import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplatesMockup from '@assets/images/templates/TemplatesMockup.png';
import Page from '@common/components/Page';
import SummaryMockup from '@common/components/SummaryMockup';
import { navigation } from '@common/helpers/navigation';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';
import palette from '@common/theme/palette/palette';
import NotLoggedLayout from '@pages/common/NotLoggedLayout';
import FormSignUp from './components/formSignUp/FormSignUp';

export default function SignUp() {
    const navigate = useNavigate();
    const location = useLocation();

    const [step, setStep] = useState(1);
    const [currentTemplate, setCurrentTemplate] = useState('');
    const [otherTemplateTitle, setOtherTemplateTitle] = useState('');
    const [templateMockup, setTemplateMockup] = useState(
        predefinedTemplates.find((template) => template.id === 'other')
    );

    const handleNavigateToSignIn = () => {
        navigate(
            location.hash
                ? `${navigation.app.routes.login}${navigation.extension.hash.signin}`
                : navigation.app.routes.login
        );
    };

    useEffect(() => {
        if (currentTemplate) {
            const foundTemplate = predefinedTemplates.find(
                (template) => template.id === currentTemplate
            );

            if (foundTemplate) {
                const updatedTemplate =
                    currentTemplate === 'other' && otherTemplateTitle
                        ? { ...foundTemplate, name: otherTemplateTitle }
                        : foundTemplate;

                setTemplateMockup(updatedTemplate);
            }
        }
    }, [currentTemplate, otherTemplateTitle]);

    return (
        <Page title='Crear cuenta'>
            <NotLoggedLayout
                centeredHorizontally={true}
                centeredVertically={step === 1 ? false : true}
                textSize={'h4'}
                textColor={palette.common.white}
                image={step === 1 ? TemplatesMockup : null}
                component={
                    step !== 1 ? (
                        <SummaryMockup
                            title={templateMockup.name}
                            sections={templateMockup.sections}
                        />
                    ) : null
                }
                backColor={`${palette.primary.main}`}
                darkMode={step !== 1 ? false : true}
                disableLogotype={step !== 1}
                bannerSize={step !== 1 ? 'large' : 'small'}
            >
                <FormSignUp
                    currentTemplate={currentTemplate}
                    setCurrentTemplate={setCurrentTemplate}
                    step={step}
                    setStep={setStep}
                    setOtherTemplateTitle={setOtherTemplateTitle}
                    otherTemplateTitle={otherTemplateTitle}
                />
                {step === 1 ? (
                    <Grid
                        container
                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Link
                                component='button'
                                variant='body2'
                                underline='hover'
                                color={palette.primary[900]}
                                onClick={handleNavigateToSignIn}
                            >
                                ¿Ya estás registrado?
                            </Link>
                        </Grid>
                    </Grid>
                ) : null}
            </NotLoggedLayout>
        </Page>
    );
}
