import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import './DragDrop.css';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function DragDropFile({
    handleFile,
    filename,
    alertFile,
    alertMessage,
    infoMessage,
    icon
}) {
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0]);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input
                ref={inputRef}
                type='file'
                id='input-file-upload'
                multiple={true}
                onChange={handleChange}
            />
            <label
                id='label-file-upload'
                htmlFor='input-file-upload'
                className={dragActive ? 'drag-active' : ''}
            >
                <Grid container direction='column'>
                    <Grid item>{icon}</Grid>
                    <Grid item>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Grid item>
                                <button className={'upload-button'} onClick={onButtonClick}>
                                    Haz clic para subir
                                </button>
                            </Grid>

                            <Grid item>
                                <Typography variant={'body1'} color={palette.secondary.light}>
                                    o arrastra y suelta
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant={'caption'} color={palette.secondary.light}>
                            {infoMessage}
                        </Typography>
                    </Grid>
                    {filename ? (
                        <Typography variant={'caption'} color={palette.primary.main}>
                            {filename}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {alertFile ? (
                        <Typography variant={'caption'} color={palette.error.main}>
                            {alertMessage}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
            </label>
            {dragActive && (
                <div
                    id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </form>
    );
}

DragDropFile.propTypes = {
    handleFile: PropTypes.func,
    filename: PropTypes.string,
    alertFile: PropTypes.bool,
    alertMessage: PropTypes.string,
    infoMessage: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired
};
