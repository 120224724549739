import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import logotypeWhite from '@assets/brand/voicit-logo-white.svg';
import logotype from '@assets/brand/voicit-logotype.svg';
import palette from '@common/theme/palette/palette';

const sizeToWidth = {
    small: { bannerWidth: '50%', contentLeftMargin: 3, contentRightMargin: 5 },
    medium: { bannerWidth: '75%', contentLeftMargin: 2, contentRightMargin: 7 },
    large: { bannerWidth: '100%', contentLeftMargin: 2, contentRightMargin: 8 }
};

export default function NotLoggedLayout(props) {
    const {
        text,
        centeredHorizontally,
        textSize,
        image,
        darkMode,
        withoutBanner,
        disableLogotype,
        centeredVertically,
        bannerSize,
        component
    } = props;

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'row',
                mt: withoutBanner ? 2 : 0
            }}
        >
            {!withoutBanner && (
                <Box
                    sx={{
                        backgroundColor: darkMode ? palette.primary.main : palette.primary[50],
                        minHeight: '100vh',
                        width:
                            sizeToWidth[bannerSize]?.bannerWidth ||
                            sizeToWidth['small']?.bannerWidth,
                        display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                        flexDirection: 'column'
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: centeredHorizontally ? 'center' : 'left',
                            pl: 5,
                            pr: 5
                        }}
                        display='flex'
                        justifyContent={centeredVertically ? 'center' : 'flex-end'}
                        minHeight='100vh'
                        spacing={9}
                    >
                        {image && (
                            <Grid item>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    justifyContent={centeredHorizontally ? 'center' : 'flex-end'}
                                >
                                    <Grid item xs={12}>
                                        <img
                                            src={image}
                                            alt='voicit-image'
                                            style={{ maxWidth: '100%', width: 'auto' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {component && <Grid item>{component}</Grid>}
                        {text ? (
                            <Grid item>
                                <Typography
                                    component={'span'}
                                    variant={textSize ? textSize : 'display3'}
                                    color={darkMode ? palette.common.white : palette.primary[800]}
                                >
                                    {text}
                                </Typography>
                            </Grid>
                        ) : null}
                        {!disableLogotype ? (
                            <Grid item>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    justifyContent={centeredHorizontally ? 'center' : 'flex-end'}
                                >
                                    <Grid item xs={centeredHorizontally ? 12 : 3}>
                                        <img
                                            src={darkMode ? logotypeWhite : logotype}
                                            alt='voicit-logo'
                                            style={{
                                                maxWidth: '100%',
                                                width: 'auto',
                                                minHeight: '24px'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>
            )}
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: withoutBanner ? 2 : 0
                }}
                display='flex'
                minHeight='100vh'
            >
                <Grid
                    item
                    sx={{
                        height: '100vh'
                    }}
                    xs={1}
                    lg={
                        sizeToWidth[bannerSize]?.contentLeftMargin ||
                        sizeToWidth['small'].contentLeftMargin
                    }
                />
                <Grid
                    item
                    xs={10} // TODO: Where is the last column?
                    lg={
                        sizeToWidth[bannerSize]?.contentRightMargin ||
                        sizeToWidth['small']?.contentRightMargin
                    }
                >
                    {props.children}
                </Grid>
            </Grid>
        </Box>
    );
}

NotLoggedLayout.propTypes = {
    children: PropTypes.node,
    text: PropTypes.string,
    centeredHorizontally: PropTypes.bool,
    centeredVertically: PropTypes.bool,
    textSize: PropTypes.string,
    bannerSize: PropTypes.string,
    image: PropTypes.any,
    darkMode: PropTypes.bool,
    withoutBanner: PropTypes.bool,
    disableLogotype: PropTypes.bool,
    component: PropTypes.component
};
