import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

IntegrationCardSkeleton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    borderType: 'dashed' | 'solid',
    borderColor: PropTypes.number,
    borderWidth: PropTypes.number,
    shadow: PropTypes.bool
};

export default function IntegrationCardSkeleton({
    children,
    borderType,
    borderWidth,
    borderColor
}) {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='flex-end'
            sx={{
                border: `${borderWidth}px ${borderType} ${borderColor}`,
                borderRadius: '10px',
                position: 'relative',
                ':hover': {
                    border: `${borderWidth}px ${borderType} ${palette.primary.main}`
                }
            }}
            p={2}
            width='350px'
        >
            {children}
        </Box>
    );
}
