import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CheckboxField from '@common/components/form/CheckboxField';
import EmailField from '@common/components/form/EmailField';
import NameField from '@common/components/form/NameField';
import PasswordField from '@common/components/form/PasswordField';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';
import { urls } from '@common/helpers/urls';
import palette from '@common/theme/palette/palette';
import StepOneOnboarding from './StepOneOnboarding';
import StepTwoOnboarding from './StepTwoOnboarding';

FormSignUpView.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    alertPassword: PropTypes.string,
    alertName: PropTypes.string,
    alertEmail: PropTypes.string,
    alertNumber: PropTypes.string,
    alertTerms: PropTypes.string,
    loading: PropTypes.bool,
    onGrecaptchaVerify: PropTypes.func.isRequired,
    handleValidateFormData: PropTypes.func.isRequired,
    currentTemplate: PropTypes.arrayOf(PropTypes.string),
    setCurrentTemplate: PropTypes.func,
    step: PropTypes.number,
    setStep: PropTypes.func,
    otherTemplateTitle: PropTypes.string,
    setOtherTemplateTitle: PropTypes.func
};

export default function FormSignUpView({
    handleSubmit,
    alertPassword,
    alertName,
    alertEmail,
    alertTerms,
    loading,
    setCurrentTemplate,
    step,
    setStep,
    setOtherTemplateTitle,
    otherTemplateTitle
}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsAcceptance, setTermsAcceptance] = useState(false);
    const [useCase, setUseCase] = useState([]);
    const [title, setTitle] = useState('¡Qué bueno conocerte!');
    const [subtitle, setSubtitle] = useState(
        'Crea una cuenta para exprimir al máximo todas tus reuniones.'
    );
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');

    const handleGoToStepOne = () => {
        setStep(1);
        setTitle(`¡Qué bueno conocerte!`);
        setSubtitle('Crea una cuenta para exprimir al máximo todas tus reuniones.');
        setAlertMessage('');
    };

    const handleGoToStepTwo = () => {
        setStep(2);
        setTitle(`Elige plantillas de resumen para tus reuniones.`);
        setSubtitle('Cada tipo de reunión tiene una plantilla asociada para mejorar tu resumen.');
    };

    const handleGoToStepThree = () => {
        if (selectedTemplates.length > 0) {
            if (selectedTemplates.length === 1) {
                setUseCase(selectedTemplates);
                handleGoToStepFour(selectedTemplates);
            } else {
                setStep(3);
                setUseCase([]);
                setTitle('¿Qué plantilla usarás con mayor frecuencia?');
                setSubtitle(
                    'En cada reunión puedes elegir la plantilla a aplicar, pero por defecto aplicaremos la que elijas ahora.'
                );
                setAlertMessage('');
            }
        } else {
            setAlertMessage('Elige al menos una plantilla de resumen para tus reuniones.');
        }
    };

    const handleGoToStepFour = (useCaseSlection) => {
        if (useCase.length > 0 || useCaseSlection) {
            setStep(4);
            setTitle('¡Ya has configurado Voicit!');
            setSubtitle('Termina de crear tu cuenta para personalizar al 100% tu plantilla.');
            setAlertMessage('');
        } else {
            setAlertMessage('Elige una plantilla principal para tus reuniones.');
        }
    };

    const handleCreateUser = (event) => {
        handleSubmit(event, {
            name,
            email,
            password,
            termsAcceptance,
            useCase: useCase[0],
            templates: predefinedTemplates
                .filter((template) => selectedTemplates.includes(template.id))
                .map((template) => ({
                    name:
                        template.id === 'other' && otherTemplateTitle
                            ? otherTemplateTitle
                            : template.name,
                    sections: template.sections.map((section, index) => ({
                        position: index,
                        type: section.type
                    })),
                    isActive: template.id === useCase[0]
                }))
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    mt: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                }}
            >
                <Typography component='h1' variant='h3'>
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: 1,
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                }}
            >
                <Typography component='h1' variant='body1' color={palette.primary[300]}>
                    {subtitle}
                </Typography>
            </Box>
            {alertMessage ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left'
                    }}
                >
                    <Typography component='h1' variant='body2' color={palette.error.main}>
                        {alertMessage}
                    </Typography>
                </Box>
            ) : null}

            <Box
                component='form'
                onSubmit={handleCreateUser}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') event.preventDefault();
                }}
                noValidate
                sx={{ mt: 1 }}
            >
                {step === 1 && (
                    <>
                        <Box>
                            <NameField
                                alertMessage={alertName}
                                name={name}
                                setName={setName}
                                autoFocus={true}
                            />
                        </Box>
                        <Stack direction='row' flexWrap='wrap'>
                            <Button
                                fullWidth
                                onClick={handleGoToStepTwo}
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                                disableElevation={true}
                            >
                                Comenzar
                            </Button>
                        </Stack>
                    </>
                )}
                {step === 2 && (
                    <StepOneOnboarding
                        handleGoToStepOne={handleGoToStepOne}
                        handleGoToStepThree={handleGoToStepThree}
                        selectedTemplates={selectedTemplates}
                        setSelectedTemplates={setSelectedTemplates}
                        setCurrentTemplate={setCurrentTemplate}
                        setOtherTemplateTitle={setOtherTemplateTitle}
                        otherTemplateTitle={otherTemplateTitle}
                    />
                )}
                {step === 3 && (
                    <StepTwoOnboarding
                        handleNextStep={handleGoToStepFour}
                        handleLastStep={handleGoToStepTwo}
                        useCase={useCase}
                        setUseCase={setUseCase}
                        selectedTemplates={selectedTemplates}
                        setCurrentTemplate={setCurrentTemplate}
                        otherTemplateTitle={otherTemplateTitle}
                    />
                )}
                {step === 4 && (
                    <>
                        <Box mt={-4}>
                            <EmailField
                                alertMessage={alertEmail}
                                email={email}
                                setEmail={setEmail}
                            />
                            <PasswordField
                                password={password}
                                setPassword={setPassword}
                                id='new-password'
                                label='Contraseña'
                                alertMessage={alertPassword}
                            />
                            {/* <OptionsSelector
                                options={useCases}
                                label='¿Qué tipo de reuniones quieres optimizar? *'
                                handleChange={(value) => setUseCase(value)}
                            /> */}
                            {/* Terms & Service */}
                            <CheckboxField
                                alertMessage={alertTerms}
                                checked={termsAcceptance}
                                setChecked={setTermsAcceptance}
                                linkUrl={urls.serviceTermsAndConditions}
                            />
                        </Box>
                        {loading ? (
                            <LoadingButton
                                loading
                                loadingPosition='start'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 3, mb: 2 }}
                                disableElevation={true}
                                startIcon={<SaveIcon />}
                            >
                                Preparando...
                            </LoadingButton>
                        ) : (
                            <Stack direction='row' flexWrap='wrap'>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    sx={{ mt: 0, mb: 2 }}
                                    disableElevation={true}
                                >
                                    Iniciar prueba de 7 días y descargar extensión
                                </Button>
                                <Button
                                    fullWidth
                                    variant='text'
                                    disableElevation={true}
                                    onClick={
                                        selectedTemplates.length === 1
                                            ? handleGoToStepTwo
                                            : handleGoToStepThree
                                    }
                                >
                                    Atrás
                                </Button>
                            </Stack>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}
