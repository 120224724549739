import React from 'react';
import Box from '@mui/material/Box';
import { PropTypes } from 'prop-types';
import CreationDateField from './CreationDateField';
import DurationField from './DurationField';
import ParticipantsField from './ParticipantsField';
import TagField from './TagField';

export default function Info(props) {
    const { currentMeeting, findAndUpdateMeeting } = props;

    return (
        <>
            <Box sx={{ height: 'auto' }}>
                <TagField
                    currentTag={currentMeeting.tag}
                    meetingId={currentMeeting.id}
                    findAndUpdateMeeting={findAndUpdateMeeting}
                />
                <CreationDateField currentCreationDate={currentMeeting.createdAt} />
                <ParticipantsField
                    speakers={
                        currentMeeting.speakers ? Object.values(currentMeeting.speakers) : null
                    }
                    numberOfSpeakers={currentMeeting.numberOfSpeakers}
                />
                <DurationField currentDuration={currentMeeting.duration} />
            </Box>
        </>
    );
}

Info.propTypes = {
    currentMeeting: PropTypes.object,
    findAndUpdateMeeting: PropTypes.func
};
