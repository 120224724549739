import React, { useState } from 'react';
import { Input } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

export default function EditableTitle(props) {
    const { handleUpdateTitle, currentMeeting, onTitleClick, setOnTitleClick } = props;

    const [titleValue, setTitleValue] = useState(currentMeeting.title);

    const handleChangeTitle = (event) => {
        setTitleValue(event.target.value);
    };

    const handleClickAway = () => {
        handleUpdateTitle(titleValue);
        setOnTitleClick(false);
    };

    const handleSaveWithEnter = (event) => {
        if (event.keyCode === 13) {
            handleUpdateTitle(titleValue);
            setOnTitleClick(false);
        }
    };

    if (onTitleClick) {
        return (
            <>
                <DialogTitle sx={{ mb: 2 }}>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <FormControl
                            fullWidth
                            sx={{
                                mr: 2,
                                '&:focus': {
                                    borderColor: '#F00'
                                }
                            }}
                            variant='outlined'
                        >
                            <Input
                                id='meeting-title'
                                type={'text'}
                                value={titleValue}
                                onChange={handleChangeTitle}
                                onKeyDown={handleSaveWithEnter}
                            />
                        </FormControl>
                    </ClickAwayListener>
                </DialogTitle>
            </>
        );
    } else {
        return (
            <>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mb: 0
                    }}
                >
                    <Grid item xs={10}>
                        <DialogTitle
                            sx={{ mb: 0 }}
                            onClick={() => setOnTitleClick(true)}
                            variant='h4'
                        >
                            {titleValue}
                        </DialogTitle>
                    </Grid>
                </Grid>
            </>
        );
    }
}

EditableTitle.propTypes = {
    handleUpdateTitle: PropTypes.func,
    currentMeeting: PropTypes.object,
    onTitleClick: PropTypes.bool,
    setOnTitleClick: PropTypes.func
};
