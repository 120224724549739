import React from 'react';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';

EmailField.propTypes = {
    alertMessage: PropTypes.string,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    autoFocus: PropTypes.bool,
    handleInput: PropTypes.func,
    variant: PropTypes.string
};

export default function EmailField({
    alertMessage,
    email,
    setEmail,
    autoFocus,
    handleInput,
    variant
}) {
    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <TextField
            error={alertMessage ? true : false}
            helperText={alertMessage}
            margin='normal'
            size='small'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus={autoFocus}
            onChange={handleChange}
            value={email}
            onInput={handleInput}
            variant={variant ? variant : 'standard'}
        />
    );
}
