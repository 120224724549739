import React, { forwardRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { urls } from '@common/helpers/urls';
import { handleLogOut } from '@common/utils/auth';
import { deleteUserAccount } from '@setup/api/user';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function DeleteAccountButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPassword('');
        setAlert(false);
        setLoading(false);
    };

    const handleOnChange = (e) => {
        setPassword(e.target.value);
    };

    const handleDeleteUser = async () => {
        setLoading(true);
        try {
            if (!password) {
                setAlert(true);
                setAlertMessage('Contraseña incorrecta. Prueba de nuevo.');
                setLoading(false);
                return;
            }
            await deleteUserAccount({ password });
            handleLogOut(navigate, dispatch);
            window.open(urls.forms.deletingAccount, '_blank');
        } catch (error) {
            setAlert(true);
            setAlertMessage('Contraseña incorrecta. Prueba de nuevo.');
        }
        setLoading(false);
    };

    return (
        <div>
            <Button color='error' variant='outlined' size='small' onClick={handleClickOpen}>
                Eliminar cuenta
            </Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>Te echaremos de menos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Recuerda que al eliminar tu cuenta desaparecerán todas tus reuniones creadas
                        y toda su información asociada.
                    </DialogContentText>
                    <TextField
                        error={alert}
                        helperText={alertMessage}
                        autoFocus
                        margin='dense'
                        required
                        name='password'
                        id='password'
                        label='Confirma tu contraseña'
                        type='password'
                        fullWidth
                        variant='standard'
                        value={password}
                        onChange={handleOnChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={handleClose}>
                        Cancelar
                    </Button>
                    {loading ? (
                        <LoadingButton loading loadingPosition='start' startIcon={<SaveIcon />}>
                            Elimando
                        </LoadingButton>
                    ) : (
                        <Button onClick={handleDeleteUser} color='error'>
                            Eliminar cuenta
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
