import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function ItemButtonSelector(props) {
    const [anchorElChip, setAnchorElChip] = useState(null);

    const itemsList = props.itemsList;

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    return (
        <div>
            <Tooltip title={props.tooltipLabel}>
                <IconButton
                    sx={{
                        borderRadius: '4px',
                        border: '0px solid'
                    }}
                    size='medium'
                    aria-label='toggle password visibility'
                    onClick={handleOpenList}
                    edge='start'
                >
                    {props.iconButton}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px', maxHeight: 450 }}
                id='menu-filters'
                anchorEl={anchorElChip}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorElChip)}
                onClose={handleCloseList}
            >
                <MenuList>
                    {itemsList.map((item, index) => (
                        <div key={index}>
                            <MenuItem key={item.id}>
                                {props.checkBox ? (
                                    <Checkbox
                                        checked={props.currentItem === item.label ? true : false}
                                        onClick={() => props.onItemSelection(item)}
                                        sx={{ mr: 1 }}
                                    />
                                ) : (
                                    <></>
                                )}
                                {props.switch ? (
                                    <Switch
                                        checked={props.currentItem}
                                        onChange={() => props.onItemSelection(item)}
                                        name='gilad'
                                    />
                                ) : (
                                    <></>
                                )}
                                <Chip
                                    sx={{ flexGrow: 1 }}
                                    label={item.label}
                                    onClick={() => props.onItemSelection(item)}
                                    color={item.color !== null ? item.color : palette.common.white}
                                    size='small'
                                />
                            </MenuItem>
                        </div>
                    ))}
                </MenuList>
            </Menu>
        </div>
    );
}

ItemButtonSelector.propTypes = {
    iconButton: PropTypes.element,
    onItemSelection: PropTypes.func,
    itemsList: PropTypes.arrayOf(PropTypes.object),
    tooltipLabel: PropTypes.string,
    currentItem: PropTypes.string,
    checkBox: PropTypes.bool,
    switch: PropTypes.bool
};
