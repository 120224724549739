import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import { deleteCustomTagById } from '@setup/api/userConfig';

export default function Tag({
    tag,
    onTagSelection,
    deleteTag,
    filterMeetingList,
    detachTag,
    disableFullWidth
}) {
    const noColor = !tag ? true : palette[tag.color] ? false : true; // checking the color exists
    const handleDeleteTag = async () => {
        await deleteCustomTagById({ tagId: tag.id });
        filterMeetingList(tag.id);
    };

    return (
        <Chip
            sx={{
                flexGrow: disableFullWidth ? 0 : 1,
                borderRadius: 2,
                justifyContent: 'left'
            }}
            label={!noColor ? tag.name : 'Sin etiqueta'}
            onClick={onTagSelection && !noColor ? () => onTagSelection(tag) : null}
            color={!noColor ? tag.color : 'chip_grey'} // I don't know why when using 'chip_primary' the app brokes
            size='small'
            onDelete={
                (!noColor && detachTag) || (!noColor && deleteTag)
                    ? detachTag
                        ? detachTag
                        : handleDeleteTag
                    : null
            }
            deleteIcon={deleteTag ? <DeleteIcon /> : null}
        />
    );
}

Tag.propTypes = {
    onTagSelection: PropTypes.func,
    tag: PropTypes.object,
    deleteTag: PropTypes.bool,
    filterMeetingList: PropTypes.func,
    detachTag: PropTypes.func,
    disableFullWidth: PropTypes.bool
};
