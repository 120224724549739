import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function LoaderTitleWithSubtitle() {
    return (
        <Stack spacing={0}>
            <Skeleton variant='text' sx={{ fontSize: '1.5rem' }} width={'100%'} />
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={'70%'} />
        </Stack>
    );
}
