export default async function copyToClipboard(content) {
    const isIOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    if (isIOSDevice) {
        let oldContentEditable = content.contentEditable,
            oldReadOnly = content.readOnly,
            range = document.createRange();

        content.contentEditable = true;
        content.readOnly = false;
        range.selectNodeContents(content);

        let s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);

        content.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

        content.contentEditable = oldContentEditable;
        content.readOnly = oldReadOnly;

        document.execCommand('copy');
    } else {
        await navigator.clipboard.writeText(content);
    }
}
