import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import ShortTextIcon from '@mui/icons-material/ShortText';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import palette from '@common/theme/palette/palette';

export const DEFAULT_TEMPLATE_SECTIONS = {
    title: {
        title: 'Título',
        icon: <ShortTextIcon htmlColor={palette.primary[500]} />,
        type: 'title',
        categoryName: 'Contenido general',
        new: false,
        deprecated: true
    },
    summary: {
        title: 'Resumen',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'summary',
        categoryName: 'Contenido general',
        new: false,
        deprecated: false
    },
    notes: {
        title: 'Notas',
        icon: <StickyNote2OutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'notes',
        categoryName: 'Contenido general',
        new: false,
        deprecated: true
    },
    index: {
        title: 'Índice',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'index',
        categoryName: 'Contenido general',
        new: false,
        deprecated: false
    },
    chronological_summary: {
        title: 'Resumen cronológico',
        icon: <SubjectOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'chronological_summary',
        categoryName: 'Contenido general',
        new: false,
        deprecated: false
    },
    decisions: {
        title: 'Decisiones',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'decisions',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: true
    },
    hashtags: {
        title: 'Palabras clave',
        icon: <TagOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'hashtags',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: true
    },
    next_meeting_items: {
        title: 'Próximos puntos a tratar',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'next_meeting_items',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: false
    },
    questions: {
        title: 'Preguntas',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'questions',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: true
    },
    tasks: {
        title: 'Tareas',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'tasks',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: true
    },
    dates: {
        title: 'Fechas',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'dates',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: false
    },
    metrics: {
        title: 'Métricas',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'metrics',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: false
    },
    pains: {
        title: 'Problemas',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'pains',
        categoryName: 'Contenido específico',
        new: false,
        deprecated: false
    },
    decision_criteria: {
        title: 'Criterio de decisión',
        icon: <SubjectOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'decision_criteria',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    decision_process: {
        title: 'Proceso de decisión',
        icon: <SubjectOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'decision_process',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    purchase_decision_metrics: {
        title: 'Métricas de decisión de compra',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'purchase_decision_metrics',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    implementation_date: {
        title: 'Fecha de implementación',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'implementation_date',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    competitors: {
        title: 'Competidores',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'competitors',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    decision_time: {
        title: 'Fechas para la decisión',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'decision_time',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    decisors: {
        title: 'Decisores de compra',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'decisors',
        categoryName: 'Reunión de ventas',
        new: false,
        deprecated: true
    },
    education: {
        title: 'Formación',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'education',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    work_experience: {
        title: 'Experiencia laboral',
        icon: <SubjectOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'work_experience',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    salary: {
        title: 'Rango salarial',
        icon: <ShortTextIcon htmlColor={palette.primary[500]} />,
        type: 'salary',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    availability_incorporation: {
        title: 'Disponibilidad de incorporación',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'availability_incorporation',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    availability_mobility: {
        title: 'Disponibilidad de movilidad',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'availability_mobility',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    availability_interviews: {
        title: 'Disponibilidad de entrevistas',
        icon: <ShortTextIcon htmlColor={palette.primary[500]} />,
        type: 'availability_interviews',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    job_expectations: {
        title: 'Expectativas del puesto',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'job_expectations',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    job_change_reasons: {
        title: 'Motivos de cambio de trabajo',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'job_change_reasons',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    metadata: {
        title: 'Datos de la reunión',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'metadata',
        categoryName: 'Contenido general',
        new: false,
        deprecated: false
    },
    job_requeriments: {
        title: 'Requerimientos del perfil de candidato',
        icon: <FormatListBulletedOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'job_requeriments',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    specific_competence: {
        title: 'Competencia blanda específica',
        icon: <SubjectOutlinedIcon htmlColor={palette.primary[500]} />,
        type: 'specific_competence',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    soft_skills: {
        title: 'Competencias blandas',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'soft_skills',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    hard_skills: {
        title: 'Competencias técnicas',
        icon: <NotesIcon htmlColor={palette.primary[500]} />,
        type: 'hard_skills',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    },
    languages: {
        title: 'Idiomas',
        icon: <ShortTextIcon htmlColor={palette.primary[500]} />,
        type: 'languages',
        categoryName: 'Entrevista de selección',
        new: false,
        deprecated: false
    }
};
