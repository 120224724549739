import TooltipMUI from '@material-ui/core/Tooltip';
import { Grid, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string,
    image: PropTypes.string.isRequired,
    title: PropTypes.string,
    placement: PropTypes.string,
    content: PropTypes.any
};

export default function Tooltip({ children, text, image, title, placement, content }) {
    return (
        <TooltipMUI
            title={
                <Grid
                    container
                    direction='column'
                    width={240}
                    mb={title || content || image ? 1 : 0}
                >
                    {image && (
                        <Grid item sx={{ mt: 0.5 }}>
                            <img src={image} width={240} alt='image' />
                        </Grid>
                    )}
                    {content && <Grid item>{content}</Grid>}
                    {title && (
                        <Grid item mt={1}>
                            <Typography variant='subtitle1'>{title}</Typography>
                        </Grid>
                    )}
                    {text && (
                        <Grid item mt={title || content || image ? 1 : 0}>
                            <Typography variant='caption'>{text}</Typography>
                        </Grid>
                    )}
                </Grid>
            }
            placement={placement ? placement : 'left'}
            id='tooltip-image'
            enterDelay={400}
            leaveDelay={100}
        >
            {children}
        </TooltipMUI>
    );
}
