import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

VerticalCard.propTypes = {
    image: PropTypes.string,
    primaryButtonText: PropTypes.string,
    primaryButtonAction: PropTypes.func,
    secondaryButtonText: PropTypes.string,
    secondaryButtonAction: PropTypes.func,
    disableHover: PropTypes.bool
};

export default function VerticalCard({
    image,
    primaryButtonText,
    primaryButtonAction,
    secondaryButtonText,
    secondaryButtonAction,
    disableHover
}) {
    return (
        <Card
            variant='outlined'
            sx={{
                ':hover': {
                    boxShadow: disableHover ? 0 : 1
                }
            }}
        >
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 2
                }}
            >
                <Grid item sx={{ mb: 3 }}>
                    <CardMedia component='img' sx={{ height: 42 }} image={image} alt='Platform' />
                </Grid>
                {secondaryButtonText ? (
                    <Grid item sx={{ mb: -1 }}>
                        <CardActions>
                            <Button
                                size='small'
                                color='primary'
                                disabled={secondaryButtonAction ? false : true}
                                onClick={secondaryButtonAction}
                            >
                                {secondaryButtonText}
                            </Button>
                        </CardActions>
                    </Grid>
                ) : null}
                <Grid item>
                    <CardActions>
                        <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            disableElevation={true}
                            onClick={primaryButtonAction}
                        >
                            {primaryButtonText}
                        </Button>
                    </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}
