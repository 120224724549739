import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { a11yProps } from '@common/components/tabs/utils';
import palette from '@common/theme/palette/palette';

export default function TabHeader({ tabs, currentTabValue, handleChangeTab }) {
    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: palette.primary[50]
            }}
        >
            <Tabs
                orientation='horizontal'
                variant='standard'
                value={currentTabValue}
                onChange={handleChangeTab}
                aria-label='Horizontal tabs'
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} {...a11yProps(index)} />
                ))}
            </Tabs>
        </Box>
    );
}

TabHeader.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentTabValue: PropTypes.number.isRequired,
    handleChangeTab: PropTypes.func.isRequired
};
