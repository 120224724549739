import { authApiCall, endpoints } from '.';

export const chat = async ({ prompt, thread, system, meetingId }) => {
    // We have to update authToken in redux store with this new one for admin permissions
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: '/chat/openai',
        data: {
            prompt,
            thread,
            system,
            userId: 0, // TODO: Need to be handled via authtoken,
            meetingId
        }
    });

    return data;
};
