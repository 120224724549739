import React, { useState } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import copyToClipboard from '@common/utils/copyToClipboard';

CopyIconButton.propTypes = {
    content: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
    tooltipTitle: PropTypes.string,
    id: PropTypes.string
};

export default function CopyIconButton({ id, content, size, onClick, tooltipTitle }) {
    const [copied, setCopied] = useState(false);
    const [tooltip, setTooltip] = useState(tooltipTitle ? tooltipTitle : 'Copiar');

    const onClickCopy = async () => {
        await copyToClipboard(content);
        setCopied(true);
        setTooltip('¡Copiado!');
        setTimeout(() => {
            setCopied(false);
            setTooltip(tooltipTitle ? tooltipTitle : 'Copiar');
        }, 2000);
    };

    const onClickOwnCopy = async () => {
        // case in which the content is retrieve by a function
        await onClick();
        setCopied(true);
        setTooltip('¡Copiado!');
        setTimeout(() => {
            setCopied(false);
            setTooltip(tooltipTitle ? tooltipTitle : 'Copiar');
        }, 2000);
    };

    return (
        <Tooltip title={tooltip}>
            <IconButton
                id={id ? id : null}
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                aria-label='copy-summary'
                onClick={onClick ? onClickOwnCopy : onClickCopy}
            >
                {copied ? (
                    <CheckOutlinedIcon fontSize={size ? size : 'inherit'} />
                ) : (
                    <ContentCopyOutlinedIcon
                        id={id ? id : null}
                        fontSize={size ? size : 'inherit'}
                    />
                )}
            </IconButton>
        </Tooltip>
    );
}
