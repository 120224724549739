import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppBar from '@common/components/AppBar';
import { navigation } from '@common/helpers/navigation';
import LoadingPage from '@pages/common/LoadingPage';
import NotFoundPage from '@pages/common/NotFoundPage';
import PluginOnboarding from '@pages/common/PluginOnboarding';
import RecorderPage from '@pages/common/RecorderPage';
import MeetingPage from '@pages/meeting/meetingPage/MeetingPage.js';
import RepositoryPage from '@pages/meetingsRepository/RepositoryPage';
import PasswordForgottenPage from '@pages/passwordForgotten/PasswordForgottenPage';
import SettingsPage from '@pages/settings/SettingsPage';
import SignInPage from '@pages/signIn/SignInPage.jsx';
import SignUp from '@pages/signUp/SignUpPage';
import TemplatePage from '@pages/template/TemplatePage';
import TemplatesRepositoryPage from '@pages/templatesRepository/TemplatesRepositoryPage';

export default function RouterManager() {
    return (
        <Routes>
            <Route element={<AppBar />}>
                <Route
                    path={navigation.app.routes.repository}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <RepositoryPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={`${navigation.app.routes.meeting}/:id`}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <MeetingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={`${navigation.app.routes.settings}/:tab`}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <SettingsPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={navigation.app.routes.templates}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <TemplatesRepositoryPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={navigation.app.routes.newTemplate}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <TemplatePage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={`${navigation.app.routes.editTemplate}/:id`}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <TemplatePage />
                        </React.Suspense>
                    }
                />
                <Route
                    path={navigation.app.routes.recorder}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <RecorderPage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route
                path={navigation.app.routes.login}
                element={
                    <React.Suspense fallback={<LoadingPage />}>
                        <SignInPage />
                    </React.Suspense>
                }
            />
            <Route
                path={navigation.app.routes.signup}
                element={
                    <React.Suspense fallback={<LoadingPage />}>
                        <SignUp />
                    </React.Suspense>
                }
            />
            <Route
                path={navigation.app.routes.passwordReset}
                element={
                    <React.Suspense fallback={<LoadingPage />}>
                        <PasswordForgottenPage />
                    </React.Suspense>
                }
            />
            <Route element={<AppBar isShared={true} />}>
                <Route
                    path={`${navigation.app.routes.meetingShared}/:id`}
                    element={
                        <React.Suspense fallback={<LoadingPage />}>
                            <MeetingPage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route
                path={navigation.app.routes.pluginOnboarding}
                element={
                    <React.Suspense fallback={<LoadingPage />}>
                        <PluginOnboarding />
                    </React.Suspense>
                }
            />
            <Route
                path='*'
                element={
                    <React.Suspense fallback={<LoadingPage />}>
                        <NotFoundPage />
                    </React.Suspense>
                }
            />
        </Routes>
    );
}
