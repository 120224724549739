import React from 'react';
// import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import ContentEditable from 'react-contenteditable';
import './reportBlockStyles.css';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import { urls } from '@common/helpers/urls';
import typography from '@common/theme/typography';
import StyleMenu from './Menus/StyleMenu';
import {
    createList,
    getRemainingContent,
    isCaretAtEndOrStart,
    jumpOnArrowKeyDown,
    moveCursorToLastBlock,
    moveCursorToNextBlock,
    setCaretToEnd
} from './utils';
const CMD_KEY = '/';

class ReportBlock extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
        this.handleTagSelection = this.handleTagSelection.bind(this);
        this.addPlaceholder = this.addPlaceholder.bind(this);
        this.deleteListItem = this.deleteListItem.bind(this);
        this.createListItem = this.createListItem.bind(this);
        this.contentEditable = React.createRef();
        this.state = {
            htmlContent: this.props.htmlContent,
            contentChanged: false,
            htmlTag: this.props.htmlTag,
            tagChanged: false,
            htmlBackup: this.props.htmlContent,
            placeholder: false,
            previousKey: null,
            isTyping: false,
            tagSelectorMenuOpen: false,
            tagSelectorMenuPosition: {
                x: null,
                y: null
            },
            actionMenuOpen: false,
            actionMenuPosition: {
                x: null,
                y: null
            }
        };
    }

    componentDidMount() {
        // ! Legacy but still running on first render
        // Add a placeholder if the first block has no sibling elements and no content
        const hasPlaceholder = this.addPlaceholder({
            block: this.contentEditable.current,
            position: this.props.position,
            content: this.props.htmlContent
        });
        if (!hasPlaceholder) {
            this.setState({
                ...this.state,
                htmlContent: this.props.htmlContent,
                htmlTag: this.props.htmlTag
            });
        }
    }

    componentDidUpdate() {
        // ! don't know if it is working well
        // update the state on the block if one of the following is true
        // 1. user stopped typing and the html content has changed & no placeholder set
        // 2. user has delete a block

        const hasNoPlaceholder = !this.state.placeholder;
        const contentChanged = this.props.htmlContent !== this.state.htmlContent;

        if (contentChanged && !this.state.contentChanged && hasNoPlaceholder) {
            this.setState({
                ...this.state,
                contentChanged: false, // reseting content changed flag
                htmlContent: this.props.htmlContent,
                htmlBackup: this.state.htmlContent
            });
        }
    }

    componentWillUnmount() {
        // ! Legacy
        // In case, the user deleted the block, we need to cleanup all listeners
        document.removeEventListener('click', this.closeActionMenu, false);
    }

    handleChange(e) {
        this.setState({
            ...this.state,
            contentChanged: true,
            htmlContent: e.target.value,
            htmlBackup: this.state.htmlContent
        });
    }

    handleFocus() {
        // ! Legacy
        // If a placeholder is set, we remove it when the block gets focused
        if (this.state.placeholder) {
            this.setState({
                ...this.state,
                htmlContent: '',
                placeholder: false,
                isTyping: true
            });
        } else {
            this.setState({ ...this.state, isTyping: true });
        }
    }

    handleBlur() {
        // check if the component's content has been updated, if so, update on server side
        if (this.state.contentChanged || this.state.tagChanged) {
            this.props.updateBlock({
                currentBlock: {
                    id: this.props.id,
                    htmlContent: this.state.htmlContent,
                    htmlTag: this.state.htmlTag
                },
                htmlChanged: this.state.contentChanged,
                tagChanged: this.state.tagChanged
            });
        }
        // ! contentChanged here caused to hide momentarily the new content
        this.setState({ ...this.state, contentChanged: false, isTyping: false });
    }

    handlePasting(e) {
        // avoiding mix formats
        e.preventDefault();
        let paste = (e.clipboardData || window.clipboardData).getData('text/plain');
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
    }

    deleteListItem = () => {
        this.setState({
            ...this.state,
            htmlContent: this.state.htmlContent.replace('<li>', '').replace('</li>', ''),
            htmlTag: 'p'
        });
    };

    createListItem = () => {
        const listItem = createList(this.state.htmlContent);
        this.setState({ ...this.state, htmlTag: 'ul', htmlContent: listItem });
    };

    handleKeyDown(e) {
        const key = e.key;
        this.setState({ ...this.state, previousKey: key });

        if (key === CMD_KEY) {
            // TODO: create functionality for CMD key
            // If the user starts to enter a command, we store a backup copy of
            // the html. We need this to restore a clean version of the content
            // after the content type selection was finished.
        } else if (key === 'Backspace' && !this.state.htmlContent) {
            if (this.state.htmlTag === 'ul') {
                // TODO: should focus on the element to start writting
                this.deleteListItem();
            } else {
                this.props.deleteBlock({ id: this.props.id });
            }
        } else if (key === 'Enter' && this.state.previousKey !== 'Shift') {
            // The Shift-Enter-combination should add a new line
            e.preventDefault();
            this.handleEnterKeyDown();
        } else if (
            key === ' ' &&
            this.state.previousKey === '-' &&
            this.state.htmlContent.charAt(0) === '-'
        ) {
            // TODO: should focus on the element to start writting
            e.preventDefault();
            this.createListItem();
        } else if (
            (key === 'ArrowDown' || key === 'ArrowRight') &&
            jumpOnArrowKeyDown(this.contentEditable.current, key)
        ) {
            moveCursorToNextBlock(this.props.blocks, this.props.currentBlockId);
        } else if (
            (key === 'ArrowUp' || key === 'ArrowLeft') &&
            jumpOnArrowKeyDown(this.contentEditable.current, key)
        ) {
            moveCursorToLastBlock(this.props.blocks, this.props.currentBlockId);
        } else if (
            key === 'Backspace' &&
            isCaretAtEndOrStart(this.contentEditable.current, this.state.htmlContent).start
        ) {
            if (this.state.htmlTag === 'ul') {
                this.deleteListItem();
                // TODO: should focus on the element to start writting
            } else if (this.props.position !== 0) {
                // first block cannot be introduce into the previous
                // TODO: Fix this case
                // this.props.intoduceBlockIntoAnother({
                //     id: this.props.id,
                //     htmlContent: this.state.htmlContent,
                //     htmlTag: this.state.htmlTag
                // });
            }
        } else if (key === 'd' && this.state.previousKey === 'Control') {
            // duplicate block
            e.preventDefault();
            this.props.addBlock({
                currentBlock: {
                    id: this.props.id,
                    htmlContent: this.state.htmlContent,
                    htmlTag: this.state.htmlTag
                },
                content: this.state.htmlContent,
                tag: this.state.htmlTag
            });
        } else if (key === 'z' && this.state.previousKey === 'Control') {
            e.preventDefault();
            this.props.undoBlockDeletion();
        }
    }

    handleEnterKeyDown() {
        // Problem here is that when doing enter at the end we cur part of the word
        if (this.state.htmlTag === 'ul') {
            // list case
            if (this.state.htmlContent.replace('<li>', '').replace('</li>', '') === '') {
                // empty list item should be convert on non list block
                // TODO: should focus on the element to start writting
                this.deleteListItem();
            } else {
                // create new list item and cut previous item
                const content = getRemainingContent({
                    element: this.contentEditable.current,
                    htmlContent: this.state.htmlContent,
                    htmlTag: this.state.htmlTag
                });
                if (content) {
                    this.setState({
                        ...this.state,
                        htmlContent: this.state.htmlContent.replace(content, '')
                    });
                }
                this.props.addBlock({
                    currentBlock: {
                        id: this.props.id,
                        htmlContent: this.state.htmlContent,
                        htmlTag: this.state.htmlTag,
                        ref: this.contentEditable.current
                    },
                    content: createList(` ${content}`),
                    tag: 'ul'
                });
            }
        } else if (this.state.htmlTag !== 'ul') {
            // create new block
            const content = getRemainingContent({
                element: this.contentEditable.current,
                htmlContent: this.state.htmlContent,
                htmlTag: this.state.htmlTag
            });
            if (content) {
                this.setState({
                    ...this.state,
                    htmlContent: this.state.htmlContent.replace(content, '')
                });
            }
            this.props.addBlock({
                currentBlock: {
                    id: this.props.id,
                    htmlContent: this.state.htmlContent,
                    htmlTag: this.state.htmlTag,
                    ref: this.contentEditable.current
                },
                content
            });
        }
    }

    handleTagSelection(htmlTag) {
        if (this.state.isTyping) {
            // TODO: set command fucntionality
            // Update the tag and restore the html backup without the command
            this.setState(
                { htmlTag: htmlTag, htmlContent: this.state.htmlBackup, htmlBackup: null },
                () => {
                    setCaretToEnd(this.contentEditable.current);
                }
            );
        } else {
            this.setState({ ...this.state, htmlTag: htmlTag, htmlBackup: null }, () => {
                this.props.updateBlock({
                    currentBlock: {
                        id: this.props.id,
                        htmlContent: this.state.htmlContent,
                        htmlTag: this.state.htmlTag
                    },
                    tagChanged: true
                });
            });
        }
    }

    // Show a placeholder for blank pages
    addPlaceholder({ block, position, content }) {
        // ! Legacy
        const isFirstBlockWithoutHtml = position === 1 && !content;
        const isFirstBlockWithoutSibling = !block.parentElement.nextElementSibling;
        if (isFirstBlockWithoutHtml && isFirstBlockWithoutSibling) {
            this.setState({
                ...this.state,
                htmlContent: 'Empieza a escribir aquí...',
                htmlTag: 'p',
                placeholder: true,
                isTyping: false
            });
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <>
                {/** Block */}
                <Draggable
                    draggableId={this.props.id.toString()}
                    index={this.props.position}
                    isDragDisabled={this.props.isShared}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            className='draggable'
                            {...provided.draggableProps}
                        >
                            <Grid container direction='row' spacing={0} sx={{ mr: 0 }}>
                                {this.props.isShared ? null : (
                                    <Grid
                                        item
                                        sx={{ ml: -12, mb: -3.25, mr: 2 }}
                                        xs={1.5}
                                        display={{ xs: 'none', md: 'flex' }}
                                    >
                                        <Grid container direction='row' spacing={0}>
                                            <Grid item xs={4}>
                                                <div className='dragHandle'>
                                                    <IconButtonWithTooltip
                                                        icon={
                                                            <ThumbDownAltOutlinedIcon fontSize='small' />
                                                        }
                                                        onClick={() =>
                                                            window.open(urls.forms.summary)
                                                        }
                                                        tooltipText='Respuesta inadecuada'
                                                        size='small'
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className='dragHandle'>
                                                    <IconButtonWithTooltip
                                                        icon={
                                                            <DeleteOutlineIcon fontSize='small' />
                                                        }
                                                        tooltipText='Eliminar'
                                                        onClick={() =>
                                                            this.props.deleteBlock({
                                                                id: this.props.id
                                                            })
                                                        }
                                                        size='small'
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={3}>
                                                <div className='dragHandle'>
                                                    <IconButtonWithTooltip
                                                        icon={<AddIcon fontSize='small' />}
                                                        tooltipText='Añadir debajo'
                                                        onClick={() =>
                                                            this.props.addBlock({
                                                                currentBlock: {
                                                                    id: this.props.id,
                                                                    htmlContent:
                                                                        this.state.htmlContent,
                                                                    htmlTag: this.state.htmlTag,
                                                                    ref: this.contentEditable
                                                                        .current
                                                                }
                                                            })
                                                        }
                                                        size='small'
                                                    />
                                                </div>                                            
                                            </Grid> */}
                                            <Grid item xs={4}>
                                                <StyleMenu
                                                    id={this.props.id}
                                                    currentBlock={{
                                                        id: this.props.id,
                                                        htmlContent: this.state.htmlContent,
                                                        htmlTag: this.state.htmlTag
                                                    }}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    handleTagSelection={this.handleTagSelection}
                                                    handleDeleteBlock={this.props.deleteBlock}
                                                    handleAddBlock={this.props.addBlock}
                                                    handleAddBelow={() =>
                                                        this.props.addBlock({
                                                            currentBlock: {
                                                                id: this.props.id,
                                                                htmlContent: this.state.htmlContent,
                                                                htmlTag: this.state.htmlTag,
                                                                ref: this.contentEditable.current
                                                            }
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item xs={12} sx={{ maxWidth: true }}>
                                    <ContentEditable
                                        id={this.props.id}
                                        disabled={this.props.isShared}
                                        innerRef={this.contentEditable}
                                        data-position={this.props.position}
                                        data-tag={this.state.htmlTag}
                                        html={this.state.htmlContent}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onBlur={this.handleBlur}
                                        onKeyDown={this.handleKeyDown}
                                        onPaste={this.handlePasting}
                                        tagName={this.state.htmlTag}
                                        className={[
                                            'block',
                                            this.state.isTyping ||
                                            this.state.actionMenuOpen ||
                                            this.state.tagSelectorMenuOpen
                                                ? 'blockSelected'
                                                : null,
                                            this.state.placeholder ? 'placeholder' : null,
                                            snapshot.isDragging ? 'isDragging' : null
                                        ].join(' ')}
                                        style={{
                                            fontFamily: typography.fontFamily
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Draggable>
            </>
        );
    }
}
ReportBlock.propTypes = {
    deleteBlock: PropTypes.func,
    addBlock: PropTypes.func,
    updatePage: PropTypes.func,
    id: PropTypes.number,
    htmlContent: PropTypes.string,
    htmlTag: PropTypes.string,
    position: PropTypes.number,
    pageId: PropTypes.string,
    updateBlock: PropTypes.func,
    blocks: PropTypes.arrayOf(PropTypes.object),
    currentBlockId: PropTypes.number,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    intoduceBlockIntoAnother: PropTypes.func,
    undoBlockDeletion: PropTypes.func
};

export default ReportBlock;
