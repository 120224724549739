import React from 'react';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';
import MicroSummary from './MicroSummary';
import OldSummary from './OldSummary';

export default function SummaryView({
    content,
    transcriptionStatus,
    microSummaries,
    isShared,
    handleUpdateMicroSummary,
    handleDeleteMicroSummary,
    meetingId
}) {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 0
            }}
        >
            {microSummaries && microSummaries !== null ? (
                <>
                    {microSummaries.map((ms, index) => (
                        <div key={index}>
                            <MicroSummary
                                microSummary={ms}
                                isShared={isShared}
                                handleUpdateMicroSummary={handleUpdateMicroSummary}
                                handleDeleteMicroSummary={() => handleDeleteMicroSummary(ms.id)}
                                meetingId={meetingId}
                            />
                        </div>
                    ))}
                </>
            ) : (
                <OldSummary content={content} transcriptionStatus={transcriptionStatus} />
            )}
        </Grid>
    );
}

SummaryView.propTypes = {
    content: PropTypes.string,
    microSummaries: PropTypes.arrayOf(PropTypes.object),
    transcriptionStatus: PropTypes.string.isRequired,
    isShared: PropTypes.bool,
    handleUpdateMicroSummary: PropTypes.func,
    handleDeleteMicroSummary: PropTypes.func,
    meetingId: PropTypes.number
};
