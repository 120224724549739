import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ClickAwayListener, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Avatar from '@common/components/avatars/Avatar';
import RoleSelector from '@common/components/speakers/RoleSelector';
import TurnSelector from '@common/components/speakers/TurnSelector';
import palette from '@common/theme/palette/palette';
import IconButtonWithTooltip from '../buttons/IconButtonWithTooltip';
import { getSpeakerRoleLabelByName, getSpeakerTurnByName } from './utils';

EditableSpeaker.propTypes = {
    name: PropTypes.string.isRequired,
    setSpeakers: PropTypes.func.isRequired,
    speakers: PropTypes.any,
    asyncSpeakerDeletion: PropTypes.func,
    asyncSpeakerTurnChange: PropTypes.func,
    availableTurns: PropTypes.number,
    editableName: PropTypes.bool,
    setAlertMessage: PropTypes.func.isRequired,
    setAlertSpeaker: PropTypes.func.isRequired
};

export default function EditableSpeaker({
    name,
    speakers,
    setSpeakers,
    asyncSpeakerDeletion,
    asyncSpeakerTurnChange,
    availableTurns,
    editableName
}) {
    const user = useSelector((state) => state.auth.authUser);
    const givenName = user && user.givenName;
    const avatarUrl = user && user.avatarUrl;

    const [turn, setTurn] = useState(
        speakers && speakers !== null ? getSpeakerTurnByName(speakers, name) : -1
    );
    const [roleLabel, setRoleLabel] = useState(
        speakers && speakers !== null ? getSpeakerRoleLabelByName(speakers, name) : ''
    );
    const [currentName, setCurrentName] = useState(name);
    const [editableNameContent, setEditableNameContent] = useState(name);
    const [nameClick, setNameClick] = useState(false);

    const handleRoleChange = async (newRole) => {
        // TODO: we cannot allow the creation of the meeting if role is selected but no the turns
        setRoleLabel(!newRole ? '' : newRole.label);
        const speakerIndex = speakers ? speakers.findIndex((s) => s.name === currentName) : false;
        const updatedSpeakers = speakers;
        updatedSpeakers[speakerIndex].roleLabel = !newRole ? '' : newRole.label;
        updatedSpeakers[speakerIndex].focus = !newRole ? false : newRole.focus;
        setSpeakers(updatedSpeakers);
    };

    const handleNameChange = ({ newName, oldName }) => {
        // Not available for async update yet
        const speakerIndex = speakers ? speakers.findIndex((s) => s.name === oldName) : false;
        const updatedSpeakers = speakers;
        updatedSpeakers[speakerIndex].name = newName;
        setCurrentName(newName);
        setSpeakers(updatedSpeakers);
    };

    const handleNameClickAway = () => {
        if (nameClick && !speakers.some((s) => Object.values(s).includes(editableNameContent))) {
            handleNameChange({
                newName: editableNameContent,
                oldName: currentName
            });
            setNameClick(false);
        }
    };

    const handleSaveNameWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleNameChange({
                newName: editableNameContent,
                oldName: currentName
            });
            event.target.blur();
            setNameClick(false);
        }
    };

    const handleTurnChange = async ({ newTurn, oldTurn }) => {
        setTurn(newTurn);
        const speakerIndex = speakers ? speakers.findIndex((s) => s.name === currentName) : false;
        const updatedSpeakers = speakers;
        updatedSpeakers[speakerIndex].turn = newTurn;
        setSpeakers(updatedSpeakers);
        if (asyncSpeakerTurnChange) {
            // ! Below function should be refactorized, second param is not used at all
            await asyncSpeakerTurnChange(
                { turn: newTurn, currentName },
                oldTurn || oldTurn === 0 ? { turn: oldTurn, currentName } : null // OldTurn will never be -1
            );
        }
    };

    const handleDeleteSpeaker = async (speakerName) => {
        const updatedSpeakers = speakers;
        // Looking for the speaker with the last turn to update it
        const speakerIndex = speakers
            ? speakers.findIndex((s) => s.turn === speakers.length - 1)
            : false;
        if (speakerIndex !== -1) {
            updatedSpeakers[speakerIndex].turn = -1;
            // Changing the turn of the speaker with the last deleted turn
            if (asyncSpeakerTurnChange) {
                // TODO: If this function fails, we should reset speakers
                await asyncSpeakerTurnChange({
                    turn: -1,
                    name: updatedSpeakers[speakerIndex].name
                });
            }
        }
        setSpeakers(updatedSpeakers.filter((speaker) => speaker.name !== speakerName));
        if (asyncSpeakerDeletion) {
            // TODO: If this function fails, we should reset speakers
            await asyncSpeakerDeletion(speakerName);
        }
    };

    useEffect(() => {
        setTurn(speakers && speakers !== null ? getSpeakerTurnByName(speakers, name) : -1);
        setRoleLabel(
            speakers && speakers !== null ? getSpeakerRoleLabelByName(speakers, name) : ''
        );
        setCurrentName(name);
        setEditableNameContent(name);
    }, [speakers]);

    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                width: '100%',
                mt: 2
            }}
        >
            <Grid item flexGrow={1}>
                <Grid
                    container
                    spacing={0}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        ml: 0
                    }}
                >
                    <Grid item>
                        <Avatar
                            size={'medium'}
                            avatarName={currentName}
                            avatarImage={currentName === givenName ? avatarUrl : null}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            ml: 1
                        }}
                    >
                        {editableName ? (
                            <ClickAwayListener onClickAway={handleNameClickAway}>
                                <Tooltip title={'Doble clic para editar'}>
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color='black'
                                        contentEditable={nameClick}
                                        onClick={() => setNameClick(true)}
                                        onInput={(e) => setEditableNameContent(e.target.innerText)}
                                        onKeyDown={handleSaveNameWithEnter}
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                color: palette.primary[500]
                                            },
                                            ':focus': {
                                                outline: 'none',
                                                color: palette.primary[500],
                                                pl: 1
                                            },
                                            width: 170
                                        }}
                                    >
                                        {name}
                                    </Typography>
                                </Tooltip>
                            </ClickAwayListener>
                        ) : (
                            <Typography component='span' variant='body2' color='black'>
                                {name}
                            </Typography>
                        )}

                        {/* <Typography
                            component='span'
                            variant='caption'
                            color={palette.secondary.light}
                        >
                            {turn >= 0
                                ? `${selectorTurns[turn]} en intervenir`
                                : 'Turno sin asignar'}
                        </Typography> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <TurnSelector
                    turn={turn}
                    onTurnChange={handleTurnChange}
                    speakers={speakers}
                    availableTurns={availableTurns}
                />
            </Grid>
            {editableName ? (
                <Grid item>
                    <RoleSelector
                        currentRoleLabel={roleLabel}
                        onRoleChange={handleRoleChange}
                        speakers={speakers}
                    />
                </Grid>
            ) : null}

            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}
            >
                <IconButtonWithTooltip
                    sx={{
                        borderRadius: '4px',
                        border: '0px solid'
                    }}
                    onClick={() => handleDeleteSpeaker(currentName, turn)}
                    icon={<CloseOutlinedIcon fontSize='small' />}
                    tooltipText='Eliminar'
                    size='small'
                />
            </Grid>
        </Grid>
    );
}
