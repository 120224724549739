import { Box, Grid, Skeleton } from '@mui/material';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import LoaderBar from '../loaders/LoaderBar';
import LoaderIcon from '../loaders/LoaderIcon';
import LoaderTitleWithSubtitle from '../loaders/LoaderTitleWithSubtitle';

AudioPlayerEmptyState.propTypes = {
    size: PropTypes.string
};

export default function AudioPlayerEmptyState({ size }) {
    return (
        <Box
            sx={{
                flexGrow: 0,
                borderRadius: '8px',
                backgroundColor: palette.primary[50],
                pt: 1,
                pb: 2,
                pr: 1,
                pl: 1
            }}
        >
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
                width='auto'
            >
                <Grid item flexGrow={1}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: 0
                        }}
                        spacing={1}
                    >
                        <Grid item mr={1}>
                            <LoaderIcon />
                        </Grid>

                        {size === 'small' ? (
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    ml: 0
                                }}
                                width={'80%'}
                            >
                                <Skeleton
                                    variant='text'
                                    sx={{ fontSize: '1.5rem' }}
                                    width={'100%'}
                                />
                            </Grid>
                        ) : (
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    ml: 0
                                }}
                                width={'60%'}
                            >
                                <LoaderTitleWithSubtitle />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'right',
                        mr: 1
                    }}
                >
                    <LoaderIcon />
                </Grid>
            </Grid>

            {/* Timeline standard size */}
            {size !== 'small' ? (
                <>
                    <Box pr={1} pl={1} mt={1}>
                        <LoaderBar />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            pr: 1,
                            pl: 1
                        }}
                    >
                        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={15} />
                        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={15} />
                    </Box>
                </>
            ) : null}
        </Box>
    );
}
