import React, { useState } from 'react';
import MicNoneRoundedIcon from '@mui/icons-material/MicNoneRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Bowser from 'bowser';
import { useNavigate } from 'react-router-dom';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import { urls } from '@common/helpers/urls';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { secondsToTimeString } from '@common/utils/date';
import CreateMeetingDialog from '@pages/meetingsRepository/components/createMeetingDialog/CreateMeetingDialog';

const RecorderPage = () => {
    const navigate = useNavigate();
    const notification = useNotification();

    const [recording, setRecording] = useState(false);
    const [duration, setDuration] = useState(0);
    const [file, setFile] = useState();
    const [timerInterval, setTimerInterval] = useState();
    const [mediaRecorder, setMediaRecorder] = useState();
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);

    let stream = null;
    let seconds = 0;

    const handleNavigateToRepository = () => {
        navigate(navigation.app.routes.repository);
        window.location.reload();
    };

    const handleRecord = async () => {
        if (recording) {
            mediaRecorder.stop();
            clearInterval(timerInterval);
            const { file: currentFile } = await getFileAndDetails();
            setFile(currentFile);
            setRecordedChunks([]);
            seconds = 0;
            setDuration(0);
            stream = null;
            setOpenDialog(true);
        } else {
            startRecording();
        }
        setRecording(!recording);
    };

    const startRecording = async () => {
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1
                }
            });

            const parser = Bowser.getParser(navigator.userAgent);
            const newMediaRecorder = new MediaRecorder(stream, {
                mimeType:
                    parser.getPlatformType() === 'mobile' && parser.getOS().name !== 'Android'
                        ? 'audio/mp4'
                        : 'audio/webm;codecs=opus' // 'audio/webm;codecs=opus' not supported on safari IOS
            });
            newMediaRecorder.ondataavailable = (e) => {
                const { data } = e;
                if (data) {
                    const updatedChunks = recordedChunks;
                    updatedChunks.push(data);
                    setRecordedChunks(updatedChunks);
                }
            };
            newMediaRecorder.onstart = () => {
                setRecordedChunks([]);
                setFile();
                setTimerInterval(
                    setInterval(() => {
                        seconds++;
                        setDuration(seconds);
                    }, 1000)
                );
            };
            newMediaRecorder.onstop = () => {
                if (stream) {
                    stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
            };
            newMediaRecorder.start(1e3); // 1e3 is the interval to check for available data
            setMediaRecorder(newMediaRecorder);
        } catch (error) {
            console.error('Error accessing media devices: ', error);
            notification('audio-recording-error');
            setRecording(false);
        }
    };

    const getFileAndDetails = async () => {
        try {
            return new Promise((resolve) => {
                const today = new Date();
                const time = today.toLocaleDateString();
                const hour = String(today.getHours()).padStart(2, '0');
                const minute = String(today.getMinutes()).padStart(2, '0');

                const audioBlob = getEncodedFile('audio/mp3');
                const fileName = `Grabación en Voicit - ${time} ${hour}:${minute}`;
                const audioFile = new File([audioBlob], fileName, {
                    type: audioBlob.type
                });
                const url = URL.createObjectURL(audioFile);
                const player = new Audio(url);
                player.addEventListener(
                    'durationchange',
                    function () {
                        if (duration != Infinity) {
                            player.remove();
                            resolve({
                                file: audioFile,
                                duration
                            });
                        }
                    },
                    false
                );
                player.load();
                player.currentTime = 24 * 60 * 60;
                player.volume = 0;
            });
        } catch (error) {
            console.error('Error during getting files', error);
            notification('audio-recording-error');
            return Promise.reject(error);
        }
    };

    const getEncodedFile = (encoder) => {
        try {
            return new Blob(recordedChunks, {
                type: encoder
            });
        } catch (error) {
            console.error('Error during creating Blob:', error);
            notification('audio-recording-error');
            throw error;
        }
    };

    return (
        <Page title='Estudio de grabación'>
            <Box>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '100vh',
                        height: '100%',
                        boxSizing: 'border-box'
                    }}
                    spacing={2}
                >
                    {/* <Grid item sx={{ mb: 5 }}>
                        <img src={logotype} alt='voicit-logotype' />
                    </Grid> */}
                    <Grid item>
                        <Typography component={'span'} variant='h4'>
                            {recording ? 'Grabando reunión' : 'Graba tu reunión'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButtonWithTooltip
                            sx={{
                                borderRadius: '24px',
                                border: '1px solid'
                            }}
                            size='large'
                            onClick={handleRecord}
                            tooltipText={recording ? 'Detener' : 'Grabar'}
                            icon={recording ? <StopRoundedIcon /> : <MicNoneRoundedIcon />}
                        />
                    </Grid>
                    {recording && (
                        <Grid item>
                            <Typography component={'span'} variant='h5'>
                                {secondsToTimeString(duration)}
                            </Typography>
                        </Grid>
                    )}
                    {!recording && (
                        <>
                            <Grid
                                item
                                sx={{ mt: 2 }}
                                direction='column'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <Link
                                    component='button'
                                    variant='body2'
                                    underline='hover'
                                    color={palette.primary[900]}
                                    onClick={() => window.open(urls.tutorials.onSite, '_blank')}
                                    textAlign='center'
                                >
                                    Ver tutorial para reuniones presenciales
                                </Link>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' textAlign='center'>
                                    o accede a la videollamada para usar la extensión en reuniones
                                    online
                                </Typography>
                            </Grid>
                            <Grid item sx={{ mt: 2 }}>
                                <Button
                                    variant='outlined'
                                    size='large'
                                    disableElevation={true}
                                    onClick={handleNavigateToRepository}
                                >
                                    Volver a mis reuniones
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
                <CreateMeetingDialog
                    openFromButton={openDialog}
                    setOpenButtonDialog={setOpenDialog}
                    isDirectUpload={true} // We need to set to true to follow the path of uploading
                    isRecordedFromVoicit={true}
                    handleDeleteMeeting={() => {}}
                    findAndUpdateMeeting={() => {}}
                    introduceNewMeeting={() => {}}
                    createdFile={file}
                />
            </Box>
        </Page>
    );
};

export default RecorderPage;
