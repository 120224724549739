export const orderTemplatesByEditedTime = (templates) => {
    // 1- Ordering by updatedAt
    templates.sort(
        (currentTemplate, nextTemplate) =>
            new Date(nextTemplate.updatedAt) - new Date(currentTemplate.updatedAt)
    );
    // 2- Setting active template to the first position
    const activeTemplateIndex = templates.findIndex((template) => template.isActive === true);
    const activeTemplate = templates.splice(activeTemplateIndex, 1)[0];
    templates.unshift(activeTemplate);
    return templates;
};
