import { CHART_COLORS } from './chartPalette';
import {
    CHIP_BLUE,
    CHIP_DISABLED,
    CHIP_EMERALD,
    CHIP_ERROR,
    CHIP_GREEN,
    CHIP_GREY,
    CHIP_INFO,
    CHIP_LAUREL,
    CHIP_OCEAN,
    CHIP_OLIVE,
    CHIP_ORGANGE,
    CHIP_PEACH,
    CHIP_PINK,
    CHIP_PRIMARY,
    CHIP_PRIMARY_DARK,
    CHIP_PRIMARY_SOFT,
    CHIP_PURPLE,
    CHIP_RED,
    CHIP_SECONDARY,
    CHIP_SECONDARY_DARK,
    CHIP_SECONDARY_PRIMARY,
    CHIP_SECONDARY_SOFT,
    CHIP_SUCCESS,
    CHIP_WARNING,
    CHIP_WOOD,
    CHIP_YELLOW,
    FLOATING_BUTTON
} from './chipsPalette';
import {
    BLUE,
    EMERALD,
    ERROR,
    GREEN,
    GREY,
    INFO,
    LAUREL,
    OCEAN,
    OLIVE,
    ORGANGE,
    PEACH,
    PINK,
    PRIMARY,
    PURPLE,
    RED,
    SECONDARY,
    SUCCESS,
    WARNING,
    WOOD,
    YELLOW
} from './fullPalette';
import { GRADIENTS } from './gradientsPalette';

//--------------------------------------------------

const palette = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY },
    chip_primary: { ...CHIP_PRIMARY },
    chip_primary_soft: { ...CHIP_PRIMARY_SOFT },
    chip_primary_dark: { ...CHIP_PRIMARY_DARK },
    secondary: { ...SECONDARY },
    chip_secondary_soft: { ...CHIP_SECONDARY_SOFT },
    chip_secondary: { ...CHIP_SECONDARY },
    chip_secondary_dark: { ...CHIP_SECONDARY_DARK },
    chip_secondary_primary: { ...CHIP_SECONDARY_PRIMARY },
    chip_grey: { ...CHIP_GREY },
    chip_disabled: { ...CHIP_DISABLED },
    purple: { ...PURPLE },
    chip_purple: { ...CHIP_PURPLE },
    pink: { ...PINK },
    chip_pink: { ...CHIP_PINK },
    red: { ...RED },
    chip_red: { ...CHIP_RED },
    blue: { ...BLUE },
    chip_blue: { ...CHIP_BLUE },
    green: { ...GREEN },
    chip_green: { ...CHIP_GREEN },
    orange: { ...ORGANGE },
    chip_orange: { ...CHIP_ORGANGE },
    info: { ...INFO },
    chip_info: { ...CHIP_INFO },
    success: { ...SUCCESS },
    chip_success: { ...CHIP_SUCCESS },
    warning: { ...WARNING },
    chip_warning: { ...CHIP_WARNING },
    error: { ...ERROR },
    chip_error: { ...CHIP_ERROR },
    ocean: { ...OCEAN },
    chip_ocean: { ...CHIP_OCEAN },
    olive: { ...OLIVE },
    chip_olive: { ...CHIP_OLIVE },
    yellow: { ...YELLOW },
    chip_yellow: { ...CHIP_YELLOW },
    emerald: { ...EMERALD },
    chip_emerald: { ...CHIP_EMERALD },
    wood: { ...WOOD },
    chip_wood: { ...CHIP_WOOD },
    laurel: { ...LAUREL },
    chip_laurel: { ...CHIP_LAUREL },
    peach: { ...PEACH },
    chip_peach: { ...CHIP_PEACH },
    floating_button: { ...FLOATING_BUTTON },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: '#fff' },
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48
    }
};

export default palette;
