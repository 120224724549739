import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
// import InformationAlert from '../alerts/InformationAlert';
import EditableSpeaker from './EditableSpeaker';
import { handleCreateSpeaker } from './utils';

const filter = createFilterOptions();
SpeakerForm.propTypes = {
    speakers: PropTypes.array,
    setSpeakers: PropTypes.func,
    asyncSpeakerCreation: PropTypes.func,
    asyncSpeakerDeletion: PropTypes.func,
    asyncSpeakerTurnChange: PropTypes.func,
    availableTurns: PropTypes.number,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    editableName: PropTypes.bool
};

/**
 * Used whenever it is neccessary to add speakers locally or async
 *
 * @param {{name: string, turn: number}[]} speakers with name and turn
 * @param {func} setSpeakers set state function for speakers
 * @param {number} availableTurns number of available turns in the transcription
 * @param {func} asyncSpeakerCreation -
 * @param {func} asyncSpeakerDeletion -
 * @param {func} asyncSpeakerTurnChange -
 */
export default function SpeakerForm({
    speakers,
    setSpeakers,
    asyncSpeakerCreation,
    asyncSpeakerDeletion,
    asyncSpeakerTurnChange,
    availableTurns,
    error,
    errorMessage,
    editableName
}) {
    const givenName = useSelector((state) => state.user.userGivenName);
    const email = useSelector((state) => state.user.userEmail);

    const [name, setName] = useState('');
    const [alertSpeaker, setAlertSpeaker] = useState(error !== undefined ? error : false);
    const [alertMessage, setAlertMessage] = useState(
        errorMessage !== undefined && errorMessage ? errorMessage : ''
    );
    const [recentUsers] = useState([
        {
            name: givenName,
            email
        }
    ]);

    const handleSpeakerInputChange = (event, selectorValue) => {
        if (selectorValue) {
            setName(selectorValue.name);
        } else if (event) {
            setName(event.target.value);
        }
    };

    const handleSaveWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleNewSpeaker();
            event.target.blur();
        }
    };

    const handleNewSpeaker = async () => {
        if (!name) {
            setAlertSpeaker(true);
            setAlertMessage(
                '¿Intentas añadir a una persona que no tiene nombre o te has olvidado de ponerlo?'
            );
            return;
        }
        try {
            const success = handleCreateSpeaker({
                name,
                turn: -1,
                roleLabel: '',
                focus: false,
                speakers,
                setSpeakers,
                setAlertSpeaker,
                setAlertMessage,
                setName,
                alertSpeaker
            });
            if (success && asyncSpeakerCreation) {
                // TODO: If this function fails, we should reset speakers
                await asyncSpeakerCreation(name);
            }
        } catch (error) {
            setAlertSpeaker(true);
            setAlertMessage('Error creando participante, prueba de nuevo.');
        }
    };

    useEffect(() => {
        if (error !== undefined) {
            setAlertSpeaker(error);
            if (errorMessage !== undefined) {
                setAlertMessage(errorMessage);
            }
        }
    }, [error, errorMessage]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}
        >
            <Grid item sx={{ mr: 0 }} xs={12}>
                <Stack
                    direction='row'
                    spacing={1}
                    alignItems={alertSpeaker ? 'center' : 'flex-end'}
                >
                    <FormControl variant='standard' sx={{ m: 1, ml: 0, width: '100%' }}>
                        <Autocomplete
                            value={name}
                            selectOnFocus
                            handleHomeEndKeys
                            id='speaker'
                            type='text'
                            onChange={(e, selectorValue) =>
                                // selectorValue is the value of the name suggestion
                                handleSpeakerInputChange(e, selectorValue)
                            }
                            onKeyDown={handleSaveWithEnter}
                            margin='dense'
                            options={recentUsers}
                            freeSolo
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // TODO: Suggest the creation of a new value
                                // const { inputValue } = params;
                                // const isExisting = options.some((option) => inputValue === option.name);
                                // if (inputValue !== '' && !isExisting) {
                                //     filtered.push({
                                //         inputValue,
                                //         name: `Add "${inputValue}"`
                                //     });
                                // }
                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={name}
                                    label='Nombre'
                                    variant='standard'
                                    error={alertSpeaker || alertMessage}
                                    onChange={(event) => handleSpeakerInputChange(event)}
                                    helperText={alertSpeaker ? alertMessage : ''}
                                    fullWidth={true}
                                />
                            )}
                        />
                    </FormControl>
                    <Button endIcon={<AddIcon />} onClick={handleNewSpeaker}>
                        Añadir
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {speakers.length > 0 ? (
                    <>
                        {/* Waiting to be deleted or not base on the validation of the new create meeting dialog */}
                        {/* {editableName ? (
                            <InformationAlert
                                message='💡 Marca a tus participantes clave para personalizar tu
                            resumen y recibir solo su información.'
                            />
                        ) : null} */}
                        {speakers.map((speaker, index) => (
                            <div key={index}>
                                <EditableSpeaker
                                    name={speaker.name}
                                    setSpeakers={setSpeakers}
                                    speakers={speakers}
                                    asyncSpeakerDeletion={asyncSpeakerDeletion} // TODO: Create the logic inside
                                    asyncSpeakerTurnChange={asyncSpeakerTurnChange}
                                    availableTurns={availableTurns}
                                    editableName={editableName}
                                    setAlertMessage={setAlertMessage}
                                    setAlertSpeaker={setAlertSpeaker}
                                />
                            </div>
                        ))}
                    </>
                ) : null}
            </Grid>
        </Grid>
    );
}
