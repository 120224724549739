import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';

export default function LoaderTranscription() {
    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography component='div' key='s01-02' variant='subtitle1'>
                    <Skeleton animation='wave' width='20%' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s01-03' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s01-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
            </Grid>

            <Grid item>
                <Typography component='div' key='s01-02' variant='subtitle1'>
                    <Skeleton animation='wave' width='20%' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s01-03' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s01-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
            </Grid>

            <Grid item>
                <Typography component='div' key='s01-02' variant='subtitle1'>
                    <Skeleton animation='wave' width='20%' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s01-03' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s01-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
            </Grid>

            <Grid item>
                <Typography component='div' key='s01-02' variant='subtitle1'>
                    <Skeleton animation='wave' width='20%' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s01-03' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s01-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
            </Grid>
        </Grid>
    );
}
