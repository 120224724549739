import React from 'react';
import MUIBadge from '@material-ui/core/Badge';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

Badge.propTypes = {
    children: PropTypes.any,
    invisible: PropTypes.bool,
    borderColor: PropTypes.string,
    color: PropTypes.string,
    tooltipTitle: PropTypes.string
};

// ! CustomBadge is defined to be able to override the primary color
const CustomBadge = styled(MUIBadge)(() => ({}));

export default function Badge(props) {
    return (
        <>
            {!props.tooltipTitle || props.invisible ? (
                <CustomBadge
                    color='primary'
                    sx={{
                        '& .MuiBadge-colorPrimary': {
                            backgroundColor: props.color,
                            outline: `3px solid ${props.borderColor}`
                        }
                    }}
                    variant='dot'
                    invisible={props.invisible}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    {props.children}
                </CustomBadge>
            ) : (
                <Tooltip title='Nuevo'>
                    <CustomBadge
                        color='primary'
                        sx={{
                            '& .MuiBadge-colorPrimary': {
                                backgroundColor: props.color,
                                outline: `3px solid ${props.borderColor}`
                            }
                        }}
                        variant='dot'
                        invisible={props.invisible}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        {props.children}
                    </CustomBadge>
                </Tooltip>
            )}
        </>
    );
}
