import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Page from '@common/components/Page';

export default function LoadingPage() {
    return (
        <Page title='Cargando...'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='75vh'
            >
                <Stack sx={{ m: 2, color: 'grey.500' }} spacing={2} direction='row'>
                    <CircularProgress color='primary' />
                </Stack>
            </Box>
        </Page>
    );
}
