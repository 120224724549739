import { authApiCall, endpoints } from '..';

export async function getSharedMicroSummaries({ meetingId, authToken }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMicroSummaries,
        path: `/${meetingId}`,
        authToken
    });

    return {
        microSummaries: res.data,
        getMicroSummariesStatus: res.status
    };
}
