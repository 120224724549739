import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import MenuButton from '@common/components/MenuButton';
import { downloadAudio } from '@common/utils/audio';

export default function TopActions({
    handleClose,
    setOnTitleClick,
    handleOpenMeetingPage,
    currentMeeting
}) {
    return (
        <DialogActions>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left'
                }}
                spacing={1}
            >
                <Grid item sx={{ flexGrow: 1 }}>
                    {handleOpenMeetingPage ? (
                        <Button
                            variant='text'
                            startIcon={<OpenInFullOutlinedIcon fontSize='small' />}
                            onClick={handleOpenMeetingPage}
                            sx={{ ml: 1 }}
                        >
                            Abrir reunión completa
                        </Button>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'rigth'
                        }}
                        spacing={1}
                    >
                        <Grid item>
                            <MenuButton
                                iconButton={<MoreHorizOutlinedIcon fontSize='small' />}
                                tooltipText='Opciones'
                                menuList={[
                                    {
                                        itemFunction: () => setOnTitleClick(true),
                                        itemIcon: (
                                            <DriveFileRenameOutlineOutlinedIcon fontSize='small' />
                                        ),
                                        itemText: 'Editar título'
                                    },
                                    {
                                        itemFunction: () =>
                                            downloadAudio({
                                                meetingTitle: currentMeeting.title,
                                                audioFilename: currentMeeting.filename
                                            }),
                                        itemIcon: <GraphicEqOutlinedIcon fontSize='small' />,
                                        itemText: 'Descargar audio'
                                    }
                                ]}
                            />
                        </Grid>
                        <Grid item>
                            <IconButtonWithTooltip
                                icon={<CloseOutlinedIcon />}
                                tooltipText='Cerrar'
                                onClick={handleClose}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
    );
}

TopActions.propTypes = {
    handleClose: PropTypes.func,
    setOnTitleClick: PropTypes.func,
    setOnSummaryEdit: PropTypes.func,
    handleOpenMeetingPage: PropTypes.func,
    disableSummaryEdition: PropTypes.bool,
    handleDeleteSummary: PropTypes.func,
    currentMeeting: PropTypes.object
};
