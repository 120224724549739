import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import Avatar from '@common/components/avatars/Avatar';
import palette from '@common/theme/palette/palette';

AvatarWithInfo.propTypes = {
    avatarName: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    avatarImage: PropTypes.string,
    children: PropTypes.node,
    sx: PropTypes.object
};

export default function AvatarWithInfo(props) {
    const { avatarImage, avatarName, subtitle } = props;

    if (avatarName) {
        return (
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...props.sx
                }}
                spacing={1}
            >
                <Grid item>
                    <Avatar size={'medium'} avatarName={avatarName} avatarImage={avatarImage} />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        ml: 1
                    }}
                >
                    <Typography component='span' variant='body2' color='black'>
                        {/* Over 26 characters, the name broke the UI */}
                        {avatarName.length >= 23 ? avatarName.slice(0, 23) + '...' : avatarName}
                    </Typography>
                    {subtitle ? (
                        <Typography
                            component='span'
                            variant='caption'
                            color={palette.secondary.light}
                        >
                            {subtitle}
                        </Typography>
                    ) : (
                        props.children
                    )}
                </Grid>
                <Grid item>{/* Just to fill space */}</Grid>
            </Grid>
        );
    }
}
