import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
// eslint-disable-next-line import/no-unresolved
import HTMLReactParser from 'html-react-parser';
import { PropTypes } from 'prop-types';
import voicitLogotype from '@assets/brand/voicit-logotype.png';

// TODO: Include bold words and subtitles

ReportPDF.propTypes = {
    meetingTitle: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.any).isRequired,
    logotype: PropTypes.any
};

const filterATags = (html) => {
    return html.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '');
};

const renderNode = (node, index) => {
    if (node.type === 'tag') {
        switch (node.name) {
            case 'b':
                return (
                    <Text style={styles.boldText} key={index}>
                        {node.children.map((child, childIndex) => renderNode(child, childIndex))}
                    </Text>
                );
            case 'li':
                return (
                    <View style={styles.li} key={index}>
                        <Text style={styles.bullet}>•</Text>
                        <Text style={styles.liText}>
                            {node.children.map((child, childIndex) =>
                                renderNode(child, childIndex)
                            )}
                        </Text>
                    </View>
                );
            case 'p':
                return (
                    <Text style={styles.p} key={index}>
                        {node.children.map((child, childIndex) => renderNode(child, childIndex))}
                    </Text>
                );
            default:
                return (
                    <Text key={index}>
                        {node.children &&
                            node.children.map((child, childIndex) => renderNode(child, childIndex))}
                    </Text>
                );
        }
    } else if (node.type === 'text') {
        return node.data;
    }
    return null;
};

const renderBlock = (block, index) => {
    const filteredHtmlContent = filterATags(block.htmlContent);
    const parsedContent = HTMLReactParser(filteredHtmlContent, {
        replace: (node, nodeIndex) => renderNode(node, nodeIndex)
    });

    const style = styles[block.style] || styles.block;

    return (
        <View key={block.id || index} style={style}>
            {block.htmlTag === 'p' ? (
                <Text style={styles.text}>{parsedContent}</Text>
            ) : (
                parsedContent
            )}
        </View>
    );
};

export default function ReportPDF({ blocks, meetingTitle, logotype }) {
    return (
        <Document>
            <Page style={styles.body}>
                <Image
                    style={logotype ? styles.image : styles.voicitLogo}
                    src={logotype ? logotype : voicitLogotype}
                />
                <Text style={styles.title}>{meetingTitle}</Text>
                <Text style={styles.info}></Text>
                <View>{blocks.map((block, index) => renderBlock(block, index))}</View>
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
}

Font.register({
    family: 'Manrope',
    src: 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'
});

const styles = StyleSheet.create({
    block: { marginBottom: 10 },
    a: { color: 'blue' },
    p: { marginBottom: 10 },
    li: { marginLeft: 5, marginBottom: 5, flexDirection: 'row' },
    bullet: { width: 8, fontSize: 10 },
    liText: {
        fontWeight: 300,
        fontSize: 10
    },
    b: {
        fontWeight: 'bold'
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: 16,
        marginBottom: 4,
        marginTop: 8
    },
    subtitle: {
        fontWeight: 300,
        fontSize: 10,
        lineHeight: 1.5,
        color: 'grey'
    },
    text: {
        fontWeight: 300,
        marginTop: 4,
        marginBottom: 12,
        lineHeight: 22 / 14,
        fontSize: 10,
        textAlign: 'justify'
    },
    boldText: {
        fontWeight: 600,
        marginTop: 4,
        marginBottom: 12,
        lineHeight: 22 / 14,
        fontSize: 10,
        textAlign: 'justify'
    },
    info: {
        fontWeight: 300,
        marginTop: 4,
        marginBottom: 24,
        lineHeight: 22 / 14,
        fontSize: 12,
        textAlign: 'justify',
        color: 'grey'
    },
    image: {
        marginBottom: 24,
        maxHeight: 24,
        maxWidth: '100%', // Ensure the width does not exceed the container's width
        objectFit: 'contain', // Maintain the aspect ratio of the image
        alignSelf: 'flex-start' // Align the image to the start (left)
    },
    voicitLogo: {
        marginBottom: 24,
        maxWidth: 99,
        maxHeight: 18
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    }
});
