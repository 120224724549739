import React, { useRef, useState } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Grid from '@mui/material/Grid';
import './DragDrop.css';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';

export default function DragDropFile({ handleFile, filename, alertFile, alertMessage }) {
    const inputRef = useRef(null);
    const userPlan = useSelector((state) => state.user.userPlan);
    const { userIsRunOutOfMinutes } = useUser();

    const [dragActive, setDragActive] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

    const handleDrag = function (e) {
        if (userIsRunOutOfMinutes) {
            setOpenPremiumDialog(true);
        } else {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === 'dragenter' || e.type === 'dragover') {
                setDragActive(true);
            } else if (e.type === 'dragleave') {
                setDragActive(false);
            }
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        if (userIsRunOutOfMinutes) {
            setOpenPremiumDialog(true);
        } else {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                handleFile(e.dataTransfer.files[0]);
            }
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        if (userIsRunOutOfMinutes) {
            setOpenPremiumDialog(true);
        } else {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                handleFile(e.target.files[0]);
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        if (userIsRunOutOfMinutes) {
            setOpenPremiumDialog(true);
        } else {
            inputRef.current.click();
        }
    };

    return (
        <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input
                ref={inputRef}
                type='file'
                id='input-file-upload'
                multiple={false}
                onChange={handleChange}
            />
            <label
                id='label-file-upload'
                htmlFor='input-file-upload'
                className={dragActive ? 'drag-active' : ''}
            >
                <Grid container direction='column'>
                    <Grid item>
                        <UploadFileOutlinedIcon />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Grid item>
                                <button className={'upload-button'} onClick={onButtonClick}>
                                    Haz clic para subir un audio
                                </button>
                            </Grid>

                            <Grid item>
                                <Typography variant={'body1'} color={palette.secondary.light}>
                                    o arrastra y suelta
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant={'caption'} color={palette.secondary.light}>
                            MP3, WAV o WEBM (max. {userPlan.meetingMinutesDuration} minutos)
                        </Typography>
                    </Grid>

                    {filename ? (
                        <Typography variant={'caption'} color={palette.primary.main}>
                            {filename}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    {alertFile ? (
                        <Typography variant={'caption'} color={palette.error.main}>
                            {alertMessage}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Grid>
            </label>
            {dragActive && (
                <div
                    id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                title='Actualiza de plan para conseguir más minutos'
            />
        </form>
    );
}

DragDropFile.propTypes = {
    handleFile: PropTypes.func,
    filename: PropTypes.string,
    alertFile: PropTypes.bool,
    alertMessage: PropTypes.string
};
