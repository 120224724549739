import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import voicitLogo from '@assets/brand/voicit-logotype.svg';
import OnboardingImage from '@assets/images/OnboardingImage.png';
import VerticalCard from '@common/components/cards/VerticalCard';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import { urls } from '@common/helpers/urls';
import palette from '@common/theme/palette/palette';
import NotLoggedLayout from '@pages/common/NotLoggedLayout';
import voicitLogotype from '../../assets/brand/voicit-isotype-black.jpg';
import meetLogotype from '../../assets/images/meet-logo.png';
import teamsLogotype from '../../assets/images/teams-logo.png';
import zoomLogotype from '../../assets/images/zoom-logo.png';

export default function PluginOnboarding() {
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.auth.authToken);

    return (
        <Page title='Plugin'>
            <NotLoggedLayout withoutBanner={true}>
                <Grid container direction='column' alignItems='center'>
                    <Grid item sx={{ mb: 4 }}>
                        <img
                            src={voicitLogo}
                            alt='voicit-logo'
                            style={{ maxWidth: '100%', width: 'auto', minHeight: '32px' }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            component='h1'
                            variant='h3'
                            color={palette.primary.main}
                            align='center'
                        >
                            {!authToken
                                ? '¡Instalado, accede a tu cuenta!'
                                : '¡Voicit ya está instalado!'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            align='center'
                            variant='body1'
                            color={palette.primary.main}
                            sx={{ mt: 2 }}
                        >
                            {!authToken
                                ? 'Accede a Voicit y entra en una videollamada para resumir tu primera reunión'
                                : 'Accede a una videollamada para resumir tu primera reunión'}
                        </Typography>
                    </Grid>
                    {!authToken ? (
                        <Button
                            sx={{ mt: 2 }}
                            type='submit'
                            // fullWidth
                            variant='contained'
                            onClick={() =>
                                navigate(
                                    `${navigation.app.routes.signup}${navigation.extension.hash.signin}`
                                )
                            } // Here we mantain the hash to signin after redirection
                            disableElevation={true}
                        >
                            Iniciar sesión o registrarme
                        </Button>
                    ) : null}
                    <Grid item sx={{ mb: 3, mt: 4 }}>
                        <img
                            src={OnboardingImage}
                            alt='voicit-extension'
                            style={{ maxWidth: '100%', width: 'auto' }}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='column' sx={{ mt: 3 }} alignItems='center'>
                    <Grid container width={'100%'} spacing={2}>
                        <Grid item xs={3}>
                            <VerticalCard
                                image={teamsLogotype}
                                primaryButtonAction={() =>
                                    window.open(urls.tutorials.teams, '_blank')
                                }
                                primaryButtonText='Ver Tutorial'
                                secondaryButtonText={authToken ? 'Ir a Teams' : ''}
                                disableHover={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <VerticalCard
                                image={meetLogotype}
                                primaryButtonAction={() =>
                                    window.open(urls.tutorials.meet, '_blank')
                                }
                                primaryButtonText='Ver Tutorial'
                                secondaryButtonAction={() =>
                                    window.open(urls.videoPlatforms.googleMeet, '_blank')
                                }
                                secondaryButtonText={authToken ? 'Ir a Meet' : ''}
                                disableHover={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <VerticalCard
                                image={zoomLogotype}
                                primaryButtonAction={() =>
                                    window.open(urls.tutorials.zoom, '_blank')
                                }
                                primaryButtonText='Ver Tutorial'
                                secondaryButtonText={authToken ? 'Ir a Zoom' : ''}
                                disableHover={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <VerticalCard
                                image={voicitLogotype}
                                primaryButtonAction={() =>
                                    window.open(urls.tutorials.onSite, '_blank')
                                }
                                primaryButtonText='Ver Tutorial'
                                secondaryButtonText={authToken ? 'Presencial' : ''}
                                secondaryButtonAction={() =>
                                    window.open(
                                        `${navigation.app.routes.recorder}${navigation.extension.hash.signin}`,
                                        '_blank'
                                    )
                                }
                                disableHover={true}
                            />
                        </Grid>
                    </Grid>
                    {authToken && (
                        <Grid item sx={{ mt: 2 }}>
                            <Link
                                component='button'
                                variant='body2'
                                underline='hover'
                                color={palette.primary[900]}
                                onClick={() =>
                                    window.open(
                                        `${navigation.app.routes.repository}${navigation.extension.hash.signin}`,
                                        '_blank'
                                    )
                                }
                            >
                                Explorar Voicit
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </NotLoggedLayout>
        </Page>
    );
}
