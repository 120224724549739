import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import Loader from '@common/components/loaders/Loader';

export default function LoaderEmptyState({ title, explanation }) {
    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{ mt: 10, mb: 2 }}
        >
            <Grid item>
                <Typography component='span' variant='subtitle1'>
                    {title}
                </Typography>
            </Grid>
            <Grid item justifyContent='center' sx={{ mb: 2 }}>
                <Typography component='span' variant='body2'>
                    {explanation}
                </Typography>
            </Grid>
            <Grid item>
                <Loader maxWidth='auto' minWidth='auto' minHeight='5vh' />
            </Grid>
        </Grid>
    );
}

LoaderEmptyState.propTypes = {
    title: PropTypes.string.isRequired,
    explanation: PropTypes.string.isRequired
};
