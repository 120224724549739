import { authApiCall, endpoints } from '.';

export async function getWriteSignedUrl({ filename, filetype }) {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.gcs,
        path: '/write-url',
        data: { filename, filetype }
    });

    return res.data.url;
}

export async function getAudioBlob({ filename, allowExportInformation }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.gcs,
        path: '/read-url-client',
        data: { filename: `recordings/${filename}.mp3`, allowExportInformation }
    });

    const resp = await fetch(data.url, {
        method: 'GET'
    });

    const audioBlob = await resp.blob();

    return {
        audioBlob
    };
}

export async function getReadSignedURL({ filename, removeType }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.gcs,
        path: '/read-url-client',
        // allowExportInformation is a way to skip backend auth
        data: {
            filename: `recordings/${filename}${removeType !== true ? '.mp3' : ''}`,
            allowExportInformation: true
        }
    });

    return data.url;
}

export async function uploadToGCS({ signedUrl, file }) {
    await fetch(signedUrl, {
        body: file,
        method: 'PUT'
    });
}
