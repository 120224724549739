import React, { forwardRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import PasswordField from '@common/components/form/PasswordField';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { handleLogOut } from '@common/utils/auth';
import { updateUserPassword } from '@setup/api/user';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function ChangePasswordButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notification = useNotification();

    const [open, setOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertNewPassword, setAlertNewPassword] = useState(false);
    const [alertCurrentPassword, setAlertCurrentPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setCurrentPassword('');
        setNewPassword('');
        setRepeatedPassword('');
        setAlertNewPassword(false);
        setAlertCurrentPassword(false);
    };

    const handleChangePassword = async () => {
        if (newPassword !== repeatedPassword) {
            setAlertNewPassword(true);
            setAlertMessage('Las contraseñas no coinciden.');
            return;
        }

        setAlertNewPassword(false);
        setLoading(true);

        try {
            const { updatedPasswordStatus } = await updateUserPassword({
                password: currentPassword,
                newPassword: newPassword
            });

            if (updatedPasswordStatus === 200) {
                handleLogOut(navigate, dispatch);
                notification('password-updated');
            } else {
                setAlertCurrentPassword(true);
                setAlertMessage('Contraseña incorrecta. Prueba de nuevo.');
            }
        } catch (error) {
            setAlertCurrentPassword(true);
            setAlertMessage('Contraseña incorrecta. Prueba de nuevo.');
        }
        setLoading(false);
    };

    return (
        <div>
            <Button
                variant='outlined'
                color='primary'
                size='small'
                disableElevation={true}
                onClick={handleClickOpen}
            >
                Cambiar contraseña
            </Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>Vas a cambiar tu contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText color={palette.primary[600]}>
                        Para confirmar tus cambios debes de introducir tu contraseña actual.
                    </DialogContentText>
                    <PasswordField
                        alert={alertCurrentPassword}
                        alertMessage={alertMessage}
                        password={currentPassword}
                        setPassword={setCurrentPassword}
                        id='current-password'
                        label='Contraseña actual'
                    />
                    <DialogContentText color={palette.primary[600]}>
                        Utiliza una contraseña de al menos 15 carácteres, o de al menos 8 carácteres
                        con letras y números.
                    </DialogContentText>
                    <Box>
                        <Box>
                            <PasswordField
                                alert={alertNewPassword}
                                alertMessage={alertMessage}
                                password={newPassword}
                                setPassword={setNewPassword}
                                id='new-password'
                                label='Nueva contraseña'
                            />
                            <PasswordField
                                alert={alertNewPassword}
                                alertMessage={alertMessage}
                                password={repeatedPassword}
                                setPassword={setRepeatedPassword}
                                id='repeated-password'
                                label='Repetir nueva contraseña'
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={handleClose}>
                        Cancelar
                    </Button>
                    {loading ? (
                        <LoadingButton loading loadingPosition='start' startIcon={<SaveIcon />}>
                            Modificando
                        </LoadingButton>
                    ) : (
                        <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            disableElevation={true}
                            onClick={handleChangePassword}
                        >
                            Modificar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
