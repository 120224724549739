import React from 'react';
import { Button, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import ItemsSelector from '@common/components/form/ItemsSelector';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';

StepOneOnboarding.propTypes = {
    handleGoToStepOne: PropTypes.func,
    handleGoToStepThree: PropTypes.func,
    selectedTemplates: PropTypes.arrayOf(PropTypes.string),
    setSelectedTemplates: PropTypes.func,
    setCurrentTemplate: PropTypes.func,
    otherTemplateTitle: PropTypes.string,
    setOtherTemplateTitle: PropTypes.func
};

export default function StepOneOnboarding({
    handleGoToStepOne,
    handleGoToStepThree,
    selectedTemplates,
    setSelectedTemplates,
    setCurrentTemplate,
    otherTemplateTitle,
    setOtherTemplateTitle
}) {
    const handleChange = (event) => {
        setOtherTemplateTitle(event.target.value);
    };

    return (
        <>
            <ItemsSelector
                multipleSelection={true}
                selectedItemsIds={selectedTemplates}
                setSelectedItemsIds={setSelectedTemplates}
                setCurrentItem={setCurrentTemplate}
                items={predefinedTemplates.filter((template) => template.id !== 'hiring-clients')}
            />
            {selectedTemplates.includes('other') ? (
                <TextField
                    margin='normal'
                    size='small'
                    fullWidth
                    id='otherTemplate'
                    label='¿Cómo nombrarías la plantilla para otras reuniones?'
                    name='otherTemplate'
                    onChange={handleChange}
                    value={otherTemplateTitle}
                    variant={'standard'}
                    onFocus={() => setCurrentTemplate('other')}
                />
            ) : null}
            <Stack direction='row' spacing={2} sx={{ mt: 3, mb: 2 }}>
                <Button
                    onClick={handleGoToStepThree}
                    variant='contained'
                    color='primary'
                    size='medium'
                    disableElevation={true}
                >
                    Continuar
                </Button>
                <Button
                    onClick={handleGoToStepOne}
                    variant='text'
                    color='primary'
                    size='medium'
                    disableElevation={true}
                >
                    Atrás
                </Button>
            </Stack>
        </>
    );
}
