import React from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import TagEditable from '@common/components/tagsComponents/TagEditable';
import palette from '@common/theme/palette/palette';

export default function TagField({ currentTag, meetingId, findAndUpdateMeeting }) {
    return (
        <>
            {/* Tag field */}
            <Grid
                container
                spacing={2}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}
            >
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1} sx={{ direction: 'row', alignItems: 'center' }}>
                        <Grid item>
                            <LocalOfferOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                component='span'
                                variant='body2'
                                color={palette.primary[300]}
                            >
                                Tipo de reunión
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TagEditable
                        tag={currentTag}
                        meetingId={meetingId}
                        findAndUpdateMeeting={findAndUpdateMeeting}
                    />
                </Grid>
            </Grid>
        </>
    );
}

TagField.propTypes = {
    currentTag: PropTypes.string,
    meetingId: PropTypes.number,
    findAndUpdateMeeting: PropTypes.func
};
