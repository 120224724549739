import React, { useState } from 'react';
import 'cropperjs/dist/cropper.css';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import DialogButton from '@common/components/dialogs/DialogButton';
import DragDropFile from '@common/components/dragDropFile/DragDropFile';

export default function UploadPhotoView({
    handleOnFileSelection,
    filename,
    alertMessage,
    setAlertMessage,
    alert,
    setAlert,
    handleCloseDialog,
    file,
    setUrlAvatarImage,
    hanldeUploadImage,
    loading
}) {
    const [cropper, setCropper] = useState();

    const getCropData = () => {
        return new Promise((resolve, reject) => {
            if (typeof cropper !== 'undefined') {
                setUrlAvatarImage(cropper.getCroppedCanvas().toDataURL());
                const urlCroppedImage = cropper.getCroppedCanvas().toDataURL();
                resolve(urlCroppedImage);
            } else {
                reject(() => console.log('Error cropping image'));
            }
        });
    };

    const handleSaveNewImage = () => {
        if (!file) {
            setAlert(true);
            setAlertMessage('Introduce una imagen');
            return false;
        }
        getCropData().then((urlCroppedImage) => {
            hanldeUploadImage(urlCroppedImage);
        });

        return true;
    };

    return (
        <DialogButton
            withButton={true}
            buttonText='Nueva foto'
            buttonIcon={<AddPhotoAlternateOutlinedIcon fontSize='small' />}
            buttonVariant='text'
            dialogTitle='Nueva foto de perfil'
            dialogText='Si añades una foto, otras personas podrán reconocerte rápidamente en entornos con varios usuarios.'
            onClose={handleCloseDialog}
            onSuccess={handleSaveNewImage}
            successButtonText='Guardar'
            successButtonLoadingText='Guardando'
            loading={loading}
        >
            {file ? (
                <Grid container justifyContent='center' sx={{ mt: 2 }}>
                    <Grid item>
                        <Cropper
                            style={{ maxHeight: 500, width: '100%', m: 0, p: 0 }}
                            zoomTo={0.1}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            src={URL.createObjectURL(file)}
                            viewMode={2}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={false}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            cropBoxResizable={false}
                            guides={true}
                            movable={true}
                            toggleDragModeOnDblclick={true}
                        />
                    </Grid>
                </Grid>
            ) : (
                <DragDropFile
                    handleFile={handleOnFileSelection}
                    filename={filename}
                    alertFile={alert}
                    alertMessage={alertMessage}
                    infoMessage='PNG or JPG (max. 2Mb)'
                    icon={<AddPhotoAlternateOutlinedIcon />}
                />
            )}
        </DialogButton>
    );
}

UploadPhotoView.propTypes = {
    filename: PropTypes.string,
    handleOnFileSelection: PropTypes.func,
    alertMessage: PropTypes.string,
    alert: PropTypes.bool,
    handleCloseDialog: PropTypes.func,
    file: PropTypes.any,
    setUrlAvatarImage: PropTypes.func,
    hanldeUploadImage: PropTypes.func,
    setAlert: PropTypes.func,
    setAlertMessage: PropTypes.func,
    loading: PropTypes.bool
};
