export const commonNotification = [
    {
        type: 'cancelled',
        message: 'Se ha deshecho la acción.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'server-error',
        message: 'Ha ocurrido un error en el servidor.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'forbidden',
        message: 'Acceso denegado al recurso.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'not-found',
        message: 'No se ha encontrado la petición.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'unkown-error',
        message: 'Error desconocido, contacta con soporte.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'email-sent',
        message: 'Te hemos enviado un correo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'user-invitation-sent',
        message: 'Hemos enviado un correo con tu invitación para unirse a Voicit.',
        actionTitle: null,
        actionFunc: null
    }
];
