import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from '@common/theme';
import '@common/theme/scrollBarStyles.css';
import { store } from '@setup/redux/store';
import App from './App';

// -- Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-WBV9XXQ'
};
TagManager.initialize(tagManagerArgs);
// -- GTM end

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.withCredentials = true;

root.render(
    <HelmetProvider>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </HelmetProvider>
);
