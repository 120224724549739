export const urls = {
    tutorials: {
        meet: 'https://youtu.be/iDhpdIqeTgw',
        teams: 'https://youtu.be/LG-vaUzqZDA',
        zoom: 'https://youtu.be/SIwh4QZPHcM',
        onSite: 'https://youtu.be/sE6cIfjRN-g',
        templates: 'https://share.vidyard.com/watch/SQZdSPxZBMrdWrvq9tDseY?'
    },
    calendar: {
        supportCalendar: 'https://calendly.com/rafatorres-voicit/quick-product-meeting',
        salesCalendar: 'https://calendly.com/alvaroarres/voicitcall',
        demoCalendar: 'https://calendly.com/alvaroarres/voicitcall'
    },
    payments: {
        premium: {
            monthly: 'https://buy.stripe.com/5kAeVKd062BN42k3df',
            annually: 'https://buy.stripe.com/5kA8xmgciekv1Uc3de'
        },
        unlimited: {
            monthly: 'https://buy.stripe.com/4gw3d29NUccncyQ7tt',
            annually: 'https://buy.stripe.com/4gwcNC4tA5NZ8iA7ts'
        },
        billing: 'https://billing.stripe.com/p/login/00gaF51Ub6Zj7NC8ww?referer=invoice_receipt',
        pricing: 'https://voicit.es/pricing/'
    },
    serviceTermsAndConditions: 'https://voicit.es/terminos-y-condiciones-del-servicio/',
    chromePlugin:
        'https://chrome.google.com/webstore/detail/voicit-res%C3%BAmenes-autom%C3%A1ti/lekjojhflheocafafpmiiemdiechdnkm',
    forms: {
        deletingAccount: 'https://tally.so/r/3E5lGo',
        feedback: 'https://tally.so/r/n07JMN',
        templates: 'https://tally.so/r/mZoQev',
        freePremium: 'https://tally.so/r/3Ed8G2',
        summary: 'https://tally.so/r/3E54PB',
        workspace: 'https://tally.so/r/3leM9N',
        shareMeeting: 'https://tally.so/r/3qDk7g',
        integrations: 'https://tally.so/r/w5jVyZ',
        transcription: 'https://tally.so/r/mBpzv1',
        calculatePricing: 'https://tally.so/r/mZOpZy',
        pluginFeedback: 'https://tally.so/r/n9Qp41',
        reportFeedback: 'https://tally.so/r/m62gdk',
        chatbotFeedback: 'https://tally.so/r/w52bkd'
    },
    emails: {
        rafa: 'rafatorres@voicit.com',
        alvaro: 'alvaroarres@voicit.com',
        noreply: 'no-reply@voicit.com',
        support: 'soporte@voicit.com',
        luna: 'luna@voicit.com'
    },
    helpCenter: {
        main: 'https://voicit-help-center.super.site',
        tutorials: {
            onboarding:
                'https://voicit-help-center.super.site/guas-y-tutoriales/crea-tu-primer-resumen-con-voicit'
        }
    },
    integrations: {
        zapier: {
            voicit: 'https://zapier.com/apps/voicit/integrations',
            notion: 'https://zapier.com/apps/notion/integrations/voicit/1551926/create-notion-pages-for-new-voicit-meetings',
            discord:
                'https://zapier.com/apps/discord/integrations/voicit/1552797/get-discord-notifications-for-new-voicit-meetings',
            slack: 'https://zapier.com/apps/slack/integrations/voicit/1552803/get-slack-notifications-for-new-voicit-meetings',
            drive: 'https://zapier.com/apps/google-drive/integrations/voicit/1552805/create-text-files-in-google-drive-for-new-voicit-meetings',
            hubspot:
                'https://zapier.com/apps/hubspot/integrations/voicit/1552808/create-or-update-hubspot-contacts-with-new-voicit-meetings'
        }
    },
    videoPlatforms: {
        googleMeet: 'https://meet.google.com/'
    }
};
