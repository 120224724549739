import React, { useRef, useState } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { pdf } from '@react-pdf/renderer';
import { PropTypes } from 'prop-types';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog.jsx';
import PricingDialog from '@common/components/dialogs/PricingDialog.jsx';
import IconLoader from '@common/components/loaders/IconLoader.jsx';
import { subscriptionPlans } from '@common/helpers/user.js';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser.js';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { getReportBlocks } from '@setup/api/reportBlocks/reportBlocks.js';
import SummaryPDF from './ReportPDF.jsx';

export default function DownloadSummaryButton({
    meetingId,
    size,
    meetingTitle,
    creationDate,
    participants,
    duration
}) {
    const notification = useNotification();
    const { getPlan } = useUser();

    const inputRef = useRef(null);

    const [downloaded, setDownloaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState('Descargar PDF');
    const [open, setOpen] = useState(false);
    const [openPricingDialog, setOpenPricingDialog] = useState(false);

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onClickDownload(e.target.files[0]);
            setOpen(false);
        }
    };

    const onClickDownload = async (logotype) => {
        setDownloading(true);
        const { reportBlocks } = await getReportBlocks({ meetingId });

        const blob = await pdf(
            <SummaryPDF
                blocks={reportBlocks}
                meetingTitle={meetingTitle}
                creationDate={creationDate}
                participants={participants}
                duration={duration}
                logotype={logotype}
            />
        ).toBlob();

        await downloadFileFromBlob({ blob: blob, filename: meetingTitle, extension: 'pdf' });
        setDownloaded(true);
        setTooltipTitle('¡Descargado!');
        notification('pdf-success-donwloaded');

        setTimeout(() => {
            setDownloaded(false);
            setDownloading(false);
            setTooltipTitle('Descargar');
        }, 2000);
    };

    return (
        <>
            <Tooltip title={tooltipTitle}>
                <IconButton
                    id='clickDownloadReport'
                    sx={{
                        borderRadius: '4px',
                        border: '0px solid'
                    }}
                    aria-label='download-report'
                    onClick={() => {
                        if (getPlan().name === subscriptionPlans.free.name) {
                            setOpenPricingDialog(true);
                            return;
                        }
                        setOpen(true);
                    }}
                >
                    {downloaded ? (
                        <CheckOutlinedIcon fontSize={size ? size : 'inherit'} />
                    ) : (
                        <>
                            {downloading ? (
                                <IconLoader fontSize={size ? size : 'inherit'} />
                            ) : (
                                <FileDownloadOutlinedIcon
                                    id='clickDownloadReport'
                                    fontSize={size ? size : 'inherit'}
                                />
                            )}
                        </>
                    )}
                </IconButton>
            </Tooltip>
            <input
                ref={inputRef}
                type='file'
                id='input-file-upload'
                multiple={true}
                onChange={handleChange}
            />
            <ConfirmationDialog
                open={open}
                title='Descargar informe'
                message='Descarga tu informe en PDF, añade tu logo para personalizar el documento.'
                onConfirm={() => inputRef.current.click()}
                onClose={() => {
                    onClickDownload();
                    setOpen(false);
                }}
                confirmButtonText='Descargar'
                closeButtonText='Elegir logo y descargar'
            />
            <PricingDialog
                openDialog={openPricingDialog}
                setOpenDialog={setOpenPricingDialog}
                title='Actualiza a Unlimited para descargar tu informe'
                defaultPlanId={1}
            />
        </>
    );
}

DownloadSummaryButton.propTypes = {
    meetingId: PropTypes.number,
    size: PropTypes.string,
    meetingTitle: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.number.isRequired
};
