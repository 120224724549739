import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';

export default function LoaderSummary() {
    return (
        <Grid container direction='column' spacing={1}>
            <Grid item>
                <Typography component='div' key='s01-02' variant='h3'>
                    <Skeleton animation='wave' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s01-03' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s01-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
                <Typography component='div' key='s01-05' variant='body1'>
                    <Skeleton animation='wave' width='60%' />
                </Typography>
                <Typography component='div' key='s01-06' variant='body1'>
                    <Skeleton animation='wave' width='30%' />
                </Typography>
            </Grid>

            <Grid item>
                <Typography component='div' key='s02-02' variant='h3'>
                    <Skeleton animation='wave' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s02-03' variant='body1'>
                    <Skeleton animation='wave' width='70%' />
                </Typography>
                <Typography component='div' key='s02-04' variant='body1'>
                    <Skeleton animation='wave' width='90%' />
                </Typography>
                <Typography component='div' key='s02-05' variant='body1'>
                    <Skeleton animation='wave' width='80%' />
                </Typography>
                <Typography component='div' key='s02-06' variant='body1'>
                    <Skeleton animation='wave' width='40%' />
                </Typography>
            </Grid>

            <Grid item>
                <Typography component='div' key='s03-02' variant='h3'>
                    <Skeleton animation='wave' />
                </Typography>
            </Grid>
            <Grid item>
                <Typography component='div' key='s03-03' variant='body1'>
                    <Skeleton animation='wave' width='65%' />
                </Typography>
                <Typography component='div' key='s03-04' variant='body1'>
                    <Skeleton animation='wave' width='85%' />
                </Typography>
                <Typography component='div' key='s03-05' variant='body1'>
                    <Skeleton animation='wave' width='95%' />
                </Typography>
                <Typography component='div' key='s03-06' variant='body1'>
                    <Skeleton animation='wave' width='55%' />
                </Typography>
            </Grid>
        </Grid>
    );
}
