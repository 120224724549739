import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import shadows from '@common/theme/palette/shadows';

// ! LEGACY: used in microsummaries which is not maintained
export default function EditionFloatingMenu({ handleEditContent, handleDelete, contentValue }) {
    return (
        <Grid item sx={{ zIndex: 1 }}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: shadows.elevated,
                    borderRadius: 1
                }}
            >
                <Grid item>
                    <Tooltip title='Editar'>
                        <IconButton
                            sx={{
                                borderRadius: '4px',
                                border: '0px solid'
                            }}
                            aria-label='copy-summary'
                            color='primary'
                            onClick={handleEditContent}
                        >
                            <EditOutlinedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <CopyIconButton content={contentValue} size='small' />
                </Grid>
                <Grid item>
                    <Tooltip title='Eliminar'>
                        <IconButton
                            sx={{
                                borderRadius: '4px',
                                border: '0px solid'
                            }}
                            aria-label='copy-summary'
                            color='primary'
                            onClick={handleDelete}
                        >
                            <DeleteOutlineOutlinedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

EditionFloatingMenu.propTypes = {
    handleEditContent: PropTypes.func.isRequired,
    contentValue: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired
};
