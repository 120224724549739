import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import LoaderEmptyState from '@common/components/loaders/LoaderEmptyState';
import Paragraph from '@common/components/Paragraph';
import palette from '@common/theme/palette/palette';

export default function OldSummary({ content, transcriptionStatus }) {
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}
        >
            {content && content !== null ? (
                <>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    display: 'inline-flex',
                                    maxHeight: 'auto'
                                }}
                                xs={11}
                            >
                                <Typography
                                    component='span'
                                    variant='subtitle1'
                                    color={palette.primary[300]}
                                >
                                    Tu resumen
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CopyIconButton content={content} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paragraph text={content} />
                    </Grid>
                </>
            ) : (
                <>
                    {transcriptionStatus == 2 ? (
                        <Grid item sx={{ mt: 5 }}>
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid item>
                                    <Typography component='span' variant='overline'>
                                        Parece que aún no has generado ningún resumen
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography paragraph={true} variant='body2' align='center'>
                                        Vuelve atrás, haz click sobre la fila de tu reunión y crea
                                        tu resumen automático.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item sx={{ mt: 5 }}>
                            <LoaderEmptyState
                                title='Generando el resumen de tu reunión'
                                explanation='Enviaremos un email a tu correo cuando el proceso termine. Recarga la página para actualizar su estado.'
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

OldSummary.propTypes = {
    content: PropTypes.string,
    transcriptionStatus: PropTypes.string.isRequired
};
