import EastIcon from '@mui/icons-material/East';
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';
import IntegrationCardSkeleton from './IntegrationCardSkeleton';

IntegrationItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string,
    logo: PropTypes.string.isRequired,
    customOnClick: PropTypes.func,
    tag: PropTypes.string
};

export default function IntegrationItem({ logo, title, description, url, customOnClick, tag }) {
    return (
        <IntegrationCardSkeleton
            borderType='solid'
            borderWidth={1}
            borderColor={palette.primary[100]}
        >
            <Grid xs={12} container>
                <Grid id='editTemplate' item height='auto'>
                    <Stack spacing={1}>
                        <Grid container direction={'row'} alignItems='center'>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                        border: `${1}px solid ${palette.primary[50]}`,
                                        borderRadius: '4px',
                                        position: 'relative'
                                    }}
                                    height='48px'
                                    width='48px'
                                >
                                    <img src={logo} width='32px' alt='image' />
                                </Box>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant='text'
                                    endIcon={<EastIcon fontSize='small' />}
                                    onClick={customOnClick ? customOnClick : () => window.open(url)}
                                >
                                    Conectar
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} alignItems='center'>
                            <Grid item>
                                <Typography
                                    id='editTemplate'
                                    variant='h5'
                                    fontWeight='regular'
                                    color={palette.primary[800]}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            {tag ? (
                                <Grid item>
                                    <Chip
                                        sx={{
                                            borderRadius: 1,
                                            justifyContent: 'left',
                                            ml: 1
                                        }}
                                        label={tag}
                                        color={'chip_grey'}
                                        size='small'
                                    />
                                </Grid>
                            ) : null}
                        </Grid>

                        <Typography
                            id='editTemplate'
                            variant='body2'
                            fontWeight='regular'
                            color={palette.primary[500]}
                        >
                            {description}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </IntegrationCardSkeleton>
    );
}
