import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import useNotification from '@common/hooks/useNotification';
import { resetPassword } from '@setup/api/auth';
import FormPasswordView from './FormPasswordView';

export default function FormPassword({ token }) {
    const notification = useNotification();

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResetPassword = async (data) => {
        setLoading(true);
        try {
            if (data.repeatedPassword === data.newPassword) {
                await resetPassword({
                    password: data.newPassword,
                    token: token[0]
                });
                notification('password-updated');
            } else {
                setAlert(true);
                setAlertMessage('Las contraseñas no coinciden');
            }
            setLoading(false);
        } catch (error) {
            // TODO: Include notification error here
            setAlert(true);
            setAlertMessage(error.response.data.message);
            setLoading(false);
        }
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append('federated', false);
        handleResetPassword(Object.fromEntries(data.entries()));
    };

    return (
        <FormPasswordView
            handleSubmit={handleSubmitForm}
            alert={alert}
            alertMessage={alertMessage}
            loading={loading}
        />
    );
}

FormPassword.propTypes = {
    token: PropTypes.string.isRequired
};
