// TODO: turn to JSON format

const meetingStatus = {
    TRANSCRIPTION_STARTED: '0',
    TRANSCRIPTION_IN_PROGRESS: '1',
    TRANSCRIPTION_SUCCEED: '2',
    TRANSCRIPTION_ERROR: '3',
    SUMMARY_STARTED: '4',
    SUMMARY_IN_PROGRESS: '5',
    SUMMARY_SUCCEED: '6',
    SUMMARY_ERROR: '7',
    AUDIO_STARTED: '8',
    AUDIO_IN_PROGESS: '9',
    AUDIO_SUCCEED: '10',
    AUDIO_ERROR: '11',
    SERVER_ERROR: '12',
    STAND_BY: '13'
};

module.exports = {
    meetingStatus
};
