import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { PropTypes } from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import TagCreationDialog from './TagCreationDialog';

export default function TagCreationButtonView({
    name,
    color,
    anchorElUser,
    handleOpenTagCreationMenu,
    handleCloseTagCreationMenu,
    handleOnTitleChange,
    handleOnColorChange,
    alertName,
    alertMessage,
    handleCreateCustomTag,
    IsIconButton
}) {
    return (
        <>
            {IsIconButton ? (
                <IconButtonWithTooltip
                    icon={<AddOutlinedIcon fontSize='small' />}
                    tooltipText={'Crea tu primera etiqueta'}
                    onClick={handleOpenTagCreationMenu}
                    size={'small'}
                />
            ) : (
                <Button
                    sx={{
                        flexGrow: 1
                    }}
                    variant={'text'}
                    color={'primary'}
                    size={'small'}
                    endIcon={<AddOutlinedIcon />}
                    disableElevation={true}
                    onClick={handleOpenTagCreationMenu}
                >
                    Crear nueva etiqueta
                </Button>
            )}

            <Popover
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseTagCreationMenu}
            >
                <TagCreationDialog
                    handleCloseTagCreationMenu={handleCloseTagCreationMenu}
                    name={name}
                    color={color}
                    handleOnTitleChange={handleOnTitleChange}
                    handleOnColorChange={handleOnColorChange}
                    alertName={alertName}
                    alertMessage={alertMessage}
                    handleCreateCustomTag={handleCreateCustomTag}
                />
            </Popover>
        </>
    );
}

TagCreationButtonView.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    anchorElUser: PropTypes.object,
    handleOpenTagCreationMenu: PropTypes.func.isRequired,
    handleCloseTagCreationMenu: PropTypes.func.isRequired,
    handleOnTitleChange: PropTypes.func.isRequired,
    handleOnColorChange: PropTypes.func.isRequired,
    alertName: PropTypes.bool.isRequired,
    alertMessage: PropTypes.string.isRequired,
    handleCreateCustomTag: PropTypes.func.isRequired,
    IsIconButton: PropTypes.bool
};
