import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import VoicitAvatar from '../avatars/VoicitAvatar';
import PricingDialog from '../dialogs/PricingDialog';
import AvatarProfile from './avatarProfile/AvatarProfile';

export default function NavigationBar({ isShared }) {
    const navigate = useNavigate();
    const { getPlan } = useUser();

    return (
        <Box
            sx={{
                flexGrow: 0,
                position: 'fixed', // Establece la posición fija
                top: 0, // Asegura que la barra esté en la parte superior
                width: '100%', // Ocupa todo el ancho
                zIndex: 1000 // Ajusta la z-index para que esté en la parte superior
            }}
            id='navbar'
        >
            <AppBar
                position='static'
                sx={{
                    borderBottom: 1,
                    borderColor: palette.primary[50],
                    height: 'auto',
                    backgroundColor: palette.common.white
                }}
                elevation={0}
            >
                <Container maxWidth='display'>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { sm: 'flex' },
                                cursor: 'pointer'
                            }}
                            onClick={
                                isShared && !getPlan()
                                    ? () => navigate(navigation.app.routes.login)
                                    : () => navigate(navigation.app.routes.repository)
                            }
                        >
                            <VoicitAvatar size={42} />
                        </Box>
                        <Box>
                            {isShared ? null : (
                                <Grid
                                    container
                                    spacing={1}
                                    sx={{ direction: 'row', alignItems: 'center' }}
                                >
                                    {getPlan()?.name === subscriptionPlans.free.name ? (
                                        <>
                                            <Grid item>
                                                <PricingDialog button={true} />
                                            </Grid>
                                        </>
                                    ) : null}
                                    <Grid item>
                                        <AvatarProfile />
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}

NavigationBar.propTypes = {
    isShared: PropTypes.bool
};
