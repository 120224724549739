import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Chip, Divider, Grid, Link, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import selectorRoles from '@common/helpers/selectorRoles.json';
import palette from '@common/theme/palette/palette';

RoleSelector.propTypes = {
    currentRoleLabel: PropTypes.string,
    onRoleChange: PropTypes.func,
    speakers: PropTypes.any
};

export default function RoleSelector({ currentRoleLabel, onRoleChange, speakers }) {
    const userUseCase = useSelector((state) => state.user.userUseCase);
    const withoutUseCase = userUseCase === selectorRoles[selectorRoles.length - 1].type;
    const [anchorElChip, setAnchorElChip] = useState(null);
    const [selectorRolesFiltered, setSelectorRolesFiltered] = useState(
        withoutUseCase
            ? selectorRoles
            : selectorRoles.filter((useCaseRoles) => useCaseRoles.type === userUseCase)
    );
    const [seeMore, setSeeMore] = useState(false);

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const handleSeeMore = () => {
        setSeeMore(true);
        setSelectorRolesFiltered(selectorRoles);
    };

    const handleSeeLess = () => {
        setSeeMore(false);
        setSelectorRolesFiltered(
            selectorRoles.filter((useCaseRoles) => useCaseRoles.type === userUseCase)
        );
    };

    const handleOnRoleChange = (data) => {
        onRoleChange(data);
        handleCloseList();
    };

    return (
        <Grid
            container
            sx={{ direction: 'row', alignItems: 'center', flexDirection: 'row', flexGrow: 1 }}
        >
            <Grid item mr={1}>
                <Tooltip title={'Cambiar rol del participante'}>
                    <Chip
                        sx={{
                            flexGrow: 1,
                            borderRadius: 2,
                            justifyContent: 'left'
                        }}
                        onClick={handleOpenList}
                        label={!currentRoleLabel ? 'Asignar rol' : currentRoleLabel}
                        color={!currentRoleLabel ? 'chip_primary_soft' : 'chip_grey'}
                        size='small'
                        onDelete={!currentRoleLabel ? null : () => onRoleChange(null)}
                        deleteIcon={!currentRoleLabel ? null : <CancelIcon />}
                        icon={!currentRoleLabel ? <ExpandMoreOutlinedIcon /> : null}
                    />
                </Tooltip>
            </Grid>
            <Popover
                sx={{ mt: '45px', maxHeight: 450, display: 'flex' }}
                id='menu-filters'
                anchorEl={anchorElChip}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={Boolean(anchorElChip)}
                onClose={handleCloseList}
            >
                <Grid
                    container
                    spacing={0}
                    direction={'column'}
                    sx={{ alignItems: 'left', m: 0, mb: 1 }}
                >
                    <Grid item>
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', pl: 2, pr: 2, pb: 1, pt: 1 }}
                        >
                            {selectorRolesFiltered.map((useCaseRoles, index) => (
                                <div key={useCaseRoles.type}>
                                    <Typography variant='caption'>{useCaseRoles.label}</Typography>
                                    {useCaseRoles.roles.map((role) => (
                                        <Grid item key={role.label} sx={{ mt: 1, ml: 1, mr: 1 }}>
                                            <Stack
                                                direction='row'
                                                spacing={1}
                                                alignItems='center'
                                                display='flex'
                                            >
                                                <Chip
                                                    sx={{
                                                        flexGrow: 1,
                                                        borderRadius: 2,
                                                        justifyContent: 'left'
                                                    }}
                                                    label={role.label}
                                                    onClick={() => {
                                                        handleOnRoleChange({
                                                            type: useCaseRoles.type,
                                                            ...role
                                                        });
                                                    }}
                                                    color={'chip_grey'}
                                                    icon={
                                                        speakers.find(
                                                            (s) => s.roleLabel === role.label
                                                        ) ? (
                                                            <DoneIcon />
                                                        ) : null
                                                    }
                                                    size='small'
                                                />
                                            </Stack>
                                        </Grid>
                                    ))}
                                    {selectorRolesFiltered.length - 1 !== index ? (
                                        <Divider sx={{ opacity: 0, mb: 1 }} />
                                    ) : null}
                                </div>
                            ))}
                            {withoutUseCase ? null : (
                                <Link
                                    component='button'
                                    variant='caption'
                                    underline='hover'
                                    textAlign='start'
                                    color='primary'
                                    onClick={seeMore ? handleSeeLess : handleSeeMore}
                                    sx={{
                                        mt: 2,
                                        '&:hover': {
                                            color: `${palette.grey[600]}`,
                                            textDecoration: `underline ${palette.grey[600]}`
                                        }
                                    }}
                                >
                                    {seeMore ? 'Ver menos' : 'Ver otros roles'}
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    );
}
