import { ERROR, INFO, PRIMARY, SUCCESS, WARNING } from './fullPalette';

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const GRADIENTS = {
    primary: createGradient(PRIMARY[50], PRIMARY.main),
    info: createGradient(INFO[50], INFO.main),
    success: createGradient(SUCCESS[50], SUCCESS.main),
    warning: createGradient(WARNING[50], WARNING.main),
    error: createGradient(ERROR[50], ERROR.main)
};
