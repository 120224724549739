import React from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import Paragraph from '@common/components/Paragraph';
import palette from '@common/theme/palette/palette';

export default function Content({ summary, summaryTopics }) {
    return (
        <>
            <Grid container direction='column' alignItems='left' spacing={0}>
                <Grid item sx={{ mt: 2, mb: 0 }}>
                    <Grid container direction='row' alignItems='left'>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Typography component='span' variant='h6'>
                                Resumen
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CopyIconButton content={summary} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{ textAlign: 'justify' }}>
                    <Paragraph text={summary} />
                </Grid>

                {summaryTopics?.length > 0 && summaryTopics[0] ? (
                    // We currently introduce a blank topic when creating the meeting, that´s why we check the first element
                    <>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={0}
                                sx={{ direction: 'row', alignItems: 'left' }}
                            >
                                {summaryTopics.map((topic) => (
                                    <Chip
                                        key={topic}
                                        size='small'
                                        label={topic}
                                        sx={{
                                            mr: 1,
                                            mb: 1,
                                            color: palette.primary.main,
                                            backgroundColor: palette.primary[50]
                                        }}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>{/* No topics provided */}</>
                )}
            </Grid>
        </>
    );
}

Content.propTypes = {
    handleUpdateSummary: PropTypes.func,
    summary: PropTypes.string,
    setOnSummaryEdit: PropTypes.func,
    onSummaryEdit: PropTypes.bool,
    summaryTopics: PropTypes.arrayOf(PropTypes.string)
};
