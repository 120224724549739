import Chip from '@mui/material/Chip';
import { PropTypes } from 'prop-types';
import useUser from '@common/hooks/useUser';

SubscriptionPlanTag.propTypes = {
    sx: PropTypes.any
};

export default function SubscriptionPlanTag({ sx }) {
    const { getPlan } = useUser();

    return (
        <>
            <Chip
                sx={{
                    ...sx,
                    borderRadius: 1,
                    justifyContent: 'left',
                    mr: 20,
                    mt: 1
                }}
                label={getPlan()?.label}
                color={getPlan()?.chipColor}
                size='small'
            />
        </>
    );
}
