import React from 'react';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';

export default function Paragraph(props) {
    const text = props.text;
    return text.split('\n').map((str, index) => (
        <Typography
            key={index}
            paragraph={true}
            variant='body1'
            color={props.color ? props.color : 'primary'}
            align='justify'
        >
            {str}
        </Typography>
    ));
}

Paragraph.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string
};
