import { configureStore } from '@reduxjs/toolkit';
import ReduxLogger from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

// Persist object config
const persistConfig = {
    key: 'counter', // ID of the persist object
    storage: storage,
    whitelist: ['auth', 'user', 'stats'] // auth and stats will be persisted
};

// Reducers combination
const reducers = rootReducer;
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(ReduxLogger)
});
