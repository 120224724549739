import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import TabHeader from '@common/components/tabs/TabHeader';
import TabPanel from '@common/components/tabs/TabPanel';
import AccountTab from './accountTab/AccountTab';
import IntegrationsTab from './integrationsTab/IntegrationsTab';
import WorkspaceTab from './workspaceTab/WorkspaceTab';

const tabs = [
    { id: 'profile', key: 'accountTab', name: 'Cuenta' },
    { id: 'workspace', key: 'workspaceTab', name: 'Equipo' },
    { id: 'integrations', key: 'integrationsTab', name: 'Integraciones' }
];

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default function ProfileTabs() {
    const navigate = useNavigate();
    const { tab } = useParams();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        navigate(`/settings/${tabs[newValue].id}`);
    };

    useEffect(() => {
        setValue(tabs.findIndex((t) => t.id === tab));
    }, [tab]);

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Grid container direction='row' alignItems='left' spacing={2} display='flex'>
                <Grid item xs={12}>
                    <TabHeader
                        tabs={tabs.map((tab) => tab.name)}
                        currentTabValue={value}
                        handleChangeTab={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TabPanel key={tabs[0].key} value={value} index={0}>
                        <AccountTab value={value} />
                    </TabPanel>
                    <TabPanel key={tabs[1].key} value={value} index={1}>
                        <WorkspaceTab />
                    </TabPanel>
                    <TabPanel key={tabs[2].key} value={value} index={2}>
                        <IntegrationsTab />
                    </TabPanel>
                    {/* This tab has been removed because currently we cannot use anything of it */}
                    {/* <TabPanel key='configurationTab' value={value} index={1}>
                        <ConfigurationTab value={value} />
                    </TabPanel> */}
                </Grid>
            </Grid>
        </Box>
    );
}
