import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplatesMockup from '@assets/images/templates/TemplatesMockup.png';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import palette from '@common/theme/palette/palette';
import NotLoggedLayout from '@pages/common/NotLoggedLayout';
import FormSignIn from './components/formSignIn/FormSignIn';

export default function SignInView() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigateSignUp = () => {
        navigate(
            location.hash
                ? `${navigation.app.routes.signup}${navigation.extension.hash.signin}`
                : navigation.app.routes.signup
        );
    };

    const handleNavigateForgottenPasswordPage = () => {
        navigate(navigation.app.routes.passwordReset);
    };

    return (
        <Page title='Iniciar sesión'>
            <NotLoggedLayout
                centeredHorizontally={true}
                textSize={'h4'}
                textColor={palette.common.white}
                image={TemplatesMockup}
                backColor={`${palette.primary.main}`}
                darkMode={true}
            >
                <FormSignIn />
                <Grid
                    container
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Link
                            component='button'
                            variant='body2'
                            underline='hover'
                            color={palette.primary[900]}
                            onClick={handleNavigateSignUp}
                        >
                            Crear cuenta
                        </Link>
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <Link
                                component='button'
                                variant='body2'
                                underline='hover'
                                color={palette.primary[900]}
                                onClick={handleNavigateForgottenPasswordPage}
                            >
                                He olvidado mi contraseña
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </NotLoggedLayout>
        </Page>
    );
}
