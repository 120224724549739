export const blockNotification = [
    {
        type: 'retrieving-blocks-error',
        message: 'Error al traer la información.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'updating-blocks-error',
        message: 'Error actualizando la información.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'creating-blocks-error',
        message: 'Error creando nuevo bloque.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'deleting-blocks-error',
        message: 'Error borrando bloque.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'dragging-blocks-error',
        message: 'Error cambiando posición.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'creating-predefined-completion-error',
        message: 'Error creando contenido predefinido.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'error-copying-blocks-content',
        message: 'Error copiando contenido.',
        actionTitle: null,
        actionFunc: null
    }
];
