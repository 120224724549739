import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { PropTypes } from 'prop-types';
import UsageWarning from './UsageWarning';

DialogUsageLimit.propTypes = {
    handleCloseFromOutside: PropTypes.function
};

export default function DialogUsageLimit({ handleCloseFromOutside }) {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        handleCloseFromOutside();
    };

    useEffect(() => {
        setOpen(true);
    }, []);
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true} onClose={handleClose}>
            <DialogContent>
                <UsageWarning />
            </DialogContent>
        </Dialog>
    );
}
