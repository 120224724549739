import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import useNotification from '@common/hooks/useNotification';
import copyToClipboard from '@common/utils/copyToClipboard';
import { getTimeMeetingIntervention } from '@common/utils/date';
import { initializeSpeakers, setSpeakers } from '@pages/meeting/utils';
import { getReadSignedURL } from '@setup/api/gcs';
import { addMeetingSpeaker, deleteMeetingSpeaker } from '@setup/api/meeting/meeting';
import {
    getAllMeetingInterventions,
    getMeetingInterventionSpeakersWithTurn,
    updateMeetingInterventionSpeaker,
    updateMeetingInterventionSpeakerByTurn
} from '@setup/api/meetingInterventions/meetingInterventions';
import { getSharedMeetingInterventionSpeakersWithTurn } from '@setup/api/meetingInterventions/sharedMeetingInterventions';
import { copyReportBlocksContent } from '@setup/api/reportBlocks/reportBlocks';
import MeetingSideMenuView from './MeetingSideMenuView';

MeetingSideMenu.propTypes = {
    id: PropTypes.number,
    speakers: PropTypes.arrayOf(PropTypes.string).isRequired,
    tag: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    firstSpeaker: PropTypes.string,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    handleSpeakerTurnChange: PropTypes.func,
    authToken: PropTypes.string,
    speakersInterventionPercentages: PropTypes.arrayOf(PropTypes.string),
    platform: PropTypes.string,
    handleUpdateBlocksWords: PropTypes.func.isRequired,
    handleChangeTab: PropTypes.func,
    currentTab: PropTypes.string,
    setCurrentAudioTime: PropTypes.func.isRequired,
    currentAudioTime: PropTypes.number.isRequired,
    setSlideChange: PropTypes.func.isRequired
};

export default function MeetingSideMenu({
    id,
    speakers,
    tag,
    date,
    time,
    title,
    duration,
    firstSpeaker,
    isShared,
    meetingId,
    handleSpeakerTurnChange,
    authToken,
    handleChangeTab,
    currentTab,
    speakersInterventionPercentages,
    filename,
    platform,
    handleUpdateBlocksWords,
    setCurrentAudioTime,
    currentAudioTime,
    setSlideChange
}) {
    const notification = useNotification();
    const audioUrl = useRef('');
    const availableTurns = useRef();

    // Introducing speakers order here
    const [meetingSpeakers, setMeetingSpeakers] = useState(
        speakers && speakers !== null
            ? firstSpeaker === speakers[0]
                ? initializeSpeakers(speakers)
                : initializeSpeakers(speakers.reverse())
            : null
    );
    const [changeState, setChangeState] = useState(true);

    const handleCreateAsyncSpeaker = async (speaker) => {
        try {
            await addMeetingSpeaker({ meetingId, speaker });
            // ! Below is being managed inside the speakerform
            // setMeetingSpeakers([...meetingSpeakers]);
            setChangeState(!changeState);
            return true;
        } catch (error) {
            notification('add-speaker-error');
            return false;
        }
    };

    const handleDeleteSpeaker = async (speaker) => {
        try {
            await deleteMeetingSpeaker({ meetingId, speaker });
            // ! Below is being managed inside the speakerform
            // setMeetingSpeakers(meetingSpeakers.filter((ms) => ms.name !== speaker));
        } catch (error) {
            notification('delete-speaker-error');
        }
    };

    const handleUpdateSpeakerByTurn = async (newSpeaker, oldSpeaker) => {
        try {
            if (newSpeaker.turn === -1) {
                // 1 - Reset current speaker to Speaker X label
                await updateMeetingInterventionSpeaker({
                    meetingId,
                    oldSpeaker: newSpeaker.currentName,
                    newSpeaker: `Speaker ${oldSpeaker.turn}`
                });
                // 1.1 - Replacing speakers in summary
                await handleUpdateBlocksWords({
                    meetingId,
                    currentWords: [newSpeaker.currentName],
                    newWords: [`Participante ${oldSpeaker.turn + 1}`]
                });
            } else if (oldSpeaker && oldSpeaker !== null && oldSpeaker.turn >= 0) {
                // Changing the turn of a speaker for an another available turn
                // 1 - Reset current speaker
                await updateMeetingInterventionSpeaker({
                    meetingId,
                    oldSpeaker: newSpeaker.currentName,
                    newSpeaker: `Speaker ${oldSpeaker.turn}`
                });
                // 1.1 - Replacing speakers in summary
                await handleUpdateBlocksWords({
                    meetingId,
                    currentWords: [newSpeaker.currentName],
                    newWords: [`Participante ${oldSpeaker.turn + 1}`]
                });
                // 2 - Set new turn
                await updateMeetingInterventionSpeakerByTurn({
                    meetingId,
                    newTurn: newSpeaker.turn,
                    newSpeaker: newSpeaker.currentName
                });
                // 2.1 - Replacing speakers in summary
                await handleUpdateBlocksWords({
                    meetingId,
                    currentWords: [`Participante ${oldSpeaker.turn + 1}`],
                    newWords: [newSpeaker.currentName]
                });
            } else {
                // 1- Adding a turn to a speaker without it
                await updateMeetingInterventionSpeakerByTurn({
                    meetingId,
                    newTurn: newSpeaker.turn,
                    newSpeaker: newSpeaker.currentName
                });
                // 1.1 - Replacing speakers in summary
                // ! Cuidado, abajo Participante sólo sirve para reuniones en español, buscar otro enfoque en el futuro
                await handleUpdateBlocksWords({
                    meetingId,
                    currentWords: [`Participante ${newSpeaker.turn + 1}`],
                    newWords: [newSpeaker.currentName]
                });
            }
            setChangeState(!changeState);
            handleSpeakerTurnChange();
        } catch (error) {
            notification('change-speaker-turn-error');
        }
    };

    const handleCopyReport = async () => {
        try {
            const reportContent = await copyReportBlocksContent(meetingId);
            await copyToClipboard(reportContent);
        } catch (error) {
            notification('error-copying-blocks-content');
        }
    };

    const handleCopyTranscription = async () => {
        try {
            let formattedInterventions = '';
            const { interventions: meetingInterventions } = await getAllMeetingInterventions({
                meetingId: id
            });
            meetingInterventions.forEach((intervention) => {
                formattedInterventions += `${intervention.speaker} (${getTimeMeetingIntervention({
                    hoursDisabled: duration > 60 * 60 ? false : true,
                    timeKey: intervention.timeKey1
                })}):\n ${intervention.lines}\n`;
            });
            await copyToClipboard(formattedInterventions);
        } catch (error) {
            notification('error-copying-transcription');
        }
    };

    // TODO: Refactor -> Why we need to do all of that? Refactor
    useEffect(() => {
        // speakers with turn are the speakers detected into the transcription, speakers.length give us the turns available
        (async () => {
            if (meetingSpeakers.length >= 1) {
                const speakersWithTurn = isShared
                    ? await getSharedMeetingInterventionSpeakersWithTurn(meetingId, authToken)
                    : await getMeetingInterventionSpeakersWithTurn(meetingId);
                setMeetingSpeakers(
                    setSpeakers({
                        meetingParticipants: meetingSpeakers.map((ms) => ms.name),
                        transcriptionSpeakers: speakersWithTurn
                    })
                );
                availableTurns.current = speakersWithTurn.length;
            }
        })();
    }, [changeState]);

    useEffect(() => {
        (async () => {
            if (!isShared && filename) {
                audioUrl.current = await getReadSignedURL({ filename });
            }
        })();
    }, []);

    return (
        <MeetingSideMenuView
            id={id}
            speakers={meetingSpeakers}
            title={title}
            tag={tag}
            date={date}
            time={time}
            duration={duration}
            handleDeleteSpeaker={handleDeleteSpeaker}
            isShared={isShared}
            meetingId={meetingId}
            handleUpdateSpeakerByTurn={handleUpdateSpeakerByTurn}
            handleChangeTab={handleChangeTab}
            currentTab={currentTab}
            speakersInterventionPercentages={speakersInterventionPercentages}
            handleCreateAsyncSpeaker={handleCreateAsyncSpeaker}
            setMeetingSpeakers={setMeetingSpeakers}
            handleCopyReport={handleCopyReport}
            filename={filename}
            platform={platform}
            audioUrl={audioUrl.current}
            availableTurns={availableTurns.current}
            handleCopyTranscription={handleCopyTranscription}
            setCurrentAudioTime={setCurrentAudioTime}
            currentAudioTime={currentAudioTime}
            setSlideChange={setSlideChange}
        />
    );
}
