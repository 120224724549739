import { authApiCall, endpoints } from '.';

export async function getMeetingNotes(meetingId) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.notes,
        path: `/${meetingId}`
    });

    return data;
}
