import React, { useState } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { pdf } from '@react-pdf/renderer';
import { PropTypes } from 'prop-types';
import IconLoader from '@common/components/loaders/IconLoader.jsx';
import useNotification from '@common/hooks/useNotification';
import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { getAllMeetingInterventions } from '@setup/api/meetingInterventions/meetingInterventions.js';
import TranscriptionPDF from './TranscriptionPDF.jsx';

export default function DownloadTranscriptionButton({
    meetingId,
    size,
    meetingTitle,
    creationDate,
    participants,
    duration
}) {
    const notification = useNotification();

    const [downloaded, setDownloaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState('Descargar PDF');

    const onClickDownload = async () => {
        setDownloading(true);
        const { interventions } = await getAllMeetingInterventions({
            meetingId
        });
        const blob = await pdf(
            <TranscriptionPDF
                interventions={interventions}
                meetingTitle={meetingTitle}
                creationDate={creationDate}
                participants={participants}
                duration={duration}
            />
        ).toBlob();

        await downloadFileFromBlob({ blob: blob, filename: meetingTitle, extension: 'pdf' });
        setDownloaded(true);
        setTooltipTitle('¡Descargado!');
        notification('pdf-success-donwloaded');

        setTimeout(() => {
            setDownloaded(false);
            setDownloading(false);
            setTooltipTitle('Descargar');
        }, 2000);
    };

    return (
        <Tooltip title={tooltipTitle}>
            <IconButton
                id='clickDownloadTranscription'
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                aria-label='download-transcription'
                onClick={onClickDownload}
            >
                {downloaded ? (
                    <CheckOutlinedIcon fontSize={size ? size : 'inherit'} />
                ) : (
                    <>
                        {downloading ? (
                            <IconLoader fontSize={size ? size : 'inherit'} />
                        ) : (
                            <FileDownloadOutlinedIcon
                                id='clickDownloadTranscription'
                                fontSize={size ? size : 'inherit'}
                            />
                        )}
                    </>
                )}
            </IconButton>
        </Tooltip>
    );
}

DownloadTranscriptionButton.propTypes = {
    meetingId: PropTypes.number.isRequired,
    size: PropTypes.string,
    meetingTitle: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.number.isRequired
};
