import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { urls } from './urls';

export const subscriptionPlans = {
    free: {
        name: 'FREE',
        label: 'Gratuito',
        chipColor: 'chip_primary_soft'
    },
    premium: {
        name: 'PREMIUM',
        label: 'Pro',
        chipColor: 'chip_secondary',
        features: [
            {
                key: 'usage',
                text: 'Hasta 1.000 minutos/mes',
                icon: <CheckCircleTwoToneIcon color={'primary'} />
            },
            {
                key: 'templates',
                text: 'Informes con Plantilla Predefinida: resumen breve, índice y resumen detallado',
                icon: <CheckCircleTwoToneIcon color={'primary'} />
            },
            {
                key: 'chat',
                text: 'Report Booster: Chat inteligente para extraer información específica',
                icon: <HighlightOffTwoToneIcon color={'primary'} />
            },
            {
                key: 'personalized-sections',
                text: 'Secciones personalizadas',
                icon: <HighlightOffTwoToneIcon color={'primary'} />
            },
            {
                key: 'audio-sync',
                text: 'Sincronización con audio',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'integrations',
                text: 'Integraciones con ATS, CRM y más',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'export',
                text: 'Exportar y descargar información',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            }
        ],
        annuallyPrice: '18',
        monthlyPrice: '24',
        monthlyPriceDisccount: '',
        annuallyPaymentUrl: urls.payments.premium.annually,
        monthlyPaymentUrl: urls.payments.premium.monthly
    },
    unlimited: {
        name: 'UNLIMITED',
        label: 'Unlimited',
        chipColor: 'chip_secondary_primary',
        features: [
            {
                key: 'usage',
                text: 'Minutos ilimitados',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'templates',
                text: 'Informes avazandos con Plantillas Personalizables',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'chat',
                text: 'Report Booster: Chat inteligente para extraer información específica',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'personalized-sections',
                text: 'Secciones personalizadas',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'audio-sync',
                text: 'Sincronización con audio',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'integrations',
                text: 'Integraciones con ATS, CRM y más',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            },
            {
                key: 'export',
                text: 'Exportar y descargar información',
                icon: <CheckCircleTwoToneIcon color={'green'} />
            }
        ],
        annuallyPrice: '45',
        monthlyPrice: '60',
        monthlyPriceDisccount: '',
        annuallyPaymentUrl: urls.payments.unlimited.annually,
        monthlyPaymentUrl: urls.payments.unlimited.monthly
    }
};

export const userRoles = {
    admin: 'ADMIN',
    user: 'USER'
};

export const userWorkspaceRole = {
    ADMIN: 1,
    MEMBER: 2
};
