import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import IconLoader from '../loaders/IconLoader';

IconButtonWithTooltip.propTypes = {
    tooltipText: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    sx: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string
};

export default function IconButtonWithTooltip({
    id,
    icon,
    tooltipText,
    onClick,
    size,
    sx,
    disabled
}) {
    const [loading, setLoading] = useState(false);
    const isAsync = onClick.constructor.name === 'AsyncFunction';

    const handleAsyncClick = async () => {
        try {
            setLoading(true);
            await onClick();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Tooltip title={tooltipText}>
            <IconButton
                disabled={disabled}
                aria-label={tooltipText}
                id={id ? id : tooltipText}
                onClick={isAsync ? handleAsyncClick : onClick}
                sx={{
                    borderRadius: '4px',
                    border: '0px solid',
                    ...sx
                }}
                size={size ? size : 'medium'}
            >
                {loading ? <IconLoader /> : icon}
            </IconButton>
        </Tooltip>
    );
}
