import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import ItemsSelector from '@common/components/form/ItemsSelector';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';

StepTwoOnboarding.propTypes = {
    handleNextStep: PropTypes.func,
    handleLastStep: PropTypes.func,
    selectedTemplates: PropTypes.arrayOf(PropTypes.string),
    setSelectedTemplates: PropTypes.func,
    setCurrentTemplate: PropTypes.func,
    setUseCase: PropTypes.func,
    useCase: PropTypes.arrayOf(PropTypes.string),
    otherTemplateTitle: PropTypes.string
};

export default function StepTwoOnboarding({
    handleNextStep,
    handleLastStep,
    selectedTemplates,
    setCurrentTemplate,
    setUseCase,
    useCase,
    otherTemplateTitle
}) {
    const filteredTemplates = predefinedTemplates.filter((template) =>
        selectedTemplates.includes(template.id)
    );

    const updated = filteredTemplates.map((template) => {
        if (template.id === 'other' && otherTemplateTitle) {
            return { ...template, name: otherTemplateTitle };
        }
        return template;
    });

    return (
        <>
            <ItemsSelector
                multipleSelection={false}
                selectedItemsIds={useCase}
                setSelectedItemsIds={setUseCase}
                setCurrentItem={setCurrentTemplate}
                items={updated}
            />
            <Stack direction='row' spacing={2} sx={{ mt: 3, mb: 2 }}>
                <Button
                    onClick={handleNextStep}
                    variant='contained'
                    color='primary'
                    size='medium'
                    disableElevation={true}
                >
                    Siguiente
                </Button>
                <Button
                    onClick={handleLastStep}
                    variant='text'
                    color='primary'
                    size='medium'
                    disableElevation={true}
                >
                    Atrás
                </Button>
            </Stack>
        </>
    );
}
