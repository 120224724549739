import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import PasswordField from '@common/components/form/PasswordField';
import palette from '@common/theme/palette/palette';

export default function FormPasswordView({ handleSubmit, alert, loading, alertMessage }) {
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');

    return (
        <Box>
            <Box
                sx={{
                    mt: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                }}
            >
                <Typography component='h1' variant='h3' color={palette.primary[900]}>
                    ¡Momento de crear una nueva contraseña!
                </Typography>
                <Typography component='h1' variant='body1' color={palette.primary[300]}>
                    Introduce una contraseña nueva, asegúrate de que contenga al menos 8 caracteres
                    y diferentes símbolos.
                </Typography>
            </Box>
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <Box>
                    <PasswordField
                        alert={alert}
                        password={password}
                        setPassword={setPassword}
                        id='newPassword'
                        label='Nueva contraseña'
                    />
                    <PasswordField
                        alert={alert}
                        alertMessage={alertMessage}
                        password={repeatedPassword}
                        setPassword={setRepeatedPassword}
                        id='repeatedPassword'
                        label='Repetir nueva contraseña'
                    />
                </Box>

                {loading ? (
                    <LoadingButton
                        loading
                        loadingPosition='start'
                        variant='contained'
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disableElevation={true}
                        startIcon={<SaveIcon />}
                    >
                        Cambiando
                    </LoadingButton>
                ) : (
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disableElevation={true}
                    >
                        Cambiar contraseña
                    </Button>
                )}
            </Box>
        </Box>
    );
}

FormPasswordView.propTypes = {
    handleSubmit: PropTypes.elementType,
    alert: PropTypes.bool,
    alertMessage: PropTypes.string,
    loading: PropTypes.bool
};
