export const config = {
    // Be careful mixing http with https protocols
    gatewayUrl: process.env.REACT_APP_NODE_ENV.includes('prod')
        ? 'https://api.voicit.com'
        : process.env.REACT_APP_NODE_ENV.includes('stg')
        ? 'https://app.dev.voicit.syndeno.io'
        : 'https://app.dev.voicit.syndeno.io',

    google: {
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        clientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        bucket: process.env.REACT_APP_GOOGLE_GCS_BUCKET,
        projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
        reCaptchaKey: '6Lf-FcYlAAAAAAl5S6kD-zR9dqnVVmq-_mg60IrF' // TODO: Introduce in secrets
    }
};
