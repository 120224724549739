import { alpha } from '@mui/material/styles';

// System Palette
export const PRIMARY = {
    50: '#DCDCDC',
    100: '#C6C6C6',
    200: '#B0B0B0',
    300: '#9A9A9A',
    400: '#848484',
    500: '#6E6E6E',
    600: '#585858',
    700: '#424242',
    800: '#2C2C2C',
    main: '#161616',
    contrastText: '#fff'
};

export const SECONDARY = {
    50: '#FDFFE8',
    100: '#FAFFD0',
    200: '#F5FFA1',
    300: '#F1FE73',
    400: '#ECFE44',
    main: '#E7FE15',
    600: '#CDE213',
    700: '#B4C610',
    800: '#9AA90E',
    900: '#808D0C',
    contrastText: '#161616'
};

export const PURPLE = {
    50: '#F4EAF3',
    100: '#E9D5E7',
    200: '#DEC1DC',
    300: '#D3ACD0',
    400: '#C897C4',
    main: '#BD82B8',
    600: '#976893',
    700: '#714E6E',
    800: '#4C344A',
    900: '#261A25',
    contrastText: '#976893'
};

export const PINK = {
    50: '#FBE4EE',
    100: '#F7C9DD',
    200: '#F4AECC',
    300: '#F092BB',
    400: '#EC77AA',
    main: '#E85C99',
    600: '#BA4A7A',
    700: '#8B375C',
    800: '#5D253D',
    900: '#2E121F',
    contrastText: '#BA4A7A'
};

export const BLUE = {
    50: '#E5F5FC',
    100: '#CBEBF9',
    200: '#B1E1F6',
    300: '#97D6F3',
    400: '#7DCCF0',
    main: '#63C2ED',
    600: '#4F9BBE',
    700: '#3B748E',
    800: '#284E5F',
    900: '#14272F',
    contrastText: '#4F9BBE'
};

export const GREEN = {
    50: '#DBF0DE',
    100: '#B8E1BE',
    200: '#94D39D',
    300: '#70C47C',
    400: '#4DB55C',
    main: '#29A63B',
    600: '#21852F',
    700: '#196423',
    800: '#104218',
    900: '#08210C',
    contrastText: '#21852F'
};

export const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
    main: '#F9FAFB',
    contrastText: '#161616'
};

export const RED = {
    50: '#FBE1E0',
    100: '#F7C4C2',
    200: '#F4A6A3',
    300: '#F08884',
    400: '#EC6B66',
    main: '#E84D47',
    600: '#BA3E39',
    700: '#8B2E2B',
    800: '#5D1F1C',
    900: '#2E0F0E',
    contrastText: '#BA3E39'
};

export const ORGANGE = {
    50: '#FCE9DF',
    100: '#F9D2BF',
    200: '#F6BCA0',
    300: '#F3A580',
    400: '#F08F60',
    main: '#ED7840',
    600: '#BE6033',
    700: '#8E4826',
    800: '#5F301A',
    900: '#2F180D',
    contrastText: '#BE6033'
};

export const YELLOW = {
    50: '#FFFCED',
    100: '#FFF9DB',
    200: '#FFF3B7',
    300: '#FFED94',
    400: '#FFE770',
    main: '#FFE14C',
    600: '#CCB43D',
    700: '#99872E',
    800: '#665A1E',
    900: '#332D0F',
    contrastText: '#CCB43D'
};

export const OLIVE = {
    50: '#FAFBF7',
    100: '#F5F7EF',
    200: '#EBEEDF',
    300: '#E0E6CE',
    400: '#D6DDBE',
    main: '#CCD5AE',
    600: '#A3AA8B',
    700: '#7A8068',
    800: '#525546',
    900: '#292B23',
    contrastText: '#A3AA8B'
};

export const OCEAN = {
    50: '#EEF0FF',
    100: '#DDE0FF',
    200: '#BBC1FF',
    300: '#98A3FF',
    400: '#7684FF',
    main: '#5465FF',
    600: '#4351CC',
    700: '#323D99',
    800: '#222866',
    900: '#111433',
    contrastText: '#4351CC'
};

export const PEACH = {
    50: '#FFF8F2',
    100: '#FFF1E4',
    200: '#FFE3C9',
    300: '#FFD6AE',
    400: '#FFC893',
    main: '#FFBA78',
    600: '#CC9560',
    700: '#997048',
    800: '#664A30',
    900: '#332518',
    contrastText: '#CC9560'
};

export const LAUREL = {
    50: '#F8FAF2',
    100: '#F1F5E4',
    200: '#E3EBC8',
    300: '#D4E0AD',
    400: '#C6D691',
    main: '#B8CC76',
    600: '#93A35E',
    700: '#6E7A47',
    800: '#4A522F',
    900: '#252918',
    contrastText: '#93A35E'
};

export const WOOD = {
    50: '#FCF8F4',
    100: '#F8F1E9',
    200: '#F1E3D3',
    300: '#EBD4BE',
    400: '#E4C6A8',
    main: '#DDB892',
    600: '#B19375',
    700: '#856E58',
    800: '#584A3A',
    900: '#2C251D',
    contrastText: '#B19375'
};

export const EMERALD = {
    50: '#EEF7F4',
    100: '#DDEEE9',
    200: '#BBDDD3',
    300: '#9ACDBD',
    400: '#78BCA7',
    main: '#56AB91',
    600: '#458974',
    700: '#346757',
    800: '#22443A',
    900: '#11221D',
    contrastText: '#458974'
};

// TODO: Rewrite alert colors
export const INFO = {
    lighter: '#161616',
    light: '#161616',
    main: '#161616',
    dark: '#161616',
    darker: '#161616',
    contrastText: '#161616'
};

export const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: PRIMARY[800]
};

export const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: PRIMARY[800]
};

export const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff'
};
