export const moveCursorToNextBlock = (blocks, currentBlockId) => {
    const nextBlockPosition = blocks.map((b) => b.id).indexOf(currentBlockId) + 1 + 1;
    const nextBlock = document.querySelector(`[data-position="${nextBlockPosition}"]`);
    if (nextBlock) {
        setCaretToStart(nextBlock);
    }
};

export const moveCursorToLastBlock = (blocks, currentBlockId) => {
    const lastBlockPosition = blocks.map((b) => b.id).indexOf(currentBlockId);
    const lastBlock = document.querySelector(`[data-position="${lastBlockPosition}"]`);
    if (lastBlock) {
        setCaretToEnd(lastBlock);
    }
};

export const setCaretToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
};

export const setCaretToStart = (element) => {
    element.focus();
};

export const getCaretIndex = (element) => {
    let position = 0;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
        const selection = window.getSelection();
        if (selection.rangeCount !== 0) {
            const range = window.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            position = preCaretRange.toString().length;
        }
    }
    return position;
};

export const isCaretAtEndOrStart = (element) => {
    const position = getCaretIndex(element);
    let start = false;
    let end = false;
    if (element.innerText.length === 0) {
        // empty block, start = end
        start = true;
        end = true;
    } else if (position < 1) {
        start = true;
    } else if (position >= element.innerText.length) {
        end = true;
    }

    return { start, end };
};

export const jumpOnArrowKeyDown = (element, key) => {
    const position = getCaretIndex(element);
    let jump = false;

    switch (key) {
        case 'ArrowDown':
            if (position >= element.innerText.length || element.innerText.length === 0) {
                jump = true;
            }
            break;
        case 'ArrowRight':
            if (position >= element.innerText.length) {
                jump = true;
            }
            break;
        case 'ArrowUp':
            if (position <= 9) {
                jump = true;
            }
            break;
        case 'ArrowLeft':
            if (position === 0) {
                jump = true;
            }
            break;
        default:
            break;
    }
    return jump;
};

export const createList = (htmlContent) => {
    return `<li>${htmlContent.substring(1)}</li>`;
};

export const createNewListItem = (htmlContent) => {
    // ! Why content before?
    return `${htmlContent}<li></li>`;
};

export const getRemainingContent = ({ element, htmlTag, htmlContent }) => {
    let filteredContent = htmlContent;
    if (htmlTag === 'ul') {
        // filtering the content
        filteredContent = filteredContent.replace('<li>', '').replace('</li>', '');
        const position = getCaretIndex(element);
        return filteredContent.slice(position);
    }

    const position = getCaretIndex(element);

    // Remove all HTML tags from the content
    const plainTextContent = htmlContent.replace(/<\/?[^>]+(>|$)/g, '');

    return plainTextContent.slice(position);
};
