import React, { useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import Avatar from '../../avatars/Avatar';
import UserMenu from './userMenu/UserMenu';

export default function AvatarProfile() {
    const givenName = useSelector((state) => state.user.userGivenName);
    const avatarUrl = useSelector((state) => state.user.userAvatarUrl);

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [hover, setHover] = useState(false);

    const handleOpenUserMenu = () => {
        setAnchorElUser('avatar');
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Grid container>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pl: 2
                    }}
                    onMouseLeave={() => setHover(false)}
                >
                    {hover ? (
                        <Grid item>
                            <Tooltip title='Menú de usuario' placement='bottom-start'>
                                <IconButton
                                    id='clickUserMenu'
                                    sx={{
                                        borderRadius: '4px',
                                        border: '0px solid',
                                        m: 0.1
                                    }}
                                    onClick={handleOpenUserMenu}
                                >
                                    <ExpandMoreOutlinedIcon id='clickUserMenu' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <Grid item onMouseEnter={() => setHover(true)}>
                            <Avatar
                                size='medium'
                                avatarName={givenName}
                                id='avatar'
                                avatarImage={avatarUrl}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Menu
                sx={{ mt: '64px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <UserMenu />
            </Menu>
        </Box>
    );
}
