import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Stack, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import { deleteOneFromArrayState } from '@common/utils/useState';
import TagsRow from '../tagsComponents/TagsRow';

MultipleEmailsField.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.string),
    setEmails: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    required: PropTypes.bool,
    alertMessage: PropTypes.string,
    variant: PropTypes.string
};

export default function MultipleEmailsField({
    emails,
    setEmails,
    autoFocus,
    label,
    required,
    alertMessage,
    variant
}) {
    const [emailInput, setEmailInput] = useState('');
    const [currentAlertMessage, setCurrentAlertMessage] = useState(
        alertMessage ? alertMessage : ''
    );

    const handleIntroduceNewEmail = (event) => {
        let validInput = true;
        event.preventDefault();

        // Separate emails, remove empty spaces and delete duplicates inputs
        // TODO: Check repeated
        const emailsInput = [
            ...new Set(
                emailInput
                    .split(',')
                    .map((correo) => correo.trim())
                    .filter((email) => email !== '')
            )
        ];

        emailsInput.forEach((email) => {
            if (!isEmail(email)) {
                setCurrentAlertMessage('Introduce un correo válido');
                validInput = false;
            }
        });

        if (validInput) {
            setEmails([...emails, ...emailsInput]);
            setEmailInput('');
            if (alertMessage) setCurrentAlertMessage('');
        }
    };

    const handleChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleDelete = (currentEmail) => {
        deleteOneFromArrayState({
            setState: setEmails,
            currentState: emails,
            elementToBeDeleted: currentEmail
        });
    };

    useEffect(() => {
        setCurrentAlertMessage(alertMessage);
    }, [alertMessage]);

    return (
        <>
            <Stack
                direction='row'
                spacing={1}
                alignItems={currentAlertMessage ? 'center' : 'flex-end'}
            >
                <TextField
                    error={currentAlertMessage ? true : false}
                    helperText={currentAlertMessage}
                    margin='normal'
                    size='small'
                    required={required ? required : false}
                    fullWidth
                    id='email'
                    label={label ? label : 'Introduce correos uno por uno'}
                    name='email'
                    autoComplete='email'
                    autoFocus={autoFocus}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                        if (event.keyCode == 13) {
                            handleIntroduceNewEmail(event);
                        }
                    }}
                    value={emailInput}
                    variant={variant ? variant : 'standard'}
                />
                <Button endIcon={<AddRoundedIcon />} onClick={handleIntroduceNewEmail}>
                    Añadir
                </Button>
            </Stack>
            <TagsRow tags={emails} handleDelete={handleDelete} />
        </>
    );
}
