import { PropTypes } from 'prop-types';
import { compareWithoutAccentsAndCase } from '@common/utils/strings';
import { turns } from './helpers';

export const handleCreateSpeaker = ({
    name,
    turn,
    focus,
    speakers,
    setAlertSpeaker,
    setAlertMessage,
    setSpeakers,
    setName,
    setTurn,
    alertSpeaker,
    roleLabel
}) => {
    // TODO: Refactor this funciton based on the new way of creating speakers
    const newSpeaker = {
        name,
        turn,
        focus,
        roleLabel
    };
    let success = true;
    if (
        setTurn &&
        turn !== -1 &&
        speakers.find((s) => s.turn === newSpeaker.turn && newSpeaker.turn !== null)
    ) {
        // We just allow repeating the turn 'Desconocido' === -1
        setAlertSpeaker(true);
        setAlertMessage('Este turno ya ha sido establecido');
        success = false;
    } else if (speakers.find((s) => compareWithoutAccentsAndCase(s.name, name))) {
        setAlertSpeaker(true);
        setAlertMessage('Participante repetido');
        success = false;
    } else {
        setSpeakers([newSpeaker, ...(speakers || [])]);
        setName('');
        if (setTurn) {
            setTurn('');
        }
        if (alertSpeaker) {
            setAlertSpeaker(false);
            setAlertMessage('');
        }
    }
    return success;
};

export const getSpeakerTurnByName = (speakersWithTurn, name) => {
    const foundSpeaker = speakersWithTurn.find((s) => s.name === name);
    return foundSpeaker ? foundSpeaker.turn : -1;
};

export const getSpeakerRoleLabelByName = (speakers, name) => {
    const foundSpeaker = speakers.find((s) => s.name === name);
    if (foundSpeaker && foundSpeaker.focus !== undefined) {
        return foundSpeaker.roleLabel;
    } else {
        return '';
    }
};

export const getSelectorTurns = (limit) => {
    if (limit) {
        return turns.slice(0, limit + 1);
    } else {
        return turns;
    }
};

handleCreateSpeaker.propTypes = {
    speaker: PropTypes.string.isRequired,
    turn: PropTypes.number.isRequired,
    speakers: PropTypes.array.isRequired,
    setAlertSpeaker: PropTypes.func.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
    setSpeakers: PropTypes.func.isRequired,
    setSpeaker: PropTypes.func.isRequired,
    setTurn: PropTypes.func,
    alertSpeaker: PropTypes.bool.isRequired,
    focus: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
};
