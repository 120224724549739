import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { Button, Stack, TextField, Typography } from '@mui/material';
import DialogButton from '@common/components/dialogs/DialogButton';
import Loader from '@common/components/loaders/Loader';
import { urls } from '@common/helpers/urls';
import useWorkspace from '@common/hooks/useWorkspace';
import palette from '@common/theme/palette/palette';
import WorkspaceMemberList from './WorkspaceMemberList';

export default function WorkspaceTab() {
    const {
        workspace,
        setWorkspace,
        workspaceMembers,
        handleGetWorkspaceMembers,
        setNewWorkspaceName,
        handleWorkspaceCreation,
        isWorkspaceAdmin,
        isLoading
    } = useWorkspace();
    const workspaceExists = Object.keys(workspace).length > 0;

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && (
                <>
                    {!workspaceExists && (
                        <>
                            <Stack>
                                <Typography component='span' variant='subtitle1'>
                                    Equipo
                                </Typography>
                                <Typography
                                    component={'span'}
                                    variant='body2'
                                    color={palette.primary[300]}
                                    sx={{ mb: 2 }}
                                >
                                    Encuentra a todos tus compañeros que utilizan la aplicación y
                                    configura opciones avanzadas de colaboración.
                                </Typography>
                            </Stack>
                            <DialogButton
                                dialogTitle='Nombre de tu workspace'
                                dialogText='Este nombre lo verán el resto de compañeros del workspace'
                                withButton={true}
                                buttonText='Crear workspace'
                                successButtonLoadingText='Guardando'
                                successButtonText='Guardar'
                                onSuccess={async () => await handleWorkspaceCreation()}
                                buttonSize='small'
                            >
                                <TextField
                                    onInput={(e) => {
                                        setNewWorkspaceName(e.target.value);
                                    }}
                                    style={{ marginTop: '15px' }}
                                    fullWidth
                                    placeholder='Nombre del workspace'
                                    variant='standard'
                                />
                            </DialogButton>
                        </>
                    )}
                    {workspaceExists && (
                        <WorkspaceMemberList
                            onMemberChange={async () => await handleGetWorkspaceMembers()}
                            onWorkspaceNameChange={(newWorkspaceName) => {
                                setWorkspace({
                                    ...workspace,
                                    name: newWorkspaceName
                                });
                            }}
                            workspaceName={workspace.name}
                            members={workspaceMembers}
                            isWorkspaceAdmin={isWorkspaceAdmin}
                        />
                    )}
                    <Button
                        variant='text'
                        startIcon={<CampaignOutlinedIcon fontSize='small' />}
                        onClick={() => window.open(urls.forms.workspace)}
                        sx={{ mt: 5 }}
                    >
                        ¿Cómo te gustaría utilizar esta funcionalidad?
                    </Button>
                </>
            )}
        </>
    );
}
