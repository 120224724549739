import palette from '@common/theme/palette/palette';

export const getSpeakerColor = (turn) => {
    let speakerColor = '';
    switch (turn) {
        case 0:
            speakerColor = palette.ocean[100];
            break;
        case 1:
            speakerColor = palette.olive[300];
            break;
        case 2:
            speakerColor = palette.blue[100];
            break;
        case 3:
            speakerColor = palette.purple[100];
            break;
        case 4:
            speakerColor = palette.green[100];
            break;

        default:
            speakerColor = palette.grey[100];
            break;
    }
    return speakerColor;
};

export const getSpeakerTurnbyName = (speakersWithTurn, speaker) => {
    const foundSpeaker = speakersWithTurn.find((s) => s.name === speaker);
    return foundSpeaker ? foundSpeaker.turn : -1;
};

export const initializeSpeakers = (speakers) => {
    return speakers.map((speaker) => {
        return { name: speaker, turn: -1 };
    });
};

/**
 * Returns the participants in order with their current turn
 */
export const setSpeakers = ({ meetingParticipants, transcriptionSpeakers }) => {
    return meetingParticipants.map((mp) => {
        const index = transcriptionSpeakers.findIndex((ts) => ts.name === mp);
        if (index !== -1) {
            return { name: mp, turn: transcriptionSpeakers[index].turn };
        }
        return { name: mp, turn: -1 };
    });
};

export const meetingTabs = {
    summary: 'summary',
    transcription: 'transcription',
    chat: 'chat'
};
