import { useState } from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import { Grid, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import AudioPlayer from '@common/components/audioPlayer/AudioPlayer';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import DialogButton from '@common/components/dialogs/DialogButton';
import ToggleButton from '@common/components/dialogs/plansDialog/ToggleButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import ShareMeetingDialog from '@common/components/dialogs/ShareMeetingDialog';
import IconPhrase from '@common/components/icons/IconPhrase';
import SpeakerForm from '@common/components/speakers/SpeakerForm';
import TagEditable from '@common/components/tagsComponents/TagEditable';
import { navigation } from '@common/helpers/navigation';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { downloadAudio } from '@common/utils/audio';
import { fromArrayTimeToString, fromSecondsToArrayTime } from '@common/utils/date';
import { meetingTabs } from '@pages/meeting/utils';
import { deleteMeetingById } from '@setup/api/meeting/meeting';
import DonwloadReportButton from '../Report/DownloadReportButton';
import DownloadTranscriptionButton from '../Transcription/DownloadTranscriptionButton';
import SpeakersInfo from './SpeakersInfo';

MeetingSideMenuView.propTypes = {
    id: PropTypes.number.isRequired,
    speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    handleDeleteSpeaker: PropTypes.func,
    isShared: PropTypes.bool,
    meetingId: PropTypes.number,
    handleUpdateSpeakerByTurn: PropTypes.func,
    handleChangeTab: PropTypes.func,
    currentTab: PropTypes.string,
    speakersInterventionPercentages: PropTypes.arrayOf(PropTypes.string),
    handleCreateAsyncSpeaker: PropTypes.func,
    setMeetingSpeakers: PropTypes.func,
    handleCopyReport: PropTypes.func,
    handleCopyTranscription: PropTypes.func,
    filename: PropTypes.string.isRequired,
    platform: PropTypes.string,
    audioUrl: PropTypes.string,
    availableTurns: PropTypes.number,
    setCurrentAudioTime: PropTypes.func.isRequired,
    currentAudioTime: PropTypes.number.isRequired,
    setSlideChange: PropTypes.func.isRequired
};

export default function MeetingSideMenuView({
    speakers,
    title,
    tag,
    date,
    time,
    handleDeleteSpeaker,
    isShared,
    meetingId,
    handleUpdateSpeakerByTurn,
    handleChangeTab,
    currentTab,
    speakersInterventionPercentages,
    handleCreateAsyncSpeaker,
    setMeetingSpeakers,
    handleCopyReport,
    filename,
    platform,
    audioUrl,
    duration,
    availableTurns,
    handleCopyTranscription,
    setCurrentAudioTime,
    currentAudioTime,
    setSlideChange
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const { getPlan } = useUser();

    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const toggleOptions = [
        { key: meetingTabs.summary, label: 'Resumen' },
        { key: meetingTabs.chat, label: 'Chat', new: true },
        { key: meetingTabs.transcription, label: 'Transcripción' }
    ];

    const arrTime = fromSecondsToArrayTime(duration);
    const durationString = fromArrayTimeToString(arrTime);

    const handleDownloadAudio = async () => {
        if (getPlan().name === subscriptionPlans.free.name) {
            setOpenPremiumDialog(true);
        } else {
            await downloadAudio({
                meetingTitle: title,
                audioFilename: filename
            });
        }
    };

    const deleteMeetingHandler = async () => {
        try {
            await deleteMeetingById({ meetingId });
            navigate(`${navigation.app.routes.repository}`);
            notification('deletion-success');
        } catch (err) {
            notification('deletion-failure');
        }
        setOpenDeleteDialog(false);
    };

    return (
        <Grid item sx={{ ml: 2 }}>
            {isShared ? null : (
                <Grid item sx={{ mb: 2 }}>
                    <ToggleButton
                        options={toggleOptions}
                        defaultOptionId={
                            currentTab
                                ? toggleOptions.findIndex((option) => option.key === currentTab)
                                : 0
                        }
                        fullWidth={true}
                        handleChange={(tabValue) => {
                            handleChangeTab(toggleOptions[tabValue].key);
                        }}
                    />
                    <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
                        {currentTab === toggleOptions[0].key ? (
                            <ShareMeetingDialog
                                withButton={true}
                                meetingId={meetingId}
                                meetingTitle={title}
                                buttonVariant={'contained'}
                            />
                        ) : null}
                        {currentTab === toggleOptions[1].key ? null : (
                            <Grid item>
                                <CopyIconButton
                                    id='clickCopyReportContent'
                                    size='small'
                                    onClick={
                                        getPlan().name !== subscriptionPlans.free.name
                                            ? currentTab === toggleOptions[0].key
                                                ? handleCopyReport
                                                : handleCopyTranscription
                                            : () => setOpenPremiumDialog(true)
                                    }
                                    tooltipTitle={'Copiar'}
                                />
                            </Grid>
                        )}

                        {currentTab === toggleOptions[2].key ? (
                            <Grid item>
                                <DownloadTranscriptionButton
                                    meetingId={meetingId}
                                    meetingTitle={title}
                                    creationDate={`${date} a las ${time}`}
                                    participants={speakers.map((speaker) => speaker.name)}
                                    duration={duration}
                                    size='small'
                                />
                            </Grid>
                        ) : null}
                        {currentTab === toggleOptions[0].key ? (
                            <Grid item>
                                <DonwloadReportButton
                                    meetingId={meetingId}
                                    meetingTitle={title}
                                    creationDate={`${date} a las ${time}`}
                                    participants={speakers.map((speaker) => speaker.name)}
                                    duration={duration}
                                    size='small'
                                />
                            </Grid>
                        ) : null}
                        <Grid item>
                            <IconButtonWithTooltip
                                tooltipText='Integraciones'
                                icon={<ExtensionOutlinedIcon fontSize='small' />}
                                onClick={() =>
                                    navigate(`${navigation.app.routes.settings}/integrations`)
                                }
                            />
                        </Grid>
                        <DialogButton
                            id='clickEditParticipants'
                            withButton={true}
                            iconButton={true}
                            iconButtonTooltip={'Editar participantes'}
                            buttonIcon={
                                <ManageAccountsOutlinedIcon
                                    id='clickEditParticipants'
                                    fontSize='small'
                                />
                            }
                            dialogTitle={'Participantes'}
                            dialogText={`Se han detectado ${availableTurns} personas hablando durante la conversación.`}
                            disableActions={true}
                            isPremiumFeat={
                                getPlan().name !== subscriptionPlans.free.name ? false : true
                            }
                            maxWidth={'sm'}
                        >
                            <Grid container direction='column'>
                                <Grid item>
                                    <AudioPlayer
                                        sx={{ mt: 2, mb: -1 }}
                                        filename={title}
                                        subtitle={
                                            platform
                                                ? platform === 'directUpload'
                                                    ? 'Subida directa'
                                                    : platform
                                                : null
                                        }
                                        audioUrl={audioUrl}
                                        audioDuration={duration}
                                        enablePlaybackRate={true}
                                        size='small'
                                    />
                                </Grid>
                                <Grid item sx={{ mb: -1, mt: 2 }}>
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color={palette.primary.main}
                                    >
                                        Escucha el audio para asignar el turno de intervención de
                                        cada participante. Sólo {availableTurns} personas han
                                        hablado durante la conversación.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <SpeakerForm
                                        speakers={speakers}
                                        setSpeakers={setMeetingSpeakers}
                                        asyncSpeakerCreation={handleCreateAsyncSpeaker}
                                        asyncSpeakerDeletion={handleDeleteSpeaker}
                                        asyncSpeakerTurnChange={handleUpdateSpeakerByTurn}
                                        availableTurns={availableTurns}
                                    />
                                </Grid>
                            </Grid>
                        </DialogButton>
                        <Grid item>
                            <IconButtonWithTooltip
                                tooltipText='Eliminar reunión'
                                icon={<DeleteOutlineOutlinedIcon fontSize='small' />}
                                onClick={() => setOpenDeleteDialog(true)}
                            />
                        </Grid>
                    </Stack>
                </Grid>
            )}
            <Grid item>
                <TagEditable isShared={isShared} tag={tag} meetingId={meetingId} />
            </Grid>
            <Grid
                item
                sx={{
                    borderRadius: '0.5rem 0.5rem 0rem 0rem'
                }}
            >
                <IconPhrase
                    text={`${date} a las ${time}`}
                    icon={
                        <CalendarTodayOutlinedIcon
                            fontSize='small'
                            sx={{ color: palette.primary[300] }}
                        />
                    }
                />
            </Grid>
            {isShared ? (
                <Grid
                    item
                    sx={{
                        borderRadius: '0rem 0rem 0.5rem 0.5rem',
                        pb: 1
                    }}
                >
                    <IconPhrase
                        text={durationString}
                        icon={
                            <AccessTimeOutlinedIcon
                                fontSize='small'
                                sx={{ color: palette.primary[300] }}
                            />
                        }
                    />
                </Grid>
            ) : null}
            <Grid
                item
                sx={{
                    mt: 2,
                    mb: 2
                }}
            >
                {!isShared ? (
                    <AudioPlayer
                        filename={title}
                        subtitle={
                            platform
                                ? platform === 'directUpload'
                                    ? 'Subida directa'
                                    : platform
                                : null
                        }
                        audioUrl={audioUrl}
                        audioDuration={duration}
                        handleDownload={handleDownloadAudio}
                        enablePlaybackRate={true}
                        setCurrentAudioTime={setCurrentAudioTime}
                        currentAudioTime={currentAudioTime}
                        setSlideChange={setSlideChange}
                    />
                ) : null}
            </Grid>
            <Grid item>
                <SpeakersInfo
                    speakers={speakers}
                    interventionPercentages={speakersInterventionPercentages}
                />
            </Grid>
            {isShared ? null : (
                <Grid container direction='row'>
                    <Grid item>
                        <IconButtonWithTooltip
                            tooltipText='Buen resumen'
                            icon={<ThumbUpAltOutlinedIcon fontSize='small' />}
                            onClick={() => window.open(urls.forms.summary)}
                        />
                    </Grid>
                    <Grid item>
                        <IconButtonWithTooltip
                            tooltipText='Mal resumen'
                            icon={<ThumbDownAltOutlinedIcon fontSize='small' />}
                            onClick={() => window.open(urls.forms.summary)}
                        />
                    </Grid>
                </Grid>
            )}

            <ConfirmationDialog
                open={openDeleteDialog}
                title='Eliminar reunión'
                message='Estas a punto de eliminar esta reunión, si la eliminas perderás toda su información.'
                onConfirm={deleteMeetingHandler}
                onClose={() => setOpenDeleteDialog(false)}
            />
            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </Grid>
    );
}
