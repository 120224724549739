import { useState } from 'react';
import { ClickAwayListener, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

TypographyEditable.propTypes = {
    handleChangeText: PropTypes.func,
    defaultText: PropTypes.string,
    fontSize: PropTypes.string,
    tooltip: PropTypes.string
};

export default function TypographyEditable({ handleChangeText, defaultText, fontSize, tooltip }) {
    const [titleClick, setTitleClick] = useState(false);
    const [editableTextContent, setEditableTextContent] = useState(defaultText);
    const [textChanged, setTextChanged] = useState(false);

    const handleSaveNewTitle = async () => {
        await handleChangeText(editableTextContent);
        setTitleClick(false);
        setTextChanged(false);
    };

    const handleSaveTitleWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSaveNewTitle();
            event.target.blur();
        }
    };

    const handleNameClickAway = () => {
        if (titleClick && textChanged) {
            handleSaveNewTitle();
        }
    };

    const handleTitleChange = (e) => {
        if (!textChanged) {
            setTextChanged(true);
        }
        setEditableTextContent(e.target.innerText);
    };

    return (
        <ClickAwayListener onClickAway={handleNameClickAway}>
            <Tooltip title={tooltip ? tooltip : 'Doble clic para editar'} followCursor={true}>
                <Typography
                    component='span'
                    variant={fontSize ? fontSize : 'body1'}
                    contentEditable={titleClick}
                    onClick={() => setTitleClick(true)}
                    onInput={handleTitleChange}
                    onKeyDown={handleSaveTitleWithEnter}
                    sx={{
                        ':hover': {
                            cursor: 'pointer',
                            color: palette.primary[300]
                        },
                        ':focus': {
                            outline: 'none',
                            color: palette.primary[500],
                            pl: 1
                        }
                    }}
                >
                    {defaultText}
                </Typography>
            </Tooltip>
        </ClickAwayListener>
    );
}
