import { authApiCall, endpoints } from '..';

export async function getSharedReportBlocks({ meetingId, authToken }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedReportBlocks,
        path: `/${meetingId}`,
        authToken
    });

    return {
        reportBlocks: res.data,
        getReportBlocksStatus: res.status
    };
}
