import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Chip, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Badge from '@common/components/Badge';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import DialogButton from '@common/components/dialogs/DialogButton';
import TypographyEditable from '@common/components/TypographyEditable';
import { DEFAULT_TEMPLATE_SECTIONS } from '@common/helpers/templates/defaultTemplateSections';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/palette/shadows';
import { updateSectionSubject, updateSectionTitle } from '@setup/api/templateSections';

TemplateSection.propTypes = {
    section: PropTypes.object,
    sectionProps: PropTypes.object,
    description: PropTypes.string,
    onDelete: PropTypes.func,
    hoverEnabled: PropTypes.bool,
    templateId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    handleEditSection: PropTypes.func,
    setSectionIdFromTemplate: PropTypes.func
};

export default function TemplateSection({
    section,
    description,
    onDelete,
    hoverEnabled,
    templateId,
    onChange,
    handleEditSection,
    setSectionIdFromTemplate
}) {
    const notification = useNotification();
    const [subject, setSubject] = useState(section.subject ? section.subject : '');
    const [openCompetencesDialog, setOpenCompetencesDialog] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleUpdateSectionTitle = async (newTitle) => {
        try {
            await updateSectionTitle({
                newTitle,
                sectionId: section.id,
                reportTemplateId: templateId
            });
            onChange();
        } catch (error) {
            notification('server-error');
        }
    };

    const handleUpdateSectionSubject = async () => {
        try {
            await updateSectionSubject({
                subject,
                sectionId: section.id,
                reportTemplateId: templateId
            });
            await handleUpdateSectionTitle(`Competencia: ${subject}`);
            setOpenCompetencesDialog(false);
            onChange();
        } catch (error) {
            notification('server-error');
        }
    };

    const handleOnClose = () => {
        setOpenCompetencesDialog(false);
    };

    return (
        <>
            {section && (
                <Grid
                    sx={{
                        border: '1px solid black',
                        borderColor: palette.primary[100],
                        borderRadius: '4px',
                        mb: 2,
                        boxShadow: !hoverEnabled ? shadows.elevated : shadows.none,
                        ':hover': {
                            boxShadow: hoverEnabled ? shadows.draggable : 'default'
                        },
                        cursor: hoverEnabled ? 'grab' : 'auto',
                        backgroundColor: 'white'
                    }}
                    container
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                    pt={description ? 0 : 1}
                    pb={description ? 0 : 1}
                    pl={description ? 1 : 2}
                    pr={description ? 0 : 1}
                    onMouseLeave={() => setHovered(false)}
                    onMouseEnter={() => setHovered(true)}
                >
                    <Grid
                        pt={1}
                        pb={1}
                        item
                        xs={2}
                        sx={{
                            display: { xs: 'flex' },
                            justifyContent: { xs: 'center', md: 'start' }
                        }}
                        md={description ? 2.5 : 1}
                    >
                        <Badge
                            invisible={section.new ? false : true}
                            color={palette.secondary.main}
                            borderColor={palette.common.white}
                            tooltipTitle='Nuevo'
                        >
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                width={description ? '60px' : '30px'}
                                height={description ? '60px' : '30px'}
                                borderRadius={1}
                                sx={{
                                    backgroundColor:
                                        section.type === 'personalized'
                                            ? palette.primary[700]
                                            : palette.primary[50]
                                }}
                            >
                                {section.icon}
                            </Box>
                        </Badge>
                    </Grid>
                    <Grid
                        display='flex'
                        flexGrow={1}
                        flexDirection={onDelete ? 'row' : 'column'}
                        justifyContent='start'
                        item
                        pt={1}
                        pb={1}
                        xs={section.id && !handleEditSection ? null : 8}
                        md={section.id && !handleEditSection ? null : 9}
                    >
                        {onDelete ? (
                            <Stack direction='column' spacing={0}>
                                <TypographyEditable
                                    defaultText={
                                        section.sectionTitle ? section.sectionTitle : section.title
                                    }
                                    handleChangeText={handleUpdateSectionTitle}
                                    tooltip={'Doble clic para editar título de sección'}
                                />
                                {section.sectionTitle && (
                                    <Typography color={palette.primary[300]} variant='caption'>
                                        ({section.title})
                                    </Typography>
                                )}
                            </Stack>
                        ) : (
                            <Typography variant='body1'>{section.title}</Typography>
                        )}
                        {description && (
                            <Typography variant='body2'>
                                <Box color={palette.primary[400]}>{description}</Box>
                            </Typography>
                        )}
                    </Grid>
                    {section.type === DEFAULT_TEMPLATE_SECTIONS.specific_competence.type &&
                        onDelete && (
                            <Grid
                                item
                                sx={{
                                    mr: 1
                                }}
                                onClick={() => {
                                    setSubject(section.subject);
                                    setOpenCompetencesDialog(true);
                                }}
                            >
                                <Tooltip title='Clic para editar competencia'>
                                    <Chip
                                        sx={{
                                            flexGrow: 1,
                                            borderRadius: 2,
                                            justifyContent: 'left',
                                            ':hover': {
                                                cursor: 'pointer'
                                            }
                                        }}
                                        label={section.subject ? section.subject : 'Sin definir'}
                                        color={
                                            section.subject
                                                ? 'chip_primary_soft'
                                                : 'chip_primary_dark'
                                        }
                                        size='small'
                                    />
                                </Tooltip>
                            </Grid>
                        )}
                    {section.deprecated && (
                        <Grid item sx={{ mr: 1 }}>
                            <Tooltip title='Por favor, quita esta sección, la hemos retirado.'>
                                <Chip
                                    sx={{ flexGrow: 1, borderRadius: 2, justifyContent: 'left' }}
                                    label='Sección retirada'
                                    color={'chip_grey'}
                                    size='small'
                                />
                            </Tooltip>
                        </Grid>
                    )}
                    {section.type === 'personalized' && section.id && hovered && (
                        <Grid
                            item
                            xs={onDelete ? 1 : 12}
                            sx={{
                                position: 'relative',
                                backgroundColor: 'white',
                                mr: -1
                            }}
                        >
                            <IconButtonWithTooltip
                                icon={<EditRoundedIcon fontSize='small' />}
                                tooltipText='Editar sección'
                                onClick={
                                    onDelete
                                        ? () => setSectionIdFromTemplate(section.sectionId)
                                        : () => handleEditSection(section.id)
                                }
                                size='small'
                                sx={{
                                    position: onDelete ? 'relative' : 'absolute',
                                    bottom: '100%',
                                    right: 0,
                                    zIndex: 0,
                                    mr: onDelete ? 0 : 1,
                                    mb: onDelete ? 0 : 1
                                }}
                            />
                        </Grid>
                    )}
                    {onDelete && hovered && (
                        <Grid xs={1} item>
                            <IconButton
                                onClick={onDelete}
                                sx={{ borderRadius: '4px', border: '0px solid' }}
                            >
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            )}
            <DialogButton
                id='clickEditName'
                iconButton={true}
                iconButtonTooltip={'Editar nombre'}
                dialogTitle={'¿Qué competencia quieres evaluar?'}
                dialogText={
                    'Introduce la competencia que quieres que sea evaluada en la entrevista.'
                }
                withButton={false}
                successButtonText='Guardar'
                onSuccess={handleUpdateSectionSubject}
                openDialog={openCompetencesDialog}
                onClose={handleOnClose}
            >
                <TextField
                    onInput={(e) => {
                        setSubject(e.target.value);
                    }}
                    label='Competencia a evaluar'
                    value={subject}
                    style={{ marginTop: '15px' }}
                    variant='standard'
                    fullWidth
                />
            </DialogButton>
        </>
    );
}
