import { Grid, Typography } from '@mui/material';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import palette from '@common/theme/palette/palette';

export default function UsageWarning() {
    return (
        <Grid
            container
            direction='column'
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Grid item>
                <Grid
                    container
                    direction='column'
                    spacing={1}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Grid item>
                        <Typography
                            component='span'
                            variant='h5'
                            color={palette.primary[800]}
                            textAlign='center'
                        >
                            Has agotado tus minutos de uso
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            component='span'
                            variant='body2'
                            color={palette.primary.main}
                            textAlign='center'
                        >
                            Hemos guardado tu conversación en Voicit.
                            <br />
                            Actualiza tu plan para poder generar resúmenes.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <PricingDialog button={true} />
            </Grid>
        </Grid>
    );
}
