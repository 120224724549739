import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { navigation } from '@common/helpers/navigation';
import palette from '@common/theme/palette/palette';

export default function Breadcrumb({ currentPageTitle, previousPage }) {
    const navigate = useNavigate();
    const handleNavigateRepository = () => {
        navigate(navigation.app.routes.repository);
    };

    return (
        <Breadcrumbs aria-label='breadcrumb'>
            <Link
                underline='hover'
                sx={{ display: 'flex', alignItems: 'center' }}
                component='button'
                color={palette.primary[300]}
                onClick={handleNavigateRepository}
            >
                <ArrowBackIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                Repositorio
            </Link>
            {previousPage ? (
                <Link
                    underline='hover'
                    sx={{ display: 'flex', alignItems: 'center' }}
                    component='button'
                    color={palette.primary[300]}
                    onClick={previousPage.handleNavigation}
                >
                    {previousPage.title}
                </Link>
            ) : null}
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color='text.primary'>
                {currentPageTitle}
            </Typography>
        </Breadcrumbs>
    );
}

Breadcrumb.propTypes = {
    currentPageTitle: PropTypes.string.isRequired,
    previousPage: PropTypes.object
};
