import { meetingStatus } from '@common/helpers/meetingStatus';

/**
 * Set to visual error state meetings on error state or those which has been more than one day in a not succeed state
 *
 * @param {date} createdAt creation date @param {number} createdAt
 * @param {date} updatedAt updated date @param {number} updatedAt
 * @returns {boolean} should or not should be on error state
 */
export const shouldBeOnErrorState = ({ status, createdAt, updatedAt }) => {
    let shouldBe = false;
    const currentDate = new Date();
    const creationDate = new Date(createdAt);
    const updateDate = new Date(updatedAt);
    const delayInMinutes = 11; // Backend will automatically start the meeting process in 10 minutes
    const timeFromCreationInMilliseconds = currentDate - creationDate;
    const timeFromCreationInHours = timeFromCreationInMilliseconds / (1000 * 60 * 60);
    const timeFromUpdateInMilliseconds = currentDate - updateDate;
    const timeFromUpdateInHours = timeFromUpdateInMilliseconds / (1000 * 60 * 60);

    if (
        status === meetingStatus.TRANSCRIPTION_ERROR ||
        status === meetingStatus.SUMMARY_ERROR ||
        status === meetingStatus.AUDIO_ERROR ||
        status === meetingStatus.SERVER_ERROR
    ) {
        shouldBe = true;
    } else if (
        status === meetingStatus.TRANSCRIPTION_STARTED ||
        status === meetingStatus.AUDIO_STARTED
    ) {
        if (
            timeFromCreationInHours >= delayInMinutes / 60 &&
            timeFromUpdateInHours >= delayInMinutes / 60
        ) {
            // More than delayInMinutes from creation or udpate in started status
            shouldBe = true;
        }
    }
    return shouldBe;
};

/**
 * Get number of participants from speakers array
 *
 * @param {[name]} speakers creation date
 * @returns {number} number of participants
 */
export const getNumberOfParticipants = (speakers) => {
    let numberOfParticipants = 1; // 1 participant by default

    if (speakers && speakers !== null) {
        if (speakers.length && speakers.length > 0) {
            numberOfParticipants = speakers.length;
        }
    }

    return numberOfParticipants;
};
