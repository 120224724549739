import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

export default function LoaderTable() {
    return (
        <Box height='auto'>
            {/* Toolbar */}
            <Box height='auto' sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Skeleton animation='wave' width='15%' height={60} />
            </Box>
            {/* Row 1 */}
            <Grid container spacing={2} mb={2} mt={1}>
                <Grid item xs={6}>
                    <Skeleton animation='wave' width='90%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='70%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='90%' height={30} />
                </Grid>
            </Grid>
            {/* Row 2 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                    <Skeleton animation='wave' width='75%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='75%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='85%' height={30} />
                </Grid>
            </Grid>
            {/* Row 3 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                    <Skeleton animation='wave' width='80%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='65%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='70%' height={30} />
                </Grid>
            </Grid>
            {/* Row 4 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                    <Skeleton animation='wave' width='94%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='78%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='95%' height={30} />
                </Grid>
            </Grid>
            {/* Row 5 */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                    <Skeleton animation='wave' width='87%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='68%' height={30} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton animation='wave' width='78%' height={30} />
                </Grid>
            </Grid>
        </Box>
    );
}
