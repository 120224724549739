import { authApiCall, endpoints } from '..';

export async function getMicroSummaries({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.microSummaries,
        path: `/${meetingId}`
    });

    return {
        microSummaries: res.data,
        getMicroSummariesStatus: res.status
    };
}

export async function getMicroSummariesTopics({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.microSummaries,
        path: `/${meetingId}`
    });

    const topicSet = new Set(res.data.map((ms) => ms.topic));

    return {
        topics: Array.from(topicSet)
    };
}

export async function createMicroSummaryKeyWordsById({ id, keyWords, meetingId }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.microSummaries,
        path: '/keyWords/one',
        data: {
            id,
            keyWords,
            meetingId
        }
    });

    return res.status;
}

export async function updateMicroSummaryTitleById({ id, meetingId, newTitle }) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.microSummaries,
        path: '/title/one',
        data: {
            id,
            meetingId,
            newTitle
        }
    });
}

export async function updateMicroSummaryContentById({ id, meetingId, newContent }) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.microSummaries,
        path: '/content/one',
        data: {
            id,
            meetingId,
            newContent
        }
    });
}

export async function deleteMicroSummaryKeyWordById({ id, keyWord, meetingId }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.microSummaries,
        path: '/deleteKeyWord/one',
        data: {
            id,
            keyWord,
            meetingId
        }
    });

    return res.status;
}

export async function deleteMicroSummary({ meetingId, id }) {
    await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.microSummaries,
        path: '/one',
        data: {
            meetingId,
            id
        }
    });
}
