import React, { useState } from 'react';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { Grid, TableCell, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import DialogButton from '@common/components/dialogs/DialogButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import ShareMeetingDialog from '@common/components/dialogs/ShareMeetingDialog';
import MenuButton from '@common/components/MenuButton';
import TagMeetingProcessingStatus from '@common/components/tagsComponents/TagMeetingProcessingStatus';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { downloadAudio } from '@common/utils/audio';
import { updateMeetingTitle } from '@setup/api/meeting/meeting';

MenuCell.propTypes = {
    handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    meeting: PropTypes.object,
    errorState: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    findAndUpdateMeeting: PropTypes.func.isRequired,
    setCurrentMeeting: PropTypes.func.isRequired,
    isStandBy: PropTypes.bool.isRequired
};

export default function MenuCell({
    meeting,
    handleDeleteMeeting,
    errorState,
    handleRetryMeeting,
    isProcessing,
    findAndUpdateMeeting,
    setCurrentMeeting,
    isStandBy
}) {
    const navigate = useNavigate();
    const notification = useNotification();
    const { getPlan, userIsRunOutOfMinutes } = useUser();

    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [openDialog, setOpenEditTitleDialog] = useState(false);
    const [newMeetingName, setNewMeetingName] = useState(meeting.title);

    const handleOpenMeetingPage = () => {
        navigate(`/meeting/${meeting.id}`);
    };

    const handleOpenShareDialog = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    const handleDownloadAudio = async () => {
        if (getPlan().name === subscriptionPlans.free.name && !errorState) {
            setOpenPremiumDialog(true);
        } else {
            await downloadAudio({
                meetingTitle: meeting.title,
                audioFilename: meeting.filename,
                allowExportInformation: errorState
            });
        }
    };

    const deleteMeetingHandler = async () => {
        try {
            await handleDeleteMeeting(meeting.id);
            notification('deletion-success');
        } catch (err) {
            notification('deletion-failure');
        }
        setOpenDeleteDialog(false);
    };

    const handleUpdateTitle = async (title) => {
        try {
            // DB update
            await updateMeetingTitle({ meetingId: meeting.id, title });
            // Meeting update in our meeting list
            const updatedMeeting = await findAndUpdateMeeting({ title, id: meeting.id });
            // Current meeting update
            setCurrentMeeting(updatedMeeting);
            return true;
        } catch (error) {
            console.log(error);
            notification('title-error');
        }
    };

    return (
        <>
            <TableCell
                sx={{
                    borderColor: palette.primary[50]
                }}
                key={meeting.duration}
                style={{ width: 160 }}
                align='right'
            >
                {errorState || isStandBy ? (
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <Grid item xs={4}>
                            <IconButtonWithTooltip
                                icon={<DeleteOutlineOutlinedIcon />}
                                tooltipText='Eliminar reunión'
                                onClick={() => setOpenDeleteDialog(true)}
                            />
                        </Grid>
                        {meeting.filename && meeting.filename !== null ? (
                            <>
                                {meeting.transcriptionStatus ===
                                    meetingStatus.TRANSCRIPTION_STARTED ||
                                meeting.transcriptionStatus === meetingStatus.AUDIO_STARTED ||
                                meeting.transcriptionStatus === meetingStatus.AUDIO_IN_PROGESS ||
                                meeting.transcriptionStatus === meetingStatus.STAND_BY ? null : (
                                    <Grid item xs={4}>
                                        <IconButtonWithTooltip
                                            icon={<FileDownloadOutlinedIcon />}
                                            tooltipText='Descargar audio'
                                            onClick={handleDownloadAudio}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={4}>
                                    <IconButtonWithTooltip
                                        icon={<ReplayOutlinedIcon />}
                                        tooltipText={isStandBy ? 'Procesar' : 'Procesar de nuevo'}
                                        onClick={
                                            userIsRunOutOfMinutes
                                                ? () => setOpenPremiumDialog(true)
                                                : async () => await handleRetryMeeting({ meeting })
                                        }
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                ) : (
                    <>
                        {isProcessing ? (
                            <TagMeetingProcessingStatus status={meeting.transcriptionStatus} />
                        ) : (
                            <>
                                <MenuButton
                                    id='clickMeetingMenu'
                                    iconButton={
                                        <MoreVertIcon id='clickMeetingMenu' fontSize='small' />
                                    }
                                    tooltipText='Opciones'
                                    menuList={[
                                        {
                                            itemFunction: handleOpenMeetingPage,
                                            itemIcon: <CallMadeOutlinedIcon fontSize='small' />,
                                            itemText: 'Abrir reunión'
                                        },
                                        {
                                            itemFunction: handleOpenShareDialog,
                                            itemIcon: (
                                                <LinkOutlinedIcon
                                                    id='clickedShareButtonMenu'
                                                    fontSize='small'
                                                />
                                            ),
                                            itemText: 'Compartir',
                                            itemClickId: 'clickedShareButtonMenu'
                                        },
                                        {
                                            itemFunction: () => setOpenEditTitleDialog(true),
                                            itemIcon: <EditOutlinedIcon fontSize='small' />,
                                            itemText: 'Editar nombre'
                                        },
                                        {
                                            itemClickId: 'clickDownloadAudioMenu',
                                            itemFunction: handleDownloadAudio,
                                            itemIcon: (
                                                <GraphicEqOutlinedIcon
                                                    id='clickDownloadAudioMenu'
                                                    fontSize='small'
                                                />
                                            ),
                                            itemText: 'Descargar audio',
                                            disabled:
                                                meeting.filename && meeting.filename !== null
                                                    ? false
                                                    : true
                                        },
                                        {
                                            itemFunction: () => setOpenDeleteDialog(true),
                                            itemIcon: (
                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                            ),
                                            itemText: 'Eliminar'
                                        }
                                    ]}
                                />
                                {/* Below lines for conditional rendering */}
                                {openShareDialog ? (
                                    <ShareMeetingDialog
                                        withButton={false}
                                        meetingId={meeting.id}
                                        meetingTitle={meeting.title}
                                        openDialog={openShareDialog}
                                        closeDialog={handleCloseShareDialog}
                                    />
                                ) : null}
                            </>
                        )}
                    </>
                )}
                <ConfirmationDialog
                    open={openDeleteDialog}
                    title='Eliminar reunión'
                    message='Estas a punto de eliminar esta reunión, si la eliminas perderás toda su información.'
                    onConfirm={deleteMeetingHandler}
                    onClose={() => setOpenDeleteDialog(false)}
                />
                <PricingDialog
                    openDialog={openPremiumDialog}
                    setOpenDialog={setOpenPremiumDialog}
                />
                <DialogButton
                    dialogTitle='Título de la reunión'
                    dialogText='Edita el título de tu reunión para adaptarlo a tus necesidades'
                    withButton={false}
                    successButtonLoadingText='Actualizando...'
                    successButtonText='Actualizar'
                    onSuccess={() => handleUpdateTitle(newMeetingName)}
                    buttonSize='small'
                    fullWidthButton={true}
                    openDialog={openDialog}
                    onClose={() => setOpenEditTitleDialog(false)}
                >
                    <TextField
                        onInput={(e) => setNewMeetingName(e.target.value)}
                        value={newMeetingName}
                        style={{ marginTop: '15px' }}
                        fullWidth
                        placeholder='Nombre de tu reunión'
                        variant='standard'
                    />
                </DialogButton>
            </TableCell>
        </>
    );
}
