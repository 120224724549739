import { authApiCall, endpoints } from '..';

export async function getReportBlocks({ meetingId }) {
    const { data, status } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.reportBlocks,
        path: `/${meetingId}`
    });

    return {
        reportBlocks: data,
        getReportBlocksStatus: status
    };
}

export async function copyReportBlocksContent(meetingId) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.reportBlocks,
        path: `/copy/${meetingId}`
    });

    return data.content.map((block) => block.trimStart()).join('\n');
}

export async function createReportBlock({ meetingId, htmlContent, htmlTag, position }) {
    const { data, status } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.reportBlocks,
        path: '/create',
        data: {
            htmlContent,
            htmlTag,
            meetingId,
            position,
            style: 'block' // TODO: It should depend
        }
    });

    return {
        reportBlock: data,
        getReportBlockStatus: status
    };
}

export async function bulkUpdateReportBlocksWords({ meetingId, currentWords, newWords }) {
    const { data, status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.reportBlocks,
        path: `/update/words/${meetingId}`,
        data: {
            currentWords,
            newWords
        }
    });

    return {
        updatedReportBlocks: data,
        updatedReportBlocksStatus: status
    };
}

export async function updateReportBlockContent({ id, newHtmlContent }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.reportBlocks,
        path: `/update`,
        data: {
            id,
            htmlContent: newHtmlContent
        }
    });

    return status;
}

export async function updateReportBlockTag({ id, newHtmlTag }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.reportBlocks,
        path: `/update`,
        data: {
            id,
            htmlTag: newHtmlTag
        }
    });

    return status;
}

export async function updateReportBlocksPosition(updatedBlocks, meetingId) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.reportBlocks,
        path: `/update/position`,
        data: { blocks: updatedBlocks, meetingId }
    });

    return status;
}

export async function updateReportBlockStyle({ id, style }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.reportBlocks,
        path: `/update`,
        data: {
            id,
            style
        }
    });

    return status;
}

export async function deleteReportBlock({ id, meetingId, position, lastBlockPosition }) {
    const { status } = await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.reportBlocks,
        path: `/delete`,
        data: { id, meetingId, oldPosition: position, newPosition: lastBlockPosition }
    });

    return status;
}
