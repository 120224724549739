import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function ColorPalette({ handleOnColorChange, color }) {
    return (
        <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={1}>
                <Tooltip title='Gris'>
                    <Box
                        id='chip_grey'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.grey[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_grey' ? `2px solid ${palette.grey[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Oliva'>
                    <Box
                        id='chip_olive'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.olive[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_olive' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Laurel'>
                    <Box
                        id='chip_laurel'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.laurel[200],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_laurel' ? `2px solid ${palette.laurel[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Esmeralda'>
                    <Box
                        id='chip_emerald'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.emerald[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_emerald'
                                    ? `2px solid ${palette.emerald[800]}`
                                    : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Amarillo'>
                    <Box
                        id='chip_yellow'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.yellow[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_yellow' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
            </Stack>
            <Stack direction='row' spacing={1}>
                <Tooltip title='Melocotón'>
                    <Box
                        id='chip_peach'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.peach[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_peach' ? `2px solid ${palette.peach[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Madera'>
                    <Box
                        id='chip_wood'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.wood[200],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_wood' ? `2px solid ${palette.wood[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Voicit'>
                    <Box
                        id='chip_secondary'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.secondary[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_secondary'
                                    ? `2px solid ${palette.primary.main}`
                                    : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Naranja'>
                    <Box
                        id='chip_orange'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.orange[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_orange' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Rojo'>
                    <Box
                        id='chip_red'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.red[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_red' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
            </Stack>
            <Stack direction='row' spacing={1}>
                <Tooltip title='Rosa'>
                    <Box
                        id='chip_pink'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.pink[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_pink' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Morado'>
                    <Box
                        id='chip_purple'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.purple[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border:
                                color === 'chip_purple' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Océano'>
                    <Box
                        id='chip_ocean'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.ocean[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_ocean' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Azul'>
                    <Box
                        id='chip_blue'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.blue[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_blue' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
                <Tooltip title='Verde'>
                    <Box
                        id='chip_green'
                        onClick={handleOnColorChange}
                        sx={{
                            height: 20,
                            width: 40,
                            backgroundColor: palette.green[100],
                            borderRadius: 1,
                            cursor: 'pointer',
                            border: color === 'chip_green' ? `2px solid ${palette.blue[800]}` : null
                        }}
                    />
                </Tooltip>
            </Stack>
        </Stack>
    );
}

ColorPalette.propTypes = {
    handleOnColorChange: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};
