import { useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
    Avatar,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Popover,
    Stack
} from '@mui/material';
import PropTypes from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import SwitchButton from '@common/components/buttons/SwitchButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import selectorLanguages from '@common/helpers/selectorLanguages.json';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { getLanguageFlag } from '@common/utils/languages';

TemplateMenuConfig.propTypes = {
    isActive: PropTypes.bool,
    handleSetActive: PropTypes.func.isRequired,
    reportLanguage: PropTypes.string.isRequired,
    handleChangeReportLanguage: PropTypes.func.isRequired
};

export default function TemplateMenuConfig({
    isActive,
    handleSetActive,
    reportLanguage,
    handleChangeReportLanguage
}) {
    const { getPlan } = useUser();

    const [anchorElChip, setAnchorElChip] = useState(null);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const handleOnChange = () => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
        } else {
            handleSetActive();
        }
    };

    const handleChangeLanguage = async (languageCode) => {
        try {
            await handleChangeReportLanguage(languageCode);
            handleCloseList();
        } catch (error) {
            console.log('Error changing language');
        }
    };

    return (
        <>
            <Grid container direction='column' alignItems='left' spacing={2}>
                <Grid item xs={12}>
                    <FormControl component='fieldset' variant='standard'>
                        <FormLabel component='legend' color='primary'>
                            Activar Plantilla
                        </FormLabel>
                        <FormControlLabel
                            // We just run onChange for non-activated templates
                            control={<SwitchButton checked={isActive} onChange={handleOnChange} />}
                            disabled={isActive}
                            label={
                                isActive
                                    ? 'Activada por defecto para tus reuniones'
                                    : 'Estará activada por defecto para tus reuniones'
                            }
                            labelPlacement='start'
                            sx={{
                                mt: -1,
                                ml: 0,
                                '& .MuiFormControlLabel-label': {
                                    color: palette.primary[400], // Customize the label color
                                    typography: 'body2' // Customize the label typography
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component='fieldset' variant='standard'>
                        <FormLabel component='legend' color='primary'>
                            Idioma del informe
                        </FormLabel>
                    </FormControl>
                    <Stack spacing={1} direction='row' alignItems='center'>
                        <Chip
                            sx={{
                                borderRadius: 2,
                                justifyContent: 'left'
                            }}
                            label={
                                selectorLanguages.find(
                                    (language) => language.code === reportLanguage
                                ).label
                            }
                            color={'chip_grey'}
                            size='small'
                            avatar={
                                reportLanguage && <Avatar src={getLanguageFlag(reportLanguage)} />
                            }
                            icon={!reportLanguage && <AutoAwesomeIcon />}
                        />
                        <IconButtonWithTooltip
                            icon={<ExpandMoreOutlinedIcon size='small' />}
                            tooltipText={'Cambiar idioma del informe'}
                            onClick={handleOpenList}
                        />
                    </Stack>

                    <Popover
                        sx={{ mt: '45px' }}
                        id='menu-filters'
                        anchorEl={anchorElChip}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorElChip)}
                        onClose={handleCloseList}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', m: 0 }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    spacing={0}
                                    direction={'column'}
                                    sx={{ alignItems: 'left' }}
                                >
                                    {selectorLanguages.map((language, index) => (
                                        <Grid
                                            item
                                            key={language.code}
                                            sx={{
                                                mt: 1,
                                                ml: 1,
                                                mr: 1,
                                                mb: selectorLanguages.length - 1 === index ? 1 : 0
                                            }}
                                        >
                                            <Chip
                                                sx={{
                                                    flexGrow: 1,
                                                    borderRadius: 2,
                                                    justifyContent: 'left'
                                                }}
                                                label={language.label}
                                                onClick={async () =>
                                                    await handleChangeLanguage(language.code)
                                                }
                                                color={'chip_grey'}
                                                size='small'
                                                avatar={
                                                    language.code && (
                                                        <Avatar
                                                            src={getLanguageFlag(language.code)}
                                                        />
                                                    )
                                                }
                                                icon={!language.code && <AutoAwesomeIcon />}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>
            <PricingDialog openDialog={openPremiumDialog} setOpenDialog={setOpenPremiumDialog} />
        </>
    );
}
