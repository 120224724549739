import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import palette from '@common/theme/palette/palette';
import NotLoggedLayout from '@pages/common/NotLoggedLayout';
import FormEmail from './components/formEmail/FormEmail';
import FormPassword from './components/formPassword/FormPassword';

export default function PasswordForgotten() {
    const [searchParams] = useSearchParams();
    const isAuthToken = searchParams.has('t');
    const token = isAuthToken ? searchParams.getAll('t') : null;

    const navigate = useNavigate();

    const handleNavigateToBetaForm = () => {
        navigate(navigation.app.routes.signup);
    };

    const handleNavigateToLogin = () => {
        navigate(navigation.app.routes.login);
    };

    return (
        <Page title='Reestablecer contraseña'>
            <NotLoggedLayout
                text={
                    isAuthToken
                        ? 'Olvídate de hacer resúmenes, pero no de tu contraseña.'
                        : 'A nosotros también se nos olvida tomar notas muchas veces.'
                }
            >
                {isAuthToken ? <FormPassword token={token} /> : <FormEmail />}
                <Grid
                    container
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    {isAuthToken ? null : (
                        <Grid item>
                            <Link
                                component='button'
                                variant='body2'
                                underline='hover'
                                color={palette.primary[900]}
                                onClick={handleNavigateToBetaForm}
                            >
                                ¿Aún no estás registrado?
                            </Link>
                        </Grid>
                    )}
                    <Grid container justifyContent='flex-end' sx={{ mt: isAuthToken ? 0 : -3 }}>
                        <Grid item>
                            <Link
                                component='button'
                                variant='body2'
                                underline='hover'
                                color={palette.primary[900]}
                                onClick={handleNavigateToLogin}
                            >
                                Volver a inicio
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </NotLoggedLayout>
        </Page>
    );
}
