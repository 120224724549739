import { authApiCall, endpoints } from '..';

export async function getAllSections() {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.section,
        path: `/all`
    });
    return data;
}

export async function getSectionById(sectionId) {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.section,
        path: `/${sectionId}`
    });
    return data;
}

export async function createSection({ name, goal, guidelines, formatType }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.section,
        path: `/create`,
        data: {
            queryType: 'question_and_answer',
            language: 'es-ES',
            name,
            goal,
            guidelines,
            formatType
        }
    });
    return data;
}

export async function updateSection({ sectionId, name, goal, guidelines, formatType }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.section,
        path: `/${sectionId}`,
        data: {
            name,
            goal,
            guidelines,
            formatType
        }
    });
    return status;
}

export async function shareSection({ sectionId }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.section,
        path: `/${sectionId}/share`
    });
    return status;
}

export async function deleteSection(sectionId) {
    const { status } = await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.section,
        path: `/${sectionId}`
    });
    return status;
}
