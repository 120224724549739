import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function FormEmailView({ handleSubmit, alert, loading, alertMessage }) {
    return (
        <Box>
            <Box
                sx={{
                    mt: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                }}
            >
                <Typography component='h1' variant='h3' color={palette.primary[900]}>
                    ¿Olvidaste tu contraseña?
                </Typography>
                <Typography component='h1' variant='body1' color={palette.primary[300]}>
                    Introduce el email que utilizaste para registrarte en Voicit y te enviaremos las
                    intrucciones para cambiar la contraseña.
                </Typography>
            </Box>
            <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <Box>
                    <TextField
                        error={alert}
                        helperText={alertMessage}
                        margin='normal'
                        size='small'
                        required
                        fullWidth
                        id='email'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        variant='standard'
                    />
                </Box>

                {loading ? (
                    <LoadingButton
                        loading
                        loadingPosition='start'
                        variant='contained'
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        disableElevation={true}
                        startIcon={<SaveIcon />}
                    >
                        Enviando instrucciones
                    </LoadingButton>
                ) : (
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disableElevation={true}
                    >
                        Enviar instrucciones
                    </Button>
                )}
            </Box>
        </Box>
    );
}

FormEmailView.propTypes = {
    handleSubmit: PropTypes.elementType,
    alert: PropTypes.bool,
    alertMessage: PropTypes.string,
    loading: PropTypes.bool
};
