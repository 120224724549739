import React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

export default function Loader({ maxWidth, minWidth, minHeight, size, text, progress }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            minHeight={minHeight ? minHeight : '55vh'}
            minWidth={minWidth ? minWidth : '65vh'}
            maxWidth={maxWidth ? maxWidth : 'auto'}
        >
            <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
                <Typography variant='subtitle2' color='primary'>
                    {text}
                </Typography>
                {progress >= 0 ? (
                    <CircularProgress
                        variant='determinate'
                        color='primary'
                        size={size ? size : '2rem'}
                        value={progress}
                    />
                ) : (
                    <CircularProgress color='primary' size={size ? size : '2rem'} />
                )}
            </Stack>
        </Box>
    );
}

Loader.propTypes = {
    minHeight: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string,
    progress: PropTypes.number
};
