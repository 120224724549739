import React, { useEffect, useState } from 'react';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { Box, Chip, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InformationAlert from '@common/components/alerts/InformationAlert';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/palette/shadows';
import { getColorFromLetter } from '@common/utils/colors';
import { orderTemplatesByEditedTime } from '@pages/templatesRepository/utils';
import { getAllReportTemplates } from '@setup/api/reportTemplates/reportTemplates';

export default function Dashboard({ monthlyMeetings, monthlyMinutes }) {
    const navigate = useNavigate();
    const userCreatedAt = useSelector((state) => state.user.userCreatedAt);
    const userPlan = useSelector((state) => state.user.userPlan);
    const { getPlan, userIsRunOutOfMinutes } = useUser();

    const [templates, setTemplates] = useState();
    const [openPricingDialog, setOpenPricingDialog] = useState(false);
    const [trialProperties, setTrialProperties] = useState(null);

    useEffect(() => {
        (async () => {
            const { data } = await getAllReportTemplates();
            // Introducing just first 2 templates
            setTemplates(orderTemplatesByEditedTime(data).slice(0, 1));
        })();
    }, []);

    useEffect(() => {
        const date = new Date().toJSON();
        const createdAt = new Date(userCreatedAt);
        const current = new Date(date);
        const diffInDays = Math.floor((current - createdAt) / (1000 * 60 * 60 * 24)); // from milliseconds to days
        const remainingDays = userPlan?.trialDays - (diffInDays >= 0 ? diffInDays : 0);
        setTrialProperties({
            diffInDays,
            remainingDays
        });
    }, []);

    return (
        <>
            {userPlan?.planName !== subscriptionPlans.free.name &&
                trialProperties?.diffInDays < userPlan?.trialDays && (
                    <InformationAlert
                        message={`🎉¡Aún te ${
                            trialProperties.remainingDays > 1 ? 'quedan' : 'queda'
                        }   <b>${trialProperties.remainingDays}
                           ${
                               trialProperties.remainingDays > 1 ? 'días' : 'día'
                           }</b> gratis en el <b>plan ${
                            getPlan().label
                        }</b>! Personaliza tus plantillas de resúmenes según tus reuniones.`}
                        onClick={() => setOpenPricingDialog(true)}
                        buttonText='Ver planes'
                        type='highlight'
                    />
                )}
            {userIsRunOutOfMinutes && (
                <InformationAlert
                    message='⏱ Has agotado tus minutos disponibles. <b>¡Actualiza tu plan para conseguir más minutos!</b>'
                    type='warning'
                    onClick={() => setOpenPricingDialog(true)}
                    buttonText='Conseguir más minutos'
                />
            )}
            <Grid
                container
                direction='row'
                sx={{
                    boxShadow: shadows.elevated,
                    backgroundColor: palette.primary.main,
                    borderRadius: 1,
                    p: 4,
                    mt: 2
                }}
            >
                <Grid item flexGrow={1}>
                    <Grid container direction='column'>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Typography component='span' variant='subtitle1' color='white'>
                                Tus reuniones del mes en cifras
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row' spacing={5}>
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item xs={6}>
                                            <Typography component='span' variant='h3' color='white'>
                                                {monthlyMeetings || 0}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component='span'
                                                variant='subtitle1'
                                                color={palette.primary[300]}
                                            >
                                                documentadas
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item xs={6}>
                                            <Typography component='span' variant='h3' color='white'>
                                                {monthlyMinutes || 0}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component='span'
                                                variant='subtitle1'
                                                color={palette.primary[300]}
                                            >
                                                minutos totales
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item xs={6}>
                                            <Typography component='span' variant='h3' color='white'>
                                                {monthlyMinutes > 0 && monthlyMeetings > 0
                                                    ? Math.round(monthlyMinutes / monthlyMeetings)
                                                    : 0}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                component='span'
                                                variant='subtitle1'
                                                color={palette.primary[300]}
                                            >
                                                minutos de media
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {templates && templates[0] !== undefined ? (
                    <>
                        {templates?.map((template, i) => {
                            return (
                                <Grid
                                    item
                                    key={i}
                                    ml={1}
                                    sx={{ display: { xs: 'none', md: 'block' } }}
                                >
                                    <Box
                                        display='flex'
                                        justifyContent='center'
                                        alignItems='center'
                                        sx={{
                                            border: `1px solid ${palette.primary[600]}`,
                                            backgroundColor: palette.primary[800],
                                            cursor: 'pointer',
                                            borderRadius: '4px',
                                            position: 'relative',
                                            ':hover': {
                                                backgroundColor: palette.primary[700],
                                                cursor: 'pointer'
                                            }
                                        }}
                                        height='100%'
                                        width='220px'
                                        onClick={() => {
                                            navigate(`/templates/edit/${template.id}`);
                                        }}
                                    >
                                        <Grid xs={12} container height={'100%'}>
                                            <Grid id='editTemplate' item height='70%'>
                                                <Stack p={1.5}>
                                                    <Typography
                                                        id='editTemplate'
                                                        color={palette.common.white}
                                                    >
                                                        {template.name}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                width='100%'
                                                height='30%'
                                                display='flex'
                                                alignItems='center'
                                                p={2}
                                                sx={{
                                                    borderBottomLeftRadius: '4px',
                                                    borderBottomRightRadius: '4px'
                                                }}
                                                bgcolor={getColorFromLetter(template.name).variant}
                                                item
                                            >
                                                <Grid
                                                    display='flex'
                                                    alignItems='center'
                                                    xs={12}
                                                    container
                                                >
                                                    {template.isActive && (
                                                        <Grid
                                                            display='flex'
                                                            justifyContent='start'
                                                            xs={6}
                                                            item
                                                        >
                                                            <Chip
                                                                label={'Activa'}
                                                                size='small'
                                                                sx={{
                                                                    backgroundColor: 'black',
                                                                    color: 'white'
                                                                }}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </>
                ) : (
                    <Grid item ml={1} sx={{ display: { sm: 'none', md: 'block' } }}>
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                border: `1px solid ${palette.primary[600]}`,
                                backgroundColor: palette.primary[800],
                                cursor: 'pointer',
                                borderRadius: '4px',
                                position: 'relative',
                                ':hover': {
                                    backgroundColor: palette.primary[700],
                                    cursor: 'pointer'
                                }
                            }}
                            height='100%'
                            width='220px'
                        >
                            <Grid xs={12} container height={'100%'}>
                                <Grid id='editTemplate' item height='70%' width='100%'>
                                    <Stack p={1.5}>
                                        <Typography component='div' key='s01-03'>
                                            <Skeleton animation='wave' width='100%' />
                                        </Typography>
                                        <Typography component='div' key='s01-03'>
                                            <Skeleton animation='wave' width='70%' />
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid
                                    width='100%'
                                    height='30%'
                                    display='flex'
                                    alignItems='center'
                                    p={2}
                                    sx={{
                                        borderBottomLeftRadius: '4px',
                                        borderBottomRightRadius: '4px'
                                    }}
                                    bgcolor={getColorFromLetter('').variant}
                                    item
                                />
                            </Grid>
                        </Box>
                    </Grid>
                )}

                <Grid item ml={1} sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                    <Tooltip title='Ver plantillas' followCursor>
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            sx={{
                                border: `1px solid ${palette.primary[600]}`,
                                backgroundColor: palette.primary[800],
                                borderRadius: 1,
                                height: '100%',
                                p: 0.5,
                                '&:hover': {
                                    backgroundColor: palette.primary[700],
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() => navigate(navigation.app.routes.templates)}
                        >
                            <Grid item>
                                <DashboardCustomizeOutlinedIcon
                                    sx={{ color: `${palette.common.white}` }}
                                    fontSize='small'
                                />
                            </Grid>
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item ml={1} sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                    <Tooltip title='Integraciones' followCursor>
                        <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            sx={{
                                border: `1px solid ${palette.primary[600]}`,
                                backgroundColor: palette.primary[800],
                                borderRadius: 1,
                                height: '100%',
                                p: 0.5,
                                '&:hover': {
                                    backgroundColor: palette.primary[700],
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() =>
                                navigate(`${navigation.app.routes.settings}/integrations`)
                            }
                        >
                            <Grid item>
                                <ExtensionOutlinedIcon
                                    sx={{ color: `${palette.common.white}` }}
                                    fontSize='small'
                                />
                            </Grid>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
            <PricingDialog
                openDialog={openPricingDialog}
                setOpenDialog={setOpenPricingDialog}
                defaultPlanId={getPlan()?.name === subscriptionPlans.premium.name ? 1 : 0}
            />
        </>
    );
}

Dashboard.propTypes = {
    monthlyMeetings: PropTypes.number,
    monthlyMinutes: PropTypes.number
};
