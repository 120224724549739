import React from 'react';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import isotype from '@assets/brand/voicit-isotype-24px.svg';
import shadows from '@common/theme/palette/shadows';

export default function VoicitAvatar({ size, sx, logoColor }) {
    return (
        <Box
            sx={{
                maxWidth: size,
                boxShadow: shadows.elevated,
                borderRadius: 1,
                ...sx
            }}
        >
            <img
                src={isotype}
                alt='voicit-isotype'
                style={{ width: '100%', height: '100%', color: logoColor ? logoColor : 'default' }}
            />
        </Box>
    );
}

VoicitAvatar.propTypes = {
    size: PropTypes.number,
    sx: PropTypes.any,
    logoColor: PropTypes.string
};
