import React, { useEffect, useState } from 'react';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import CopyIconButton from '@common/components/buttons/CopyIconButton';
import DialogButton from '@common/components/dialogs/DialogButton';
import MultipleEmailsField from '@common/components/form/MultipleEmailsField';
import { navigation } from '@common/helpers/navigation';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { getSharingMeetingUrl, shareMeetingByMail } from '@setup/api/meeting/meeting';
import IconPhrase from '../icons/IconPhrase';

ShareMeetingDialog.propTypes = {
    meetingId: PropTypes.number.isRequired,
    meetingTitle: PropTypes.string.isRequired,
    openDialog: PropTypes.bool,
    closeDialog: PropTypes.func,
    withButton: PropTypes.bool.isRequired,
    buttonVariant: PropTypes.string
};

export default function ShareMeetingDialog({
    meetingId,
    meetingTitle,
    openDialog,
    closeDialog,
    withButton,
    buttonVariant
}) {
    const notification = useNotification();
    const navigate = useNavigate();

    const [shareableUrl, setShareableUrl] = useState('');
    const [emails, setEmails] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const { getPlan } = useUser();

    const handleInvitation = async () => {
        if (emails.length > 0) {
            for (const email of emails) {
                await shareMeetingByMail({
                    meetingId,
                    isPremiumUser: getPlan().name !== subscriptionPlans.free.name,
                    to: email
                });
            }
            notification('meeting-shared');
            return true;
        } else {
            setAlertMessage(
                'No has introducido ninguna dirección de correo aún. Pulsa enter para introducir un correo.'
            );
        }
    };

    const handleClose = () => {
        setEmails([]);
        if (closeDialog) {
            closeDialog();
        }
    };

    useEffect(() => {
        (async () => {
            const { sharedUrl } = await getSharingMeetingUrl({
                meetingId,
                isPremiumUser: getPlan().name !== subscriptionPlans.free.name
            });
            setShareableUrl(sharedUrl);
        })();
    }, []);

    return (
        <DialogButton
            id='clickedShareButtonDialog'
            successButtonId='clickedShareButtonByMail'
            withButton={withButton}
            successButtonText='Compartir por correo'
            onSuccess={handleInvitation}
            buttonText={`Compartir`}
            buttonIcon={<InsertLinkOutlinedIcon />}
            dialogTitle={`Compartir '${meetingTitle}'`}
            buttonVariant={buttonVariant ? buttonVariant : 'text'}
            dialogText={
                'Utiliza el enlace para tener acceso a tu reunión en cualquier lugar o envía por correo a quien quieras.'
            }
            openDialog={openDialog}
            onClose={handleClose}
            successButtonLoadingText='Enviando...'
        >
            <Grid container direction='column'>
                <Grid item>
                    <TextField
                        sx={{ mt: 2 }}
                        id='copy-url'
                        label='Url para compartir reunión'
                        value={shareableUrl}
                        variant='filled'
                        fullWidth={true}
                        InputProps={{
                            endAdornment: (
                                <CopyIconButton
                                    id='clickedShareMeetingByURL'
                                    content={shareableUrl}
                                    size={'small'}
                                />
                            )
                        }}
                    />
                </Grid>

                <Grid item>
                    <MultipleEmailsField
                        label='Compartir por correo'
                        alertMessage={alertMessage}
                        emails={emails}
                        setEmails={setEmails}
                    />
                </Grid>

                <Grid item sx={{ mt: 2 }}>
                    <Typography component='span' variant='subtitle1' color={palette.primary[300]}>
                        Permisos
                    </Typography>
                </Grid>
                <Grid item sx={{ mt: 1 }}>
                    <IconPhrase
                        icon={<LanguageOutlinedIcon />}
                        text='Cualquier persona con enlace puede ver tu reunión'
                    />
                </Grid>
                <Grid item sx={{ mt: 3 }}>
                    <Button
                        variant='text'
                        startIcon={<ExtensionOutlinedIcon fontSize='small' />}
                        onClick={() => navigate(`${navigation.app.routes.settings}/integrations`)}
                        sx={{ mr: 1 }}
                    >
                        Integraciones
                    </Button>
                </Grid>
            </Grid>
        </DialogButton>
    );
}
