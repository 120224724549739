import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumb from '@common/components/BreadCrumb';
import Page from '@common/components/Page';
import ProfileTabs from './components/verticalTabs/ProfileTabs';

export default function SettingsPage() {
    const title = 'Ajustes';

    return (
        <Page title={title}>
            <Box sx={{ display: 'flex', minHeight: '100vh', paddingTop: 4 }}>
                <Box
                    component='main'
                    sx={{
                        backgroundColor: 'white',
                        flexGrow: 1,
                        height: 'auto',
                        overflow: 'auto'
                    }}
                >
                    <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                        <Grid container direction='column' spacing={3}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Breadcrumb currentPageTitle={title} />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <Typography component='span' variant='h4'>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ProfileTabs />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Page>
    );
}
