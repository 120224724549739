import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';

OptionsSelector.propTypes = {
    options: PropTypes.arrayOf(Object),
    handleChange: PropTypes.func,
    label: PropTypes.string,
    alertMessage: PropTypes.string
};

export default function OptionsSelector({ options, label, handleChange }) {
    return (
        <Autocomplete
            sx={{ mt: 2, mb: 1 }}
            options={options}
            getOptionLabel={(option) => `${option.label}`}
            onChange={(e, value) => handleChange(value)}
            fullWidth
            id={label}
            autoHighlight
            renderInput={(params) => <TextField {...params} label={label} variant='standard' />}
        />
    );
}
