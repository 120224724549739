import React from 'react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import TablePaginationMUI from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

function PaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, float: 'left' }}>
            <IconButton
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='first page'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                sx={{
                    borderRadius: '4px',
                    border: '0px solid'
                }}
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

PaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export default function TablePagination(props) {
    const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, rowCount } = props;
    return (
        <TablePaginationMUI
            labelRowsPerPage={''}
            rowsPerPageOptions={[5, 10, 15]}
            colSpan={4}
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            sx={{ borderBottom: 'none' }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
            labelDisplayedRows={({ from, to, count }) => {
                return (
                    <Typography variant='body2' color={palette.primary[300]} sx={{ mt: 2 }}>
                        {`Reuniones ${from} a ${to} de ${count !== -1 ? count : `más de ${to}`}`}
                    </Typography>
                );
            }}
        />
    );
}

TablePagination.propTypes = {
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    rowCount: PropTypes.number
};
