import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export default function IconLoader() {
    return (
        <Stack sx={{ color: 'grey.500' }} spacing={0} direction='row'>
            <CircularProgress color='primary' size='1rem' />
        </Stack>
    );
}
