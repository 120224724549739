export const profileNotification = [
    {
        type: 'image-upload-failure',
        message: 'Ha ocurrido un error subiendo tu imagen, intentalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'image-uploaded',
        message: 'Imagen subida con éxito, vuelve a entrar a la app para ver el cambio.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'password-updated',
        message: 'Contraseña actualizada.',
        actionTitle: null,
        actionFunc: null
    }
];
