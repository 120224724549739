import React, { useState } from 'react';
import { Chip, Paper, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MUIToggleButton from '@mui/material/ToggleButton';
import { PropTypes } from 'prop-types';
// import palette from '@common/theme/palette/palette';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius
        }
    }
}));

ToggleButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            new: PropTypes.bool
        })
    ).isRequired,
    defaultOptionId: PropTypes.number,
    handleChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    avoidOptionChange: PropTypes.bool
};

/**
 * Toogle between options
 *
 * @param {array} options - Name of the selection options
 * @param {number} defaultOptionId - Default option to be selected
 * @param {func} handleChange - Function to handle logic when changing from one option to another
 * @param {bool} fullWidth - Fill the container or not
 * @param {bool} avoidOptionChange - Avoid or not clicking not active options
 */
export default function ToggleButton({
    options,
    defaultOptionId,
    handleChange,
    fullWidth,
    avoidOptionChange
}) {
    const [value, setValue] = useState(defaultOptionId ? defaultOptionId : 0);

    const handleOnChange = (event, newValue) => {
        if (newValue != null && value !== newValue) {
            if (!avoidOptionChange) {
                setValue(newValue);
            }
            if (handleChange) {
                handleChange(newValue);
            }
        }
    };

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap'
                }}
            >
                <StyledToggleButtonGroup
                    size='small'
                    value={value}
                    exclusive
                    onChange={handleOnChange}
                    aria-label='option'
                    fullWidth={fullWidth ? true : false}
                >
                    {options.map((option, index) => {
                        return (
                            <MUIToggleButton key={index} value={index} aria-label='left aligned'>
                                <Typography
                                    color='primary'
                                    variant='subtitle1'
                                    component='p'
                                    mr={1}
                                >
                                    {option.label}
                                </Typography>
                                {option.new && (
                                    <Chip
                                        label='Beta'
                                        variant='contained'
                                        color='chip_secondary_soft'
                                        size='small'
                                        ml={1}
                                    />
                                )}
                            </MUIToggleButton>
                        );
                    })}
                </StyledToggleButtonGroup>
            </Paper>
        </div>
    );
}
