import React, { useEffect, useState } from 'react';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { Box, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// import CopyIconButton from '@common/components/buttons/CopyIconButton';
// import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import Paragraph from '@common/components/Paragraph';
import palette from '@common/theme/palette/palette';
import { getColorFromLetter } from '@common/utils/colors';
import { getTimeMeetingIntervention, timeKeyToSeconds } from '@common/utils/date';
import './styles.css';

export default function Intervention({
    showInterDetails,
    // handleDeleteIntervention,
    intervention,
    //    creationDate,
    duration,
    handleUpdateIntervention,
    isShared,
    speakersInterventionPercentages,
    currentAudioTime,
    setCurrentAudioTime,
    setAudioInterventionTimeKey
}) {
    const [, setShowActions] = useState(false);
    const [onInterventionEdit, setOnInterventionEdit] = useState(false);
    const [interventionValue, setInterventionValue] = useState(intervention.lines);

    const confidencePercentage = intervention.confidence
        ? Math.trunc(intervention.confidence * 100)
        : 100;
    const interventionStartTimeInSeconds = timeKeyToSeconds(intervention.timeKey1);
    const isBeingReproduced =
        currentAudioTime >= interventionStartTimeInSeconds &&
        currentAudioTime < timeKeyToSeconds(intervention.timeKey1 + intervention.timeKey2);

    const handleChangeIntervention = (event) => {
        setInterventionValue(event.target.value);
    };

    const handleCloseInterventionEdition = () => {
        setInterventionValue(intervention.lines);
        setOnInterventionEdit(false);
    };

    const onClickSave = () => {
        handleUpdateIntervention(intervention.id, interventionValue);
        setOnInterventionEdit(false);
    };

    const time = getTimeMeetingIntervention({
        timeKey: intervention.timeKey1,
        hoursDisabled: duration > 60 * 60 ? false : true
    });

    useEffect(() => {
        setInterventionValue(intervention.lines);
    }, [intervention]);

    useEffect(() => {
        if (isBeingReproduced) {
            setAudioInterventionTimeKey(intervention.timeKey1);
        }
    }, [currentAudioTime]);

    return (
        <Grid
            id={intervention.timeKey1}
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 1
            }}
            wrap='nowrap'
            spacing={0}
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => setShowActions(false)}
        >
            <Grid item>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mb: 1
                    }}
                    spacing={2}
                >
                    <Tooltip
                        title={
                            isBeingReproduced
                                ? 'Reproduciendo aquí'
                                : confidencePercentage < 50
                                ? `La precisión de la transcripción es más baja de lo habitual (${confidencePercentage}%)`
                                : speakersInterventionPercentages?.length > 0
                                ? `Ha intervenido un ${
                                      speakersInterventionPercentages[intervention.turn]
                                  }`
                                : ''
                        }
                        placement='left-end'
                        disableHoverListener={
                            speakersInterventionPercentages?.length > 0 ? false : true
                        }
                    >
                        <Grid item>
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'left'
                                }}
                            >
                                {confidencePercentage < 50 ? (
                                    <Grid item sx={{ mr: 1 }}>
                                        <ErrorOutlineOutlinedIcon color='error' fontSize='small' />
                                    </Grid>
                                ) : null}
                                <Grid item>
                                    <Box
                                        sx={{
                                            animationName: isBeingReproduced
                                                ? 'circle-pulse'
                                                : 'none',
                                            animationDuration: '1s',
                                            animationIterationCount: 'infinite',
                                            backgroundColor: `${
                                                getColorFromLetter(intervention.speaker).variant
                                            }`,
                                            color: `${
                                                getColorFromLetter(intervention.speaker).variant
                                            }`,
                                            height: 7,
                                            width: 7,
                                            border: `7px solid ${
                                                getColorFromLetter(intervention.speaker).variant
                                            }`,
                                            borderRadius: 1,
                                            mr: 1
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant='subtitle1'
                                        sx={{ margin: 0, textAlign: 'left' }}
                                        color='primary'
                                    >
                                        {intervention.speaker}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Tooltip>

                    {showInterDetails ? (
                        <Grid item>
                            <Typography
                                component='span'
                                variant='caption'
                                sx={{ margin: 0, textAlign: 'left' }}
                                color={palette.primary[300]}
                            >
                                {time}
                            </Typography>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {intervention.updatedAt && intervention.createdAt !== intervention.updatedAt ? (
                        <Grid item>
                            <Typography
                                component='span'
                                variant='caption'
                                sx={{ margin: 0, textAlign: 'left' }}
                                color={palette.primary[300]}
                            >
                                (Editado)
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    ml: 0,
                    mr: 0,
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                {onInterventionEdit ? (
                    <Grid item xs={12}>
                        <TextField
                            id='standard-multiline-static'
                            multiline
                            fullWidth
                            sx={{ height: 'auto' }}
                            variant='standard'
                            type={'text'}
                            value={interventionValue}
                            onChange={handleChangeIntervention}
                        />
                        <Grid item sx={{ mt: 3 }}>
                            <Button
                                variant='contained'
                                color='primary'
                                disableElevation={true}
                                onClick={onClickSave}
                            >
                                Guardar
                            </Button>
                            <Button
                                sx={{ ml: 1 }}
                                color='primary'
                                disableElevation={true}
                                onClick={handleCloseInterventionEdition}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            justifyContent={'left'}
                            xs={12}
                            zeroMinWidth
                            sx={{ mb: 0, cursor: 'pointer' }}
                            onClick={() => setCurrentAudioTime(interventionStartTimeInSeconds)}
                        >
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'left'
                                }}
                                onClick={isShared ? null : () => setShowActions(true)}
                            >
                                <Grid item xs={12}>
                                    <Paragraph
                                        text={interventionValue ? interventionValue : ''}
                                        color={
                                            isBeingReproduced
                                                ? palette.primary[900]
                                                : palette.primary[500]
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Add to the upper sx grid element mb: showActions ? -1 : 3.5
                        {showActions ? (
                            <Grid container flexDirection='row'>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<VolumeUpOutlinedIcon fontSize='small' />}
                                        tooltipText={'Escuchar'}
                                        onClick={() =>
                                            setCurrentAudioTime(interventionStartTimeInSeconds)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<EditOutlinedIcon fontSize='small' />}
                                        tooltipText={'Editar'}
                                        onClick={() => setOnInterventionEdit(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <CopyIconButton content={interventionValue} size='small' />
                                </Grid>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<DeleteOutlineOutlinedIcon fontSize='small' />}
                                        tooltipText={'Eliminar'}
                                        onClick={() => handleDeleteIntervention(intervention.id)}
                                    />
                                </Grid>
                            </Grid>
                        ) : null} */}
                    </>
                )}
            </Grid>
        </Grid>
    );
}

Intervention.propTypes = {
    showInterDetails: PropTypes.bool.isRequired,
    handleDeleteIntervention: PropTypes.func.isRequired,
    intervention: PropTypes.object.isRequired,
    creationDate: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    handleUpdateIntervention: PropTypes.func.isRequired,
    isShared: PropTypes.bool,
    speakersInterventionPercentages: PropTypes.arrayOf(PropTypes.string),
    currentAudioTime: PropTypes.number.isRequired,
    setCurrentAudioTime: PropTypes.func.isRequired,
    setAudioInterventionTimeKey: PropTypes.func.isRequired
};
