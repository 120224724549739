import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { notifications } from '@common/helpers/notificationTypes/index';
import { removeNotification } from '@setup/redux/reducers/notificationSlice';

export default function Notification({
    actionFunc,
    actionTitle,
    localEnabled,
    localMessage,
    setLocalEnabled,
    onClose
}) {
    const dispatch = useDispatch();
    const globalEnabled = useSelector((state) => state.notification.isActive);
    const type = useSelector((state) => state.notification.type);
    const message = useSelector((state) => state.notification.message);
    const [index, setIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(!localEnabled ? globalEnabled : localEnabled); // This is not working as expected
    const [notificationActionTitle, setNotificationActionTitle] = useState(actionTitle);

    const handleClose = () => {
        setOpen(false);
        dispatch(removeNotification());
        if (setLocalEnabled && setLocalEnabled !== null) {
            setLocalEnabled(false);
        }
        if (onClose) {
            onClose();
        }
        setLoader(false);
    };

    const handleTriggerAction = () => {
        if (notifications[index].actionTitle) {
            notifications[index].actionFunc();
        } else {
            actionFunc();
        }
        setLoader(true);
    };
    const action = (
        <>
            {notificationActionTitle ? (
                <>
                    {loader ? (
                        <>
                            <CheckCircleIcon fontSize='small' color='green' sx={{ mr: 1 }} />
                        </>
                    ) : (
                        <Button color='secondary' size='small' onClick={handleTriggerAction}>
                            {notificationActionTitle}
                        </Button>
                    )}
                </>
            ) : (
                <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            )}
        </>
    );

    useEffect(() => {
        // Not necessary if default value update the state, but that's not the case
        if (globalEnabled) {
            setOpen(globalEnabled);
        }
    }, [globalEnabled]);

    useEffect(() => {
        if (open) {
            if (!message) {
                for (var i = 0; i < notifications.length; i++) {
                    if (notifications[i].type === type) {
                        setIndex(i);
                        if (notifications[i].actionFunc !== null) {
                            setNotificationActionTitle(notifications[i].actionTitle);
                        }
                        return;
                    }
                }
            }
        }
    }, [open]);

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={Slide}
            autoHideDuration={5000}
            onClose={handleClose}
            message={localMessage ? localMessage : message ? message : notifications[index].message}
            action={action}
            key={Slide}
            sx={{
                backgroundColor: 'primary'
            }}
        />
    );
}

Notification.propTypes = {
    actionFunc: PropTypes.func,
    actionTitle: PropTypes.string,
    localEnabled: PropTypes.bool,
    localMessage: PropTypes.string,
    setLocalEnabled: PropTypes.func,
    onClose: PropTypes.func
};
