import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useUser from '@common/hooks/useUser';
import { signIn } from '@setup/api/auth';
import { setAuthToken } from '@setup/redux/reducers/authSlice';
import { removeUser } from '@setup/redux/reducers/userSlice';
import FormSignInView from './FormSignInView';

export default function FormSignIn() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { handleSignInDataAndRedirection } = useUser();

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSignIn = async (data) => {
        setLoading(true);
        dispatch(removeUser());
        dispatch(setAuthToken(null));
        try {
            const userData = await signIn({ data });
            handleSignInDataAndRedirection(
                userData,
                location.hash ? (location.search ? location.search : '') + location.hash : ''
            );
            setLoading(false);
        } catch (error) {
            setAlert(true);
            setAlertMessage(error.response.data.message);
            setLoading(false);
        }
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append('federated', false);
        handleSignIn(Object.fromEntries(data.entries()));
    };

    return (
        <FormSignInView
            handleSubmit={handleSubmitForm}
            alert={alert}
            alertMessage={alertMessage}
            loading={loading}
        />
    );
}
