import React from 'react';
import TableFooterMUI from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { PropTypes } from 'prop-types';
import Pagination from './TablePagination';

export default function TableFooter(props) {
    const { page, rowsPerPage, rowCount, handleChangePage, handleChangeRowsPerPage } = props;
    return (
        <TableFooterMUI>
            <TableRow>
                <Pagination
                    rowsPerPage={rowsPerPage}
                    page={page}
                    rowCount={rowCount}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableRow>
        </TableFooterMUI>
    );
}

TableFooter.propTypes = {
    handleOpenMeetingDialog: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rowCount: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func
};
