import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import { PropTypes } from 'prop-types';
import { meetingStatus } from '@common/helpers/meetingStatus';
import { useGetTagData } from '@common/hooks/useGetTagData';
import { utcToLocaleDate } from '@common/utils/date';
import { getNumberOfParticipants, shouldBeOnErrorState } from '@pages/meetingsRepository/utils';
import { getMeetingById } from '@setup/api/meeting/meeting';
import DateCell from './tableCells/DateCell';
import InfoCell from './tableCells/InfoCell';
import MenuCell from './tableCells/MenuCell';
import TitleCell from './tableCells/TitleCell';

TableRowView.propTypes = {
    handleOpenMeeting: PropTypes.func,
    meeting: PropTypes.object,
    handleDeleteMeeting: PropTypes.func,
    handleRetryMeeting: PropTypes.func,
    findAndUpdateMeeting: PropTypes.func.isRequired
};

export default function TableRowView({
    handleOpenMeeting,
    meeting,
    handleDeleteMeeting,
    handleRetryMeeting,
    findAndUpdateMeeting
}) {
    const [hover, setHover] = useState(false);
    const [currentMeeting, setCurrentMeeting] = useState(meeting);
    const { currentTagData } = useGetTagData(meeting.tag);

    const date = utcToLocaleDate({ utcDate: currentMeeting.createdAt, timeZone: 'es-ES' });
    const numberOfParticipants = getNumberOfParticipants(currentMeeting.speakers);
    const errorState = shouldBeOnErrorState({
        createdAt: currentMeeting.createdAt,
        updatedAt: currentMeeting.updatedAt,
        status: currentMeeting.transcriptionStatus
    });
    const isProcessing =
        currentMeeting.transcriptionStatus !== meetingStatus.SUMMARY_ERROR &&
        currentMeeting.transcriptionStatus !== meetingStatus.SUMMARY_SUCCEED &&
        currentMeeting.transcriptionStatus !== meetingStatus.TRANSCRIPTION_ERROR &&
        currentMeeting.transcriptionStatus !== meetingStatus.STAND_BY;
    const isStandBy = currentMeeting.transcriptionStatus === meetingStatus.STAND_BY;

    useEffect(() => {
        let timerInterval;
        if (meeting && !errorState && isProcessing) {
            if (!timerInterval) {
                timerInterval = setInterval(() => {
                    (async () => {
                        const { meeting: updatedMeeting } = await getMeetingById({
                            meetingId: meeting.id
                        });
                        if (
                            currentMeeting.transcriptionStatus !==
                            updatedMeeting.transcriptionStatus
                        ) {
                            setCurrentMeeting(updatedMeeting);
                            if (
                                updatedMeeting.transcriptionStatus !==
                                    meetingStatus.SUMMARY_ERROR &&
                                updatedMeeting.transcriptionStatus !==
                                    meetingStatus.SUMMARY_SUCCEED &&
                                updatedMeeting.transcriptionStatus !==
                                    meetingStatus.TRANSCRIPTION_ERROR &&
                                updatedMeeting.transcriptionStatus !== meetingStatus.AUDIO_ERROR &&
                                updatedMeeting.transcriptionStatus !== meetingStatus.SERVER_ERROR
                            ) {
                                clearInterval(timerInterval);
                            }
                        }
                    })();
                }, 9000);
            }
        } else if (timerInterval) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval);
    }, [currentMeeting]);

    return (
        <TableRow
            key={meeting.id}
            hover={true}
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
        >
            <TitleCell
                handleOpenMeeting={handleOpenMeeting}
                hover={hover}
                meeting={currentMeeting}
                errorState={errorState}
            />
            <InfoCell
                handleOpenMeeting={handleOpenMeeting}
                currentTagData={currentTagData}
                numberOfParticipants={numberOfParticipants}
                meeting={currentMeeting}
                errorState={errorState}
                hover={hover}
                isStandBy={isStandBy}
            />
            <DateCell
                handleOpenMeeting={handleOpenMeeting}
                date={date}
                errorState={errorState}
                meeting={currentMeeting}
            />
            <MenuCell
                meeting={currentMeeting}
                handleDeleteMeeting={handleDeleteMeeting}
                errorState={errorState}
                handleRetryMeeting={handleRetryMeeting}
                isProcessing={isProcessing}
                isStandBy={isStandBy}
                findAndUpdateMeeting={findAndUpdateMeeting}
                setCurrentMeeting={setCurrentMeeting}
            />
        </TableRow>
    );
}
