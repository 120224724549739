import CatFlag from '@assets/images/cat.jpg';
import GermanFlag from '@assets/images/de.png';
import EnglishFlag from '@assets/images/en.webp';
import SpanishFlag from '@assets/images/es.png';
import FrenchFlag from '@assets/images/fr.png';
import ItalianFlag from '@assets/images/it.png';
import PTFlag from '@assets/images/pt.png';

export const getLanguageFlag = (languageCode) => {
    switch (languageCode) {
        case 'es-ES':
            return SpanishFlag;
        case 'en-GB':
            return EnglishFlag;
        case 'ca-ES':
            return CatFlag;
        case 'fr-FR':
            return FrenchFlag;
        case 'de-DE':
            return GermanFlag;
        case 'it-IT':
            return ItalianFlag;
        case 'pt-PT':
            return PTFlag;
    }
};
