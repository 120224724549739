import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Chip, Grid, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import selectorTurns from '@common/helpers/selectorTurns.json';

TurnSelector.propTypes = {
    setTemplate: PropTypes.func,
    turn: PropTypes.number,
    onTurnChange: PropTypes.func,
    speakers: PropTypes.any,
    availableTurns: PropTypes.number
};

export default function TurnSelector({ turn, onTurnChange, speakers, availableTurns }) {
    const [anchorElChip, setAnchorElChip] = useState(null);
    const currentTurns = selectorTurns.slice(0, availableTurns ? availableTurns : speakers.length);

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const handleOnTurnChange = (data) => {
        onTurnChange(data);
        handleCloseList();
    };

    return (
        <Grid
            container
            sx={{ direction: 'row', alignItems: 'center', flexDirection: 'row', flexGrow: 1 }}
        >
            <Grid item mr={1}>
                <Tooltip title={'Cambiar turno de intervención'}>
                    <Chip
                        sx={{
                            flexGrow: 1,
                            borderRadius: 2,
                            justifyContent: 'left'
                        }}
                        label={
                            turn === -1
                                ? 'Orden de intervención'
                                : `${selectorTurns[turn]} en hablar`
                        }
                        color={turn === -1 ? 'chip_primary_soft' : 'chip_grey'}
                        size='small'
                        onDelete={
                            turn === -1 ? null : () => onTurnChange({ newTurn: -1, oldTurn: turn })
                        }
                        onClick={handleOpenList}
                        deleteIcon={turn === -1 ? null : <CancelIcon />}
                        icon={turn === -1 ? <ExpandMoreOutlinedIcon /> : null}
                    />
                </Tooltip>
            </Grid>
            <Popover
                sx={{ mt: '45px', maxHeight: 450, display: 'flex' }}
                id='menu-filters'
                anchorEl={anchorElChip}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={Boolean(anchorElChip)}
                onClose={handleCloseList}
            >
                <Grid
                    container
                    spacing={0}
                    direction={'column'}
                    sx={{ alignItems: 'left', m: 0, mb: 1 }}
                >
                    <Grid item>
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', pl: 2, pr: 2, pb: 1, pt: 1 }}
                        >
                            <Typography variant='caption'>Escucha el audio para ordenar</Typography>
                            {currentTurns.map((turnName, index) => (
                                <Grid item key={index} sx={{ mt: 1, ml: 1, mr: 1 }}>
                                    <Stack
                                        direction='row'
                                        spacing={1}
                                        alignItems='center'
                                        display='flex'
                                    >
                                        <Chip
                                            sx={{
                                                flexGrow: 1,
                                                borderRadius: 2,
                                                justifyContent: 'left'
                                            }}
                                            label={`${turnName} en hablar`}
                                            onClick={
                                                speakers.find((s) => s.turn === index)
                                                    ? null
                                                    : () => {
                                                          handleOnTurnChange({
                                                              newTurn: index,
                                                              oldTurn: turn !== -1 ? turn : null
                                                          });
                                                      }
                                            }
                                            color={
                                                speakers.find((s) => s.turn === index)
                                                    ? 'chip_disabled'
                                                    : 'chip_grey'
                                            }
                                            icon={
                                                speakers.find((s) => s.turn === index) ? (
                                                    <DoneIcon />
                                                ) : null
                                            }
                                            size='small'
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    );
}
