import { useDispatch } from 'react-redux';
import {
    setNotificationMessage,
    setNotificationType
} from '@setup/redux/reducers/notificationSlice';

export default function useNotification() {
    const dispatch = useDispatch();

    const handleShowNotification = (notification, message) => {
        if (message) {
            dispatch(setNotificationMessage(message));
        } else {
            dispatch(setNotificationType(notification));
        }
    };

    return handleShowNotification;
}
