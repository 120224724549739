import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Loader from '@common/components/loaders/Loader';
import { meetingStatus } from '@common/helpers/meetingStatus';
import ProgressLoader from './ProgressLoader';

export default function DialogloadingState({ currentMeeting, handleOpenMeetingDialog }) {
    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{ mt: 10, mb: 2 }}
        >
            {currentMeeting.transcriptionStatus === meetingStatus.AUDIO_STARTED ||
            currentMeeting.transcriptionStatus === meetingStatus.AUDIO_IN_PROGESS ||
            currentMeeting.transcriptionStatus === meetingStatus.TRANSCRIPTION_STARTED ||
            currentMeeting.transcriptionStatus === meetingStatus.TRANSCRIPTION_IN_PROGRESS ? (
                <ProgressLoader
                    currentMeeting={currentMeeting}
                    handleOpenMeetingDialog={handleOpenMeetingDialog}
                />
            ) : (
                <>
                    <Grid item>
                        <Typography component='span' variant='subtitle1'>
                            Generando el resumen de tu reunión
                        </Typography>
                    </Grid>
                    <Grid item justifyContent='center'>
                        <Typography component='span' variant='body2'>
                            Enviaremos un email a tu correo cuando el proceso termine.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Loader maxWidth='auto' minWidth='auto' minHeight='5vh' />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

DialogloadingState.propTypes = {
    currentMeeting: PropTypes.object.isRequired,
    handleOpenMeetingDialog: PropTypes.func.isRequired
};
