import { authApiCall, endpoints } from '..';

export async function getAllMeetingInterventions({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/${meetingId}`
    });

    return {
        interventions: res.data,
        interventionsStatus: res.status
    };
}

export async function getPaginatedMeetingInterventions({ meetingId, page, pageSize }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/paginated/${meetingId}/${page}/${pageSize}`
    });

    return {
        interventions: res.data.data,
        count: res.data.count
    };
}

export async function getFirstSpeaker({ meetingId }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/paginated/${meetingId}/${0}/${1}`
    });

    return {
        speaker: res.data.data[0].speaker
    };
}

export async function getPaginatedMeetingInterventionsBySpeaker({
    meetingId,
    page,
    pageSize,
    filterSpeakerTag
}) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/paginated/${meetingId}/${page}/${pageSize}`,
        params: {
            speaker: filterSpeakerTag
        }
    });

    return {
        interventions: res.data.data,
        count: res.data.count
    };
}

export async function getMeetingInterventionSpeakersWithTurn(meetingId) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.meetingInterventions,
        path: `/speakers/turns/${meetingId}`
    });

    return res.data;
}

/**
 * Update the name of a specific speaker, used to when deleting the turn, to get back the Speaker label
 * Affects to speakers with turn
 *
 */
export async function updateMeetingInterventionSpeaker({ meetingId, oldSpeaker, newSpeaker }) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetingInterventions,
        path: '/update/names/all',
        data: {
            meetingId,
            oldSpeaker,
            newSpeaker
        }
    });
}

/**
 * Update the turn of a specific speaker
 *
 */
export async function updateMeetingInterventionSpeakerByTurn({
    meetingId,
    oldTurn,
    oldSpeaker,
    newTurn,
    newSpeaker
}) {
    // ! oldTurn and oldSpeaker just usen when there is an actual old speaker (Speaker X is not)
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetingInterventions,
        path: '/update/turns/all',
        data: {
            meetingId,
            oldTurn,
            oldSpeaker,
            newTurn,
            newSpeaker
        }
    });
}

export async function updateMeetingInterventionLines({
    meetingId,
    newLines,
    meetingInterventionId
}) {
    await authApiCall({
        method: 'PUT',
        endpoint: endpoints.meetingInterventions,
        path: '/update/lines/one',
        data: {
            id: meetingInterventionId,
            meetingId,
            newLines
        }
    });
}

export async function deleteMeetingIntervention({ meetingId, meetingInterventionId }) {
    await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.meetingInterventions,
        path: '/delete/one',
        data: {
            meetingId,
            id: meetingInterventionId
        }
    });
}
