import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigation } from '@common/helpers/navigation';
import { setAuthToken } from '@setup/redux/reducers/authSlice';
import { setTranscriptionMinutes } from '@setup/redux/reducers/statsSlice';
import { removeUser } from '@setup/redux/reducers/userSlice';
import UserMenuView from './UserMenuView';

export default function UserMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const givenName = useSelector((state) => state.user.userGivenName);
    const email = useSelector((state) => state.user.userEmail);
    const avatarUrl = useSelector((state) => state.user.userAvatarUrl);
    const monthlyMeetings = useSelector((state) => state.stats.monthlyMeetings);

    const handleLogOut = async () => {
        try {
            dispatch(removeUser());
            dispatch(setTranscriptionMinutes(null));
            dispatch(setAuthToken(null));
            navigate(navigation.app.routes.login);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSettings = () => {
        navigate(`${navigation.app.routes.settings}/profile`);
    };

    const handleIntegrations = () => {
        navigate(`${navigation.app.routes.settings}/integrations`);
    };

    const handleTemplates = () => {
        navigate(navigation.app.routes.templates);
    };

    return (
        <UserMenuView
            handleLogOut={handleLogOut}
            handleSettings={handleSettings}
            handleTemplates={handleTemplates}
            givenName={givenName}
            email={email}
            monthlyMeetings={monthlyMeetings}
            avatarImage={avatarUrl}
            handleIntegrations={handleIntegrations}
        />
    );
}
