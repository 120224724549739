import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import palette from '../theme/palette/palette';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.primary[500]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 4,
        backgroundColor: palette.secondary.main
    }
}));

export default function ProgressBar({ maxValue, currentValue, sx, reverseProgress }) {
    const getValue = () => {
        if (maxValue === 0 && currentValue === 0) {
            return 0; // To ensure the progress bar shows as disabled
        }
        if (currentValue >= maxValue) {
            return 100;
        }
        return reverseProgress
            ? ((maxValue - currentValue) * 100) / maxValue
            : (currentValue * 100) / maxValue;
    };

    return (
        <Box sx={{ flexGrow: 1, ...sx }}>
            <BorderLinearProgress variant='determinate' value={getValue()} />
        </Box>
    );
}

ProgressBar.propTypes = {
    maxValue: PropTypes.number,
    currentValue: PropTypes.number,
    reverseProgress: PropTypes.bool,
    sx: PropTypes.object
};
