import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';
import { getColorFromLetter } from '@common/utils/colors';
import TemplateSkeleton from './TemplateCardSkeleton';

TemplateCardLoading.propTypes = {
    isAddHandler: PropTypes.bool,
    id: PropTypes.number,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    tag: PropTypes.any,
    author: PropTypes.string,
    onSetActive: PropTypes.func,
    onDelete: PropTypes.func,
    onNameChange: PropTypes.func,
    onHandlerClick: PropTypes.func
};

export default function TemplateCardLoading({ name }) {
    return (
        <Box id='editTemplate'>
            <TemplateSkeleton
                borderType='solid'
                borderWidth={2}
                shadow={0}
                borderColor={palette.primary[50]}
            >
                <Grid xs={12} container>
                    <Grid id='editTemplate' item height='120px' width='100%'>
                        <Stack p={2}>
                            <Typography component='div' key='s01-03' variant='h3'>
                                <Skeleton animation='wave' width='80%' />
                            </Typography>
                            <Typography component='div' key='s01-03' variant='body1'>
                                <Skeleton animation='wave' width='45%' />
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        width='100%'
                        height='50px'
                        display='flex'
                        alignItems='center'
                        p={2}
                        sx={{
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px'
                        }}
                        bgcolor={getColorFromLetter(name).variant}
                        item
                    />
                </Grid>
            </TemplateSkeleton>
        </Box>
    );
}
