import React, { useEffect, useState } from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PropTypes } from 'prop-types';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import DialogButton from '@common/components/dialogs/DialogButton';

export default function DatePickerButtonDialog({ setSearchInputDate }) {
    const [open, setOpen] = useState(false);
    const [fromValue, setFromValue] = useState(new Date());
    const [toValue, setToValue] = useState(fromValue);

    const handleApply = () => {
        setSearchInputDate({
            from: fromValue,
            to: toValue
        });
        setFromValue(new Date());
        setToValue(fromValue);
        setOpen(false);
    };

    useEffect(() => {
        setToValue(fromValue);
    }, [fromValue]);

    return (
        <>
            <IconButtonWithTooltip
                id='clickFilterByDate'
                tooltipText={'Filtrar por fecha'}
                onClick={() => setOpen(true)}
                icon={<CalendarTodayOutlinedIcon id='clickFilterByDate' />}
                size='medium'
            />
            <DialogButton
                withButton={false}
                dialogTitle={'Filtrar por fecha'}
                dialogText={'Introduce el rango de fechas por el que quieres filtrar.'}
                openDialog={open}
                onClose={() => setOpen(false)}
                onSuccess={handleApply}
                successButtonText={'Aplicar'}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label='Desde'
                        value={fromValue}
                        onChange={(newValue) => {
                            setFromValue(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth={true} sx={{ mt: 2 }} />
                        )}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label='Hasta'
                        value={toValue}
                        onChange={(newValue) => {
                            setToValue(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth={true} sx={{ mt: 2 }} />
                        )}
                    />
                </LocalizationProvider>
            </DialogButton>
        </>
    );
}

DatePickerButtonDialog.propTypes = {
    setSearchInputDate: PropTypes.func
};
