import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { urls } from '@common/helpers/urls';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import { validatePhone } from '@common/utils/validation';
import { grecaptchaVerify, signUp } from '@setup/api/auth';
import { config } from '@setup/config';
import FormSignUpView from './FormSignUpView';

FormSignUp.propTypes = {
    currentTemplate: PropTypes.arrayOf(PropTypes.string),
    setCurrentTemplate: PropTypes.func,
    step: PropTypes.number,
    setStep: PropTypes.func,
    otherTemplateTitle: PropTypes.string,
    setOtherTemplateTitle: PropTypes.func
};

export default function FormSignUp({
    setCurrentTemplate,
    currentTemplate,
    step,
    setStep,
    setOtherTemplateTitle,
    otherTemplateTitle
}) {
    const location = useLocation();
    const notification = useNotification();
    const { handleSignInDataAndRedirection: signIn } = useUser();

    const [alertName, setAlertName] = useState('');
    const [alertEmail, setAlertEmail] = useState('');
    const [alertPassword, setAlertPassword] = useState('');
    const [alertNumber, setAlertNumber] = useState('');
    const [alertTerms, setAlertTerms] = useState('');

    const [grecaptchaToken, setGrecaptchaToken] = useState();
    const [grecaptchaVerification, setGrecaptchaVerification] = useState(false);

    const handleValidateFormData = (data) => {
        let validated = true;

        if (data.name === '') {
            setAlertName('Introduce un nombre.');
            validated = false;
        }

        if (!isEmail(data.email)) {
            setAlertEmail('Email no válido');
            validated = false;
        }

        if (data.password === '') {
            setAlertPassword('Introduce una contraseña.');
            validated = false;
        }

        return validated;
    };

    const handleSubmit = async (event, data) => {
        event.preventDefault();
        const dataForm = new FormData(event.currentTarget);
        if (data.mobileNumber && !validatePhone(data.mobileNumber.number)) {
            //TODO: make a stronger filter for mobile number, currently just checking if the input is a number
            setAlertNumber('Introduce un número válido.');
            return;
        }

        if (!handleValidateFormData(data)) {
            return;
        }

        // if (!data.useCase) {
        //     setAlertTerms('Indica tu caso de uso, por favor.');
        //     return;
        // }

        if (!data.termsAcceptance) {
            setAlertTerms('Debes de aceptar los términos y condiciones del servicio.');
            return;
        }

        if (!grecaptchaVerification) {
            notification('grecaptcha-robot');
            return;
        }
        console.log('tempaltes', data.templates);
        dataForm.append('federated', false);
        dataForm.append('givenName', data.name);
        dataForm.append('email', data.email);
        dataForm.append('password', data.password);
        dataForm.append('useCase', data.useCase);
        dataForm.append('templates', JSON.stringify(data.templates));
        // ! Legacy
        // if (data.mobileNumber) {
        //     dataForm.append(
        //         'phoneNumber',
        //         `+${data.mobileNumber.countryCode} ${data.mobileNumber.number}`
        //     );
        // }
        const objectData = Object.fromEntries(dataForm.entries());
        try {
            // 1. Creating account
            const userData = await signUp(objectData);
            // 2. Automatically login to Voicit
            signIn(userData, location.hash ? location.hash : '');

            // ! Legacy
            // if (emailsToInvite.length > 0)
            //     await sendUserInvitationToVoicit(
            //         emailsToInvite,
            //         data.email,
            //         'Registro',
            //         userData.authToken
            //     );
            if (!location.hash) {
                // just for users that doesn´t come from chrome store
                window.open(urls.chromePlugin);
            }
        } catch (error) {
            notification(
                error.response.data.message === 'El usuario ya existe'
                    ? 'user-already-exists'
                    : 'sign-up-error'
            );
        }
    };

    const onGrecaptchaVerify = async (token) => {
        try {
            if (!grecaptchaToken) {
                setGrecaptchaToken(token);
                const verification = await grecaptchaVerify(token);
                if (verification.success) {
                    setGrecaptchaVerification(true);
                } else {
                    notification('grecaptcha-robot');
                    return;
                }
            }
        } catch (error) {
            notification('grecaptcha-error');
        }
    };

    return (
        <>
            <FormSignUpView
                handleSubmit={handleSubmit}
                alertName={alertName}
                alertEmail={alertEmail}
                alertPassword={alertPassword}
                alertNumber={alertNumber}
                alertTerms={alertTerms}
                onGrecaptchaVerify={onGrecaptchaVerify}
                handleValidateFormData={handleValidateFormData}
                setCurrentTemplate={setCurrentTemplate}
                currentTemplate={currentTemplate}
                step={step}
                setStep={setStep}
                setOtherTemplateTitle={setOtherTemplateTitle}
                otherTemplateTitle={otherTemplateTitle}
            />
            <GoogleReCaptchaProvider reCaptchaKey={config.google.reCaptchaKey}>
                <GoogleReCaptcha onVerify={(token) => onGrecaptchaVerify(token)} />
            </GoogleReCaptchaProvider>
        </>
    );
}
