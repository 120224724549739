import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Page from '@common/components/Page';
import palette from '@common/theme/palette/palette';

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <Page title='Página no encontrada'>
            <Box sx={{ backgroundColor: palette.primary.main, height: '100vh' }}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    display='flex'
                    alignItems='center'
                    minHeight='75vh'
                >
                    <Grid item>
                        <Typography component={'span'} variant='display1' color='secondary'>
                            404
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component={'span'} variant='display2' color='white'>
                            SORRY, THERE IS
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component={'span'} variant='display2' color='secondary'>
                            NOTHING HERE
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 5 }}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            size='large'
                            disableElevation={true}
                            onClick={() => navigate(`/`)}
                        >
                            BACK
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
};

export default NotFoundPage;
