import { authApiCall, endpoints } from '.';

export const updateSectionTitle = async ({ newTitle, sectionId, reportTemplateId }) => {
    // We have to update authToken in redux store with this new one for admin permissions
    console.log(newTitle, sectionId, reportTemplateId);
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.templateSections,
        path: `/update/title/${sectionId}`,
        data: {
            reportTemplateId,
            sectionTitle: newTitle
        }
    });

    return status;
};

export const updateSectionSubject = async ({ subject, sectionId, reportTemplateId }) => {
    // We have to update authToken in redux store with this new one for admin permissions
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.templateSections,
        path: `/update/subject/${sectionId}`,
        data: {
            reportTemplateId,
            subject
        }
    });

    return status;
};
