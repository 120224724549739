import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import NavigationBar from '@common/components/navigationBar/NavigationBar';

export default function AppBar({ isShared }) {
    return (
        <div style={{ height: '100%' }}>
            <NavigationBar isShared={isShared} />
            <Outlet />
        </div>
    );
}

AppBar.propTypes = {
    isShared: PropTypes.bool
};
