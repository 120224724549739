export const compareWithoutAccentsAndCase = (string01, string02) => {
    if (string01 && string01 !== null && string02 && string02 !== null) {
        const s01 = string01.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const s02 = string02.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return s01.toLowerCase() === s02.toLowerCase();
    } else return false;
};

export const normalizeString = (string) => {
    const noAccents = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return noAccents.toLowerCase();
};
