export const introduceArrayIntoArrayState = ({ setState, currentState, newArray }) => {
    setState([...(currentState || []), ...newArray]);
};

export const introduceOneIntoArrayState = ({ setState, currentState, newOne, inverseOrder }) => {
    if (inverseOrder) {
        setState([...(currentState || []), newOne]);
    } else {
        setState([newOne, ...(currentState || [])]);
    }
};

export const findAndUpdateFieldFromArrayOfObjectsState = ({
    setState,
    currentState,
    compareObjectField,
    compareValue,
    updateObjectField,
    newValue
}) => {
    return new Promise((solve, reject) => {
        let updatedState = currentState;
        const index = currentState.findIndex((s) => {
            return s[compareObjectField] === compareValue;
        });

        if (index) {
            updatedState[index][updateObjectField] = newValue;
            setState(updatedState);
            solve(updatedState[index]);
        } else {
            reject();
        }
    });
};

export const deleteOneFromArrayState = ({ setState, currentState, elementToBeDeleted }) => {
    setState(currentState.filter((element) => element !== elementToBeDeleted));
};

export const deleteOneFromArrayOfObjectsState = ({
    setState,
    currentState,
    ValueFieldName,
    ValueToMatch
}) => {
    setState(currentState.filter((element) => element[ValueFieldName] !== ValueToMatch));
};
