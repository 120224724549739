import React, { forwardRef, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import ItemsSelector from '@common/components/form/ItemsSelector';
import SummaryMockup from '@common/components/SummaryMockup';
import { navigation } from '@common/helpers/navigation';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';
import { createReportTemplate } from '@setup/api/reportTemplates/reportTemplates';

TemplatesLibraryDialog.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function TemplatesLibraryDialog({ openDialog, setOpenDialog }) {
    const navigate = useNavigate();
    const defaultTemplate = 'other';

    const [currentTemplateId, setCurrentTemplateId] = useState(null);
    const [template, setTemplate] = useState([defaultTemplate]);
    const [lastTemplate, setLastTemplate] = useState(
        predefinedTemplates.find((template) => template.id === defaultTemplate)
    );

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCreateTemplate = async () => {
        const selectedTemplate = predefinedTemplates.find((t) => t.id === template[0]);
        const data = {
            name: selectedTemplate.name,
            sections: selectedTemplate.sections.map((s, i) => {
                return {
                    position: i,
                    type: s.type,
                    sectionTitle: s.sectionTitle,
                    subject: s.subject
                };
            })
        };

        const res = await createReportTemplate(data);
        if (res.id) navigate(`/templates/edit/${res.id}`);
    };

    useEffect(() => {
        if (currentTemplateId) {
            setLastTemplate(
                predefinedTemplates.find((template) => template.id === currentTemplateId)
            );
        }
    }, [currentTemplateId]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth={'md'}
            fullWidth={true}
        >
            <DialogTitle>
                Librería de plantillas de informes
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='space-evenly'
                    mt={4}
                >
                    <Grid item xs={5}>
                        <ItemsSelector
                            multipleSelection={false}
                            selectedItemsIds={template}
                            setSelectedItemsIds={setTemplate}
                            setCurrentItem={setCurrentTemplateId}
                            items={predefinedTemplates.filter(
                                (template) => template.id !== 'other'
                            )}
                            spacing={0.5}
                            direction='column'
                        />
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: -3
                        }}
                    >
                        <SummaryMockup title={lastTemplate.name} sections={lastTemplate.sections} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => navigate(navigation.app.routes.newTemplate)}>
                    Continuar sin plantilla
                </Button>
                <Button onClick={handleCreateTemplate} variant='contained' disableElevation={true}>
                    Utilizar plantilla
                </Button>
            </DialogActions>
        </Dialog>
    );
}
