import React from 'react';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import AvatarWithInfo from '@common/components/avatars/AvatarWithInfo';
import IconPhrase from '@common/components/icons/IconPhrase';

SpeakersInfo.propTypes = {
    speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
    interventionPercentages: PropTypes.arrayOf(PropTypes.string)
};

export default function SpeakersInfo({ speakers, interventionPercentages }) {
    return (
        <>
            {speakers ? (
                <>
                    {speakers.map((speaker) => (
                        <div key={speaker.name}>
                            <AvatarWithInfo avatarName={speaker.name} sx={{ mb: 1 }}>
                                <Stack direction='row' spacing={1}>
                                    <Chip
                                        sx={{
                                            flexGrow: 1,
                                            borderRadius: 1,
                                            justifyContent: 'left'
                                        }}
                                        label={
                                            speaker.turn >= 0
                                                ? `Participante ${speaker.turn + 1}`
                                                : 'Turno sin asignar'
                                        }
                                        color={'chip_grey'}
                                        size='small'
                                    />
                                    {interventionPercentages &&
                                    interventionPercentages !== null &&
                                    interventionPercentages[speaker.turn] ? (
                                        <IconPhrase
                                            text={interventionPercentages[speaker.turn]}
                                            icon={
                                                <CircularProgress
                                                    variant='determinate'
                                                    value={Number(
                                                        interventionPercentages[
                                                            speaker.turn
                                                        ].replace('%', '')
                                                    )}
                                                    color={'primary'}
                                                    size={15}
                                                />
                                            }
                                            tooltipText='Porcentaje de intervención'
                                        />
                                    ) : null}
                                </Stack>
                            </AvatarWithInfo>
                        </div>
                    ))}
                </>
            ) : null}
        </>
    );
}
