import { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import {
    generalContentCategory,
    recruitmentCategory,
    specificContentCategory
} from '@common/helpers/templates/templateSectionsByType';
import useWorkspace from '@common/hooks/useWorkspace';
import palette from '@common/theme/palette/palette';
import { getSectionById } from '@setup/api/section/sections';
import TemplateMenuCategorySections from './TemplateMenuCategorySections';
import TemplateMenuSectionConfig from './TemplateMenuSectionConfig';

let SwitchConditional = (props) => {
    const { condition, children } = props;
    return children.find((element) => {
        return element.props.id === condition;
    });
};

TemplateMenuCategories.propTypes = {
    isActive: PropTypes.bool,
    handleSetActive: PropTypes.func.isRequired,
    personalizedSections: PropTypes.any.isRequired,
    handleAddNewSection: PropTypes.func.isRequired,
    handleRemoveSection: PropTypes.func.isRequired,
    handleUpdateSection: PropTypes.func.isRequired,
    sectionIdFromTemplate: PropTypes.number,
    setSectionIdFromTemplate: PropTypes.func.isRequired
};

export default function TemplateMenuCategories({
    personalizedSections,
    handleAddNewSection,
    handleRemoveSection,
    handleUpdateSection,
    sectionIdFromTemplate,
    setSectionIdFromTemplate
}) {
    const userSectionsCategoryName = 'Mis secciones';

    const { workspace } = useWorkspace();

    const [category, setCategory] = useState('');
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [showSectionConfig, setShowSectionConfig] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);

    const handleEditSection = async (sectionId) => {
        const section = await getSectionById(sectionId);
        setCurrentSection(section);
        setShowSectionConfig(true);
    };

    useEffect(() => {
        const showEditionMenu = async () => {
            if (sectionIdFromTemplate) {
                await handleEditSection(sectionIdFromTemplate);
                setCategory(userSectionsCategoryName);
                setSectionIdFromTemplate(undefined);
            }
        };
        showEditionMenu();
    }, [sectionIdFromTemplate]);

    return (
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Grid container direction='row' alignItems='left' spacing={2} display='flex'>
                {!category && !showSectionConfig ? (
                    // TODO: Category value is not being filled in the best way
                    <Grid xs={12} item>
                        {personalizedSections?.userSections?.length > 0 && (
                            <Button
                                variant={'text'}
                                color={'primary'}
                                size={'medium'}
                                endIcon={
                                    <ChevronRightRoundedIcon sx={{ justifyContent: 'flex-end' }} />
                                }
                                disableElevation={true}
                                onClick={() => setCategory(userSectionsCategoryName)}
                                fullWidth={true}
                                sx={{
                                    justifyContent: 'space-between',
                                    paddingRight: '16px', // Ensure there is enough padding on the right for the icon
                                    textAlign: 'left' // Ensure text alignment is left
                                }}
                            >
                                {userSectionsCategoryName}
                            </Button>
                        )}
                        {personalizedSections?.workspaceSections?.length > 0 && (
                            <Button
                                variant={'text'}
                                color={'primary'}
                                size={'medium'}
                                endIcon={
                                    <ChevronRightRoundedIcon sx={{ justifyContent: 'flex-end' }} />
                                }
                                disableElevation={true}
                                onClick={() => setCategory(workspace.name)}
                                fullWidth={true}
                                sx={{
                                    justifyContent: 'space-between',
                                    paddingRight: '16px', // Ensure there is enough padding on the right for the icon
                                    textAlign: 'left' // Ensure text alignment is left
                                }}
                            >
                                {workspace.name}
                            </Button>
                        )}
                        <Button
                            variant={'text'}
                            color={'primary'}
                            size={'medium'}
                            endIcon={
                                <ChevronRightRoundedIcon sx={{ justifyContent: 'flex-end' }} />
                            }
                            disableElevation={true}
                            onClick={() => setCategory(generalContentCategory.name)}
                            fullWidth={true}
                            sx={{
                                justifyContent: 'space-between',
                                paddingRight: '16px', // Ensure there is enough padding on the right for the icon
                                textAlign: 'left' // Ensure text alignment is left
                            }}
                        >
                            {generalContentCategory.name}
                        </Button>
                        <Button
                            variant={'text'}
                            color={'primary'}
                            size={'medium'}
                            endIcon={
                                <ChevronRightRoundedIcon sx={{ justifyContent: 'flex-end' }} />
                            }
                            disableElevation={true}
                            onClick={() => setCategory(specificContentCategory.name)}
                            fullWidth={true}
                            sx={{
                                justifyContent: 'space-between',
                                paddingRight: '16px', // Ensure there is enough padding on the right for the icon
                                textAlign: 'left' // Ensure text alignment is left
                            }}
                        >
                            {specificContentCategory.name}
                        </Button>
                        <Button
                            variant={'text'}
                            color={'primary'}
                            size={'medium'}
                            endIcon={
                                <ChevronRightRoundedIcon sx={{ justifyContent: 'flex-end' }} />
                            }
                            disableElevation={true}
                            onClick={() => setCategory(recruitmentCategory.name)}
                            fullWidth={true}
                            sx={{
                                justifyContent: 'space-between',
                                paddingRight: '16px', // Ensure there is enough padding on the right for the icon
                                textAlign: 'left' // Ensure text alignment is left
                            }}
                        >
                            {recruitmentCategory.name}
                        </Button>
                        <Button
                            variant={'text'}
                            color={'primary'}
                            size={'medium'}
                            startIcon={<AddRoundedIcon sx={{ justifyContent: 'flex-end' }} />}
                            disableElevation={true}
                            onClick={() => setShowSectionConfig(true)}
                            fullWidth={true}
                            sx={{ justifyContent: 'flex-start', flexGrow: 1 }}
                        >
                            Crear sección
                        </Button>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Breadcrumbs aria-label='breadcrumb'>
                                <Link
                                    underline='hover'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    component='button'
                                    color={palette.primary[300]}
                                    onClick={() => {
                                        setCategory('');
                                        setShowSectionConfig(false);
                                        setCurrentSection(null);
                                    }}
                                >
                                    <ArrowBackIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Categorías
                                </Link>
                                {currentSection?.id && (
                                    <Link
                                        underline='hover'
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        component='button'
                                        color={palette.primary[300]}
                                        onClick={() => {
                                            setShowSectionConfig(false);
                                            setCurrentSection(null);
                                        }}
                                    >
                                        {category}
                                    </Link>
                                )}
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color='text.primary'
                                >
                                    {category && !currentSection
                                        ? category
                                        : currentSection
                                        ? currentSection.name
                                        : 'Nueva sección'}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        {category && !currentSection ? (
                            <Grid item xs={12}>
                                <SwitchConditional condition={category}>
                                    <div id={userSectionsCategoryName}>
                                        <TemplateMenuCategorySections
                                            sections={personalizedSections.userSections}
                                            handleEditSection={handleEditSection}
                                            createSection={() => {
                                                setShowSectionConfig(true);
                                                setCategory('');
                                            }}
                                        />
                                    </div>
                                    <div id={workspace.name}>
                                        {/* This category is not updated when a personalized section is updated. */}
                                        <TemplateMenuCategorySections
                                            sections={personalizedSections.workspaceSections}
                                        />
                                    </div>
                                    <div id={specificContentCategory.name}>
                                        <TemplateMenuCategorySections
                                            sections={specificContentCategory.sections}
                                        />
                                    </div>
                                    <div id={generalContentCategory.name}>
                                        <TemplateMenuCategorySections
                                            sections={generalContentCategory.sections}
                                        />
                                    </div>
                                    <div id={recruitmentCategory.name}>
                                        <TemplateMenuCategorySections
                                            sections={recruitmentCategory.sections}
                                        />
                                    </div>
                                </SwitchConditional>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <TemplateMenuSectionConfig
                                    handleNavigateToPersonalizedSections={() => {
                                        setCategory(userSectionsCategoryName);
                                        setCurrentSection(null);
                                    }}
                                    handleAddNewSection={handleAddNewSection}
                                    currentSection={currentSection}
                                    handleRemoveSection={handleRemoveSection}
                                    handleUpdateSection={handleUpdateSection}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                title='Actualiza a Unlimited para activar tu plantilla'
                defaultPlanId={1}
            />
        </Box>
    );
}
