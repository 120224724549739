import { authApiCall, endpoints } from '.';

export async function createCustomTag({ name, color }) {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.customTags,
        path: '/create',
        data: { name, color }
    });

    return {
        customTag: res.data
    };
}

export async function getAllCustomTags() {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.customTags,
        path: '/'
    });

    return {
        customTags: res.data
    };
}

export async function deleteCustomTagById({ tagId }) {
    await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.customTags,
        path: `/delete/${tagId}`
    });
}
