import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import ColorPalette from './ColorPalette';

export default function TagCreationDialog({
    handleCloseTagCreationMenu,
    name,
    color,
    handleOnTitleChange,
    handleOnColorChange,
    alertName,
    alertMessage,
    handleCreateCustomTag
}) {
    return (
        <>
            <Box
                display='flex'
                sx={{
                    backgroundColor: palette[color].contrastText,
                    mt: -1,
                    pt: 2,
                    pb: 2
                }}
            >
                <Chip
                    sx={{ flexGrow: 1, m: 5, borderRadius: 2 }}
                    icon={<LocalOfferIcon fontSize='small' />}
                    label={name}
                    color={color}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mr: 2,
                    ml: 2,
                    mb: 2,
                    mt: 1
                }}
            >
                <TextField
                    autoFocus={true}
                    error={alertName}
                    helperText={alertName ? alertMessage : ''}
                    margin='dense'
                    id='name'
                    label='Título de la etiqueta'
                    type='text'
                    fullWidth
                    variant='standard'
                    value={name}
                    onChange={handleOnTitleChange}
                    sx={{ mb: 2 }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mr: 2,
                    ml: 2,
                    mb: 4,
                    mt: 1
                }}
            >
                <Typography
                    sx={{ mb: 1 }}
                    component='span'
                    variant='subtitle1'
                    color={palette.primary[300]}
                >
                    Color
                </Typography>
                <ColorPalette handleOnColorChange={handleOnColorChange} color={color} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    mr: 2,
                    ml: 2,
                    mt: 1
                }}
            >
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    disableElevation={true}
                    onClick={handleCreateCustomTag}
                    sx={{ mb: 1 }}
                >
                    Crear etiqueta
                </Button>
                <Button
                    variant='text'
                    color='primary'
                    size='small'
                    disableElevation={true}
                    onClick={handleCloseTagCreationMenu}
                    sx={{ mb: 2 }}
                >
                    Cancelar
                </Button>
            </Box>
        </>
    );
}

TagCreationDialog.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    handleCloseTagCreationMenu: PropTypes.func.isRequired,
    handleOnTitleChange: PropTypes.func.isRequired,
    handleOnColorChange: PropTypes.func.isRequired,
    alertName: PropTypes.bool.isRequired,
    alertMessage: PropTypes.string.isRequired,
    handleCreateCustomTag: PropTypes.func.isRequired
};
