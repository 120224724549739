const turns = [
    {
        value: -1,
        string: 'Desconocido'
    },
    {
        value: 0,
        string: 'Primero'
    },
    {
        value: 1,
        string: 'Segundo'
    },
    {
        value: 2,
        string: 'Tercero'
    },
    {
        value: 3,
        string: 'Cuarto'
    },
    {
        value: 4,
        string: 'Quinto'
    },
    {
        value: 5,
        string: 'Sexto'
    },
    {
        value: 6,
        string: 'Séptimo'
    }
];

module.exports = {
    turns
};
