import React, { useState } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { Chip, Fab, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ItemButtonSelector from '@common/components/ItemButtonSelector';
import Loader from '@common/components/loaders/Loader';
import LoaderEmptyState from '@common/components/loaders/LoaderEmptyState';
import shadows from '@common/theme/palette/shadows';
import Intervention from './Intervention';

export default function TranscriptionView({
    meeting,
    showInterDetails,
    handleChargeMore,
    loadingSpeakers,
    handleUpdateIntervention,
    handleDeleteIntervention,
    handleUpdateSpeakersContent,
    interventions,
    scrollY,
    speakersList,
    setFilterSpeakerTag,
    filterSpeakerTag,
    isShared,
    isDirectUpload,
    currentAudioTime,
    setCurrentAudioTime
}) {
    const [currentAudioInterventionTimeKey, setAudioInterventionTimeKey] = useState(null);
    const handleSelectFilter = (chip) => {
        setFilterSpeakerTag(chip.label);
    };

    const handleUnselectFilter = () => {
        setFilterSpeakerTag('');
    };

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            mb: 3
                        }}
                    >
                        {/* <Grid item sx={{ flexGrow: 1 }} /> */}
                        {speakersList && speakersList !== null ? (
                            <Grid item>
                                <ItemButtonSelector
                                    iconButton={<FilterListOutlinedIcon />}
                                    itemsList={speakersList}
                                    onItemSelection={handleSelectFilter}
                                    tooltipLabel='Filtrar por participante'
                                />
                            </Grid>
                        ) : null}
                        {filterSpeakerTag ? (
                            <Grid inputMode=''>
                                <Chip
                                    sx={{
                                        mr: 2
                                    }}
                                    key={filterSpeakerTag}
                                    label={filterSpeakerTag}
                                    size='small'
                                    onDelete={handleUnselectFilter}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>

                <Grid item alignItems='left'>
                    {interventions ? (
                        <>
                            {interventions.map((intervention, index) => (
                                <div key={index}>
                                    <Intervention
                                        intervention={intervention}
                                        creationDate={meeting.createdAt}
                                        duration={meeting.duration}
                                        showInterDetails={showInterDetails}
                                        meetingId={meeting.id}
                                        handleUpdateSpeakersContent={handleUpdateSpeakersContent}
                                        speakers={meeting.speakers}
                                        handleDeleteIntervention={handleDeleteIntervention}
                                        handleUpdateIntervention={handleUpdateIntervention}
                                        isShared={isShared}
                                        isDirectUpload={isDirectUpload}
                                        speakersInterventionPercentages={
                                            meeting.speakersInterventionPercentages
                                        }
                                        currentAudioTime={currentAudioTime}
                                        setCurrentAudioTime={setCurrentAudioTime}
                                        setAudioInterventionTimeKey={setAudioInterventionTimeKey}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <LoaderEmptyState
                            title='Estamos transcribiendo tu reunión'
                            explanation='Enviaremos un email a tu correo cuando el proceso termine. Recarga la página para actualizar su estado.'
                        />
                    )}
                </Grid>

                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 1
                    }}
                    spacing={1}
                >
                    {scrollY >= 200 ? (
                        <>
                            <Fab
                                variant='extended'
                                onClick={() => window.scrollTo(0, 0)}
                                color='floating_button'
                                sx={{
                                    boxShadow: shadows.elevated,
                                    display: 'flex',
                                    // Display button to the bottom right
                                    position: 'fixed',
                                    right: '2em',
                                    bottom: '2em'
                                }}
                            >
                                <ExpandLessOutlinedIcon sx={{ mr: 1 }} />
                                Volver arriba
                            </Fab>
                            {currentAudioInterventionTimeKey ? (
                                <Fab
                                    variant='extended'
                                    onClick={() => {
                                        const element = document.getElementById(
                                            `${currentAudioInterventionTimeKey}`
                                        );
                                        if (element) {
                                            element.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'center',
                                                inline: 'nearest'
                                            });
                                        }
                                    }}
                                    color='floating_button'
                                    sx={{
                                        boxShadow: shadows.elevated,
                                        display: 'flex',
                                        // Display button to the bottom right
                                        position: 'fixed',
                                        right: '2em',
                                        bottom: '2em',
                                        mr: 20
                                    }}
                                >
                                    <SyncOutlinedIcon sx={{ mr: 1 }} />
                                    Sincronizar con audio
                                </Fab>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {loadingSpeakers ? (
                                <Loader minHeight='8vh' size='24px' />
                            ) : (
                                <Fab
                                    onClick={handleChargeMore}
                                    aria-label='show-more'
                                    color='floating_button'
                                    sx={{ boxShadow: shadows.elevated, mt: 5 }}
                                >
                                    <ExpandMoreOutlinedIcon />
                                </Fab>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

TranscriptionView.propTypes = {
    meeting: PropTypes.object.isRequired,
    showInterDetails: PropTypes.bool.isRequired,
    handleChargeMore: PropTypes.func.isRequired,
    loadingSpeakers: PropTypes.bool.isRequired,
    handleUpdateIntervention: PropTypes.func.isRequired,
    handleDeleteIntervention: PropTypes.func.isRequired,
    handleUpdateSpeakersContent: PropTypes.func.isRequired,
    interventions: PropTypes.arrayOf(PropTypes.object).isRequired,
    scrollY: PropTypes.number.isRequired,
    speakersList: PropTypes.arrayOf(PropTypes.object).isRequired,
    setFilterSpeakerTag: PropTypes.func.isRequired,
    filterSpeakerTag: PropTypes.string,
    setInterventions: PropTypes.func.isRequired,
    isShared: PropTypes.bool,
    isDirectUpload: PropTypes.bool,
    currentAudioTime: PropTypes.number.isRequired,
    setCurrentAudioTime: PropTypes.func.isRequired
};
