import { authApiCall, endpoints } from '..';

export async function getSharedPaginatedMeetingInterventions({
    meetingId,
    page,
    pageSize,
    authToken
}) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMeetingInterventions,
        path: `/paginated/${meetingId}/${page}/${pageSize}`,
        authToken
    });

    return {
        interventions: res.data.data,
        count: res.data.count
    };
}

export async function getSharedFirstSpeaker({ meetingId, authToken }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMeetingInterventions,
        path: `/paginated/${meetingId}/${0}/${1}`,
        authToken
    });

    return {
        speaker: res.data.data[0].speaker
    };
}

export async function getSharedPaginatedMeetingInterventionsBySpeaker({
    meetingId,
    page,
    pageSize,
    filterSpeakerTag,
    authToken
}) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMeetingInterventions,
        path: `/paginated/${meetingId}/${page}/${pageSize}`,
        params: {
            speaker: filterSpeakerTag
        },
        authToken
    });

    return {
        interventions: res.data.data,
        count: res.data.count
    };
}

export async function getSharedMeetingInterventionSpeakersWithTurn(meetingId, authToken) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMeetingInterventions,
        path: `/speakers/turns/${meetingId}`,
        authToken
    });

    return res.data;
}
