import { authApiCall, endpoints } from '.';

export async function getUser() {
    // userId is contained into the authToken
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.user,
        path: '/'
    });

    return data;
}

export async function getUserPlan(authToken) {
    // it is not necessary to pass always the authToken because we add it when the user is already signed in
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.user,
        path: '/plan',
        authToken
    });

    return data;
}

export async function invitateToVoicit({ to, toName, content, authToken }) {
    const { status } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.user,
        path: '/send-invitation',
        data: { to, toName, content },
        authToken
    });

    return status;
}

export async function updateAvatarUrl({ filename }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.user,
        path: '/avatar-url',
        data: { filename }
    });

    return { avatarUrl: res.data };
}

export async function updateUserPassword({ password, newPassword }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.user,
        path: '/change-password',
        data: { password, newPassword }
    });

    return { updatedPasswordStatus: res.status };
}

export async function updateUserName({ givenName }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.user,
        path: '/change-name',
        data: { givenName }
    });

    return { updateUserNameStatus: res.status };
}

export async function updateUserData(data) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.user,
        path: '/update',
        data: data
    });

    return { updateUserStatus: res.status };
}

export async function deleteUserAccount({ password }) {
    const { status } = await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.user,
        path: '/',
        data: { password }
    });
    return status;
}
