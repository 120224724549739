import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Loader from '@common/components/loaders/Loader';
import palette from '@common/theme/palette/palette';
import { getAllMeetingInterventions } from '@setup/api/meetingInterventions/meetingInterventions';

export default function OldTranscriptions({ meeting }) {
    const [transcription, setTranscription] = useState(null);

    const handleGetLines = async () => {
        const { interventions, interventionsStatus } = await getAllMeetingInterventions({
            meetingId: meeting.id
        });

        if (interventionsStatus === 200) {
            setTranscription(interventions[0].lines.split('.'));
        } else {
            console.log('Error fetching sepakers');
        }
    };

    useEffect(() => {
        handleGetLines();
    }, []);

    if (transcription) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Grid container direction='column' alignItems='left' spacing={1} sx={{ mt: 2 }}>
                    <Grid justifyContent={'left'} item xs zeroMinWidth>
                        {transcription.map((paragraph, index) => (
                            <div key={index}>
                                <Typography
                                    variant='body1'
                                    sx={{ mt: 1, textAlign: 'left' }}
                                    color={palette.secondary.dark}
                                >
                                    {paragraph}
                                    {'.'}
                                </Typography>
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        return (
            <Box sx={{ width: '100%' }}>
                <Grid container direction='column' alignItems='left' spacing={1}>
                    <Loader />
                </Grid>
            </Box>
        );
    }
}

OldTranscriptions.propTypes = {
    meeting: PropTypes.object.isRequired
};
