import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';

PasswordField.propTypes = {
    alertMessage: PropTypes.string,
    password: PropTypes.string,
    setPassword: PropTypes.func,
    label: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.string
};

export default function PasswordField({ alertMessage, password, setPassword, label, id, variant }) {
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            error={alertMessage ? true : false}
            helperText={alertMessage}
            margin='normal'
            size='small'
            required
            fullWidth
            name={id ? id : 'password'}
            label={label ? label : 'Contraseña'}
            type={showPassword ? 'text' : 'password'}
            id={id ? id : 'password'}
            onChange={handleChange}
            value={password}
            autoComplete={id ? id : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='start' id='current-password'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            variant={variant ? variant : 'standard'}
        />
    );
}
