export function handleApiCallErrorNotification({ status, notification, message }) {
    switch (status) {
        case 400:
            notification(message);
            break;
        case 401:
            notification('unauthorized');
            break;
        case 403:
            notification('forbidden');
            break;
        case 404:
            notification('not-found');
            break;
        case 500:
            notification('server-error');
            break;
        default:
            notification('unkown-error');
            break;
    }
}
