import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { PropTypes } from 'prop-types';
import IconPhrase from '@common/components/icons/IconPhrase';
import palette from '@common/theme/palette/palette';

HorizontalCard.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.node,
    link: PropTypes.string,
    linkText: PropTypes.string,
    onClick: PropTypes.func
};

export default function HorizontalCard({ text, icon, link, linkText, onClick }) {
    return (
        <Card
            variant='outlined'
            sx={{
                display: 'flex',
                ':hover': {
                    border: '1px solid',
                    borderColor: palette.primary.main
                },
                cursor: link || onClick ? 'pointer' : 'default'
            }}
            onClick={
                onClick ? onClick : linkText || !link ? null : () => window.open(link, '_blank')
            }
        >
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Grid item>
                        <IconPhrase text={text} icon={icon} />
                    </Grid>
                    {linkText ? (
                        <Grid item>
                            <Grid item>
                                <Link
                                    component='button'
                                    variant='body2'
                                    underline='hover'
                                    color={palette.primary[900]}
                                    onClick={() => window.open(link, '_blank')}
                                >
                                    {linkText}
                                </Link>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </CardContent>
        </Card>
    );
}
