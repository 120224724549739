import { DEFAULT_TEMPLATE_SECTIONS } from './defaultTemplateSections';

const generalContentSections = [
    {
        id: 'metadata',
        props: DEFAULT_TEMPLATE_SECTIONS.metadata,
        description: 'Participantes, plataforma y duración'
    },
    {
        id: 'summary',
        props: DEFAULT_TEMPLATE_SECTIONS.summary,
        description: 'Resumen general de la reunión'
    },
    {
        id: 'chronological_summary',
        props: DEFAULT_TEMPLATE_SECTIONS.chronological_summary,
        description: 'Resúmenes con título de las distintas partes de la conversación'
    },
    {
        id: 'index',
        props: DEFAULT_TEMPLATE_SECTIONS.index,
        description: 'Lista de los temas principales discutidos'
    }
];

const specificContentSections = [
    {
        id: 'pains',
        props: DEFAULT_TEMPLATE_SECTIONS.pains,
        description: 'Lista de dificultades comentadas'
    },
    {
        id: 'metrics',
        props: DEFAULT_TEMPLATE_SECTIONS.metrics,
        description: 'Lista de datos numéricos que se han comentado'
    },
    {
        id: 'dates',
        props: DEFAULT_TEMPLATE_SECTIONS.dates,
        description: 'Fechas comentadas durante la reunión'
    },
    {
        id: 'next_meeting_items',
        props: DEFAULT_TEMPLATE_SECTIONS.next_meeting_items,
        description: 'Lista de sugerencias de puntos a discutir en la siguiente reunión'
    }
];

const recruitmentSections = [
    {
        id: 'education',
        props: DEFAULT_TEMPLATE_SECTIONS.education,
        description: 'Historial educativo del candidato'
    },
    {
        id: 'work_experience',
        props: DEFAULT_TEMPLATE_SECTIONS.work_experience,
        description: 'Experiencia laboral del candidato en orden cronológico'
    },
    {
        id: 'specific_competence',
        props: DEFAULT_TEMPLATE_SECTIONS.specific_competence,
        description: 'Añade una competencia específica para ser evaluada.'
    },
    {
        id: 'soft_skills',
        props: DEFAULT_TEMPLATE_SECTIONS.soft_skills,
        description: 'Competencias que el candidato ha mencionado.'
    },
    {
        id: 'hard_skills',
        props: DEFAULT_TEMPLATE_SECTIONS.hard_skills,
        description: 'Competencias técnicas que han sido mencionadas.'
    },
    {
        id: 'job_change_reasons',
        props: DEFAULT_TEMPLATE_SECTIONS.job_change_reasons,
        description: 'Motivos por los cuales el candidato está considerando cambiar de trabajo'
    },
    {
        id: 'job_expectations',
        props: DEFAULT_TEMPLATE_SECTIONS.job_expectations,
        description: 'Expectativas del candidato respecto a la posición'
    },
    {
        id: 'salary',
        props: DEFAULT_TEMPLATE_SECTIONS.salary,
        description: 'Rango salarial esperado por el candidato'
    },
    {
        id: 'languages',
        props: DEFAULT_TEMPLATE_SECTIONS.languages,
        description: 'Nivel de idiomas, certificaciones o nivel hablado y escrito'
    },
    {
        id: 'availability_mobility',
        props: DEFAULT_TEMPLATE_SECTIONS.availability_mobility,
        description: 'Disposición del candidato a mudarse o viajar por la oferta de trabajo'
    },
    {
        id: 'availability_incorporation',
        props: DEFAULT_TEMPLATE_SECTIONS.availability_incorporation,
        description: 'Fecha en la que el candidato está disponible para comenzar en la posición'
    },
    {
        id: 'availability_interviews',
        props: DEFAULT_TEMPLATE_SECTIONS.availability_interviews,
        description: 'Disponibilidad del candidato para participar en más entrevistas'
    }
    // {
    //     id: 'job_requeriments',
    //     props: DEFAULT_TEMPLATE_SECTIONS.job_requeriments,
    //     description:
    //         'Características, aptitudes y cualidades sobre el perfil de candidato que se busca para la posición'
    // }
];

// ! Deprecated
const salesSections = [
    {
        id: 'decision_criteria',
        props: DEFAULT_TEMPLATE_SECTIONS.decision_criteria,
        description: 'Conclusión basada en competidores, fechas y métricas'
    },
    {
        id: 'decision_process',
        props: DEFAULT_TEMPLATE_SECTIONS.decision_process,
        description: 'Conclusión basada en tiempos y responsables de compra'
    },
    {
        id: 'purchase_decision_metrics',
        props: DEFAULT_TEMPLATE_SECTIONS.purchase_decision_metrics,
        description: 'Lista de métricas a analizar para decidir'
    },
    {
        id: 'implementation_date',
        props: DEFAULT_TEMPLATE_SECTIONS.implementation_date,
        description: 'Lista de fechas en las que se desea introducir la solución'
    },
    {
        id: 'competitors',
        props: DEFAULT_TEMPLATE_SECTIONS.competitors,
        description: 'Lista de aspectos sobre competidores mencionados'
    },
    {
        id: 'decision_time',
        props: DEFAULT_TEMPLATE_SECTIONS.decision_time,
        description: 'Lista sobre el tiempo estimado para tomar una decisión'
    },
    {
        id: 'decisors',
        props: DEFAULT_TEMPLATE_SECTIONS.decisors,
        description: 'Lista de personas responsables de la compra'
    }
];

export const generalContentCategory = {
    id: 'generalContentCategory',
    name: 'Contenido general',
    sections: generalContentSections
};

export const specificContentCategory = {
    id: 'specificContentCategory',
    name: 'Contenido específico',
    sections: specificContentSections
};
export const recruitmentCategory = {
    id: 'recruitmentCategory',
    name: 'Entrevista de selección',
    sections: recruitmentSections
};

// ! Deprecated
export const salesCategory = {
    id: 'salesCategory',
    name: 'Reunión de ventas',
    sections: salesSections
};
