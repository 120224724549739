import CloseIcon from '@mui/icons-material/Close';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import DragDropFile from './dragDropFile/DragDropFile';
import { DialogTransition } from './utils';

CreateMeetingMethodSelection.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleOnFileChange: PropTypes.func.isRequired,
    handleNavigateToRecorder: PropTypes.func.isRequired,
    filename: PropTypes.string,
    alertFile: PropTypes.bool,
    alertMessage: PropTypes.string
};

export default function CreateMeetingMethodSelection({
    handleClose,
    filename,
    alertFile,
    alertMessage,
    handleOnFileChange,
    handleNavigateToRecorder
}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={DialogTransition}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle sx={{ mb: 2 }}>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DragDropFile
                    handleFile={handleOnFileChange}
                    filename={filename}
                    alertFile={alertFile}
                    alertMessage={alertMessage}
                />
                <Button
                    type='submit'
                    fullWidth
                    variant='outlined'
                    onClick={handleNavigateToRecorder}
                    sx={{ mt: 2 }}
                    disableElevation={true}
                    startIcon={<MicNoneOutlinedIcon />}
                >
                    Grabar desde Voicit
                </Button>
            </DialogContent>
        </Dialog>
    );
}
