import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { PropTypes } from 'prop-types';
import IconButtonWithTooltip from './buttons/IconButtonWithTooltip';

MenuButton.propTypes = {
    menuList: PropTypes.arrayOf(PropTypes.object),
    iconButton: PropTypes.any,
    menuWidth: PropTypes.func,
    size: PropTypes.string,
    sx: PropTypes.any,
    tooltipText: PropTypes.string,
    id: PropTypes.string
};

export default function MenuButton(props) {
    const { menuList, iconButton, menuWidth, tooltipText, size, sx } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [index, setIndex] = useState('');
    const open = Boolean(anchorEl);
    const width = menuWidth ? '100%' : menuWidth;

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setLoading(false);
        setIndex('');
    };

    const handleFunction = async (func, itemIndex) => {
        try {
            setLoading(true);
            setIndex(itemIndex);
            await func();
            setLoading(false);
            handleClose();
            setIndex('');
        } catch (error) {
            setLoading(false);
            setIndex('');
        }
    };

    return (
        <div>
            {tooltipText ? (
                <IconButtonWithTooltip
                    id={props.id}
                    icon={iconButton}
                    tooltipText={tooltipText}
                    onClick={handleClick}
                    size={size}
                    sx={sx}
                />
            ) : (
                <IconButton
                    id={props.id}
                    aria-label='more'
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={handleClick}
                    sx={{
                        borderRadius: '4px',
                        border: '0px solid'
                    }}
                >
                    {iconButton}
                </IconButton>
            )}

            <Menu
                id={props.id}
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 'auto',
                        width: width
                    }
                }}
            >
                <MenuList>
                    {menuList.map((menuItem, i) => (
                        <div key={i}>
                            {menuItem.divider ? null : ( // <Divider />
                                <>
                                    <MenuItem
                                        id={menuItem.itemClickId}
                                        key={i}
                                        onClick={
                                            menuItem.itemFunction
                                                ? () =>
                                                      handleFunction(
                                                          menuItem.itemFunction,
                                                          menuItem.itemText
                                                      )
                                                : () =>
                                                      menuItem.subMenuCollapseFunc(
                                                          !menuItem.subMenuCollapsed
                                                      )
                                        }
                                        disabled={menuItem.disabled ? menuItem.disabled : false}
                                    >
                                        {menuItem.itemIcon ? (
                                            <ListItemIcon key={`icon-${i}`}>
                                                {loading && index === menuItem.itemText ? (
                                                    <CircularProgress color='inherit' size={16} />
                                                ) : (
                                                    menuItem.itemIcon
                                                )}
                                            </ListItemIcon>
                                        ) : null}
                                        <ListItemText id={props.id} key={`text-${i}`}>
                                            {menuItem.itemText}
                                        </ListItemText>
                                        {menuItem.itemCollapse ? (
                                            menuItem.subMenuCollapsed ? (
                                                <ExpandLess sx={{ ml: 1 }} />
                                            ) : (
                                                <ExpandMore sx={{ ml: 1 }} />
                                            )
                                        ) : null}
                                    </MenuItem>
                                    {menuItem.itemCollapse && menuItem.subMenuCollapsed ? (
                                        <>
                                            <Collapse
                                                in={menuItem.subMenuCollapsed}
                                                timeout='auto'
                                                unmountOnExit
                                            >
                                                {menuItem.subMenu.map((subMenuItem, i) => (
                                                    <div key={`submenu-${i}`}>
                                                        <MenuItem
                                                            key={`submenu-${i}`}
                                                            onClick={() =>
                                                                handleFunction(
                                                                    subMenuItem.itemFunction,
                                                                    subMenuItem.itemText
                                                                )
                                                            }
                                                            sx={{ pl: 4 }}
                                                        >
                                                            <ListItemIcon key={`submenu-icon-${i}`}>
                                                                {loading &&
                                                                index === subMenuItem.itemText ? (
                                                                    <CircularProgress
                                                                        color='inherit'
                                                                        size={16}
                                                                    />
                                                                ) : (
                                                                    subMenuItem.itemIcon
                                                                )}
                                                            </ListItemIcon>
                                                            <ListItemText key={`submenu-text-${i}`}>
                                                                {loading &&
                                                                index === subMenuItem.itemText
                                                                    ? 'Cargando...'
                                                                    : subMenuItem.itemText}
                                                            </ListItemText>
                                                        </MenuItem>
                                                    </div>
                                                ))}
                                            </Collapse>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                    ))}
                </MenuList>
            </Menu>
        </div>
    );
}
