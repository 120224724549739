import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '@common/components/editableComponents/StrictModeDroppable';
import TemplateSection from '../../TemplateSection';

TemplateMenuCategorySections.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleEditSection: PropTypes.func.isRequired,
    createSection: PropTypes.func.isRequired
};

export default function TemplateMenuCategorySections({
    sections,
    handleEditSection,
    createSection
}) {
    return (
        <StrictModeDroppable droppableId='selectable-default-blocks'>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {createSection && (
                        <Button
                            variant={'text'}
                            color={'primary'}
                            size={'medium'}
                            startIcon={<AddRoundedIcon sx={{ justifyContent: 'flex-end' }} />}
                            disableElevation={true}
                            onClick={createSection}
                            fullWidth={true}
                            sx={{ justifyContent: 'flex-start', flexGrow: 1, mb: 2 }}
                        >
                            Crear sección
                        </Button>
                    )}
                    {sections.map((section, i) => {
                        return (
                            <Draggable
                                draggableId={`${section.id}-default`}
                                index={i}
                                key={`item-${i}-default`}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <TemplateSection
                                            section={section.props}
                                            description={section.description}
                                            hoverEnabled={true}
                                            handleEditSection={handleEditSection}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </StrictModeDroppable>
    );
}
