import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import InformationAlert from '@common/components/alerts/InformationAlert';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import { StrictModeDroppable } from '@common/components/editableComponents/StrictModeDroppable';
import { urls } from '@common/helpers/urls';
import TemplateSection from './TemplateSection';

TemplateDragDropSections.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object),
    onDelete: PropTypes.func,
    templateId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    setSectionIdFromTemplate: PropTypes.func.isRequired
};

export default function TemplateDragDropSections({
    sections,
    onDelete,
    templateId,
    onChange,
    setSectionIdFromTemplate
}) {
    return (
        <>
            {sections && (
                <StrictModeDroppable droppableId='selected-template-sections'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {sections.map((section, i) => {
                                return (
                                    <Draggable draggableId={String(i)} index={i} key={`item-${i}`}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Grid display='flex' alignItems='center' container>
                                                    <Grid xs={1} sx={{ mb: 2 }} item>
                                                        <div
                                                            style={{ opacity: 1 }}
                                                            className='dragHandle'
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <IconButtonWithTooltip
                                                                icon={
                                                                    <DragIndicatorOutlinedIcon
                                                                        fontSize='medium'
                                                                        sx={{
                                                                            cursor: 'grab'
                                                                        }}
                                                                    />
                                                                }
                                                                onClick={() => {
                                                                    return;
                                                                }}
                                                                tooltipText={'Arrastra para mover'}
                                                                size={'small'}
                                                                sx={{
                                                                    borderRadius: '4px',
                                                                    border: '0px solid'
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid xs={11} item>
                                                        <TemplateSection
                                                            position={i}
                                                            section={section}
                                                            onDelete={() => onDelete(i)}
                                                            templateId={templateId}
                                                            onChange={onChange}
                                                            setSectionIdFromTemplate={
                                                                setSectionIdFromTemplate
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            )}
            {sections?.length < 1 && (
                <InformationAlert message='👋 Arrastra los bloques de contenido que quieras para generar de tu resumen' />
            )}
            <Grid container direction={'row'} sx={{ mt: 5, mb: 5 }}>
                <Grid item sx={{ mr: 2 }}>
                    <Button
                        variant='text'
                        startIcon={<OndemandVideoRoundedIcon fontSize='small' />}
                        onClick={() => window.open(urls.tutorials.templates)}
                    >
                        Cómo personalizar mi plantilla
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='text'
                        startIcon={<CampaignOutlinedIcon fontSize='small' />}
                        onClick={() => window.open(urls.forms.templates)}
                    >
                        Haznos saber qué tipos de reuniones tienes
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
