import { forwardRef } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
    <div style={{ height: '100%' }}>
        <Helmet>
            <title>{`${title} | Voicit`}</title>
            {meta}
        </Helmet>

        <Box ref={ref} {...other} style={{ height: '100%' }}>
            {children}
        </Box>
    </div>
));

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.node
};

Page.displayName = 'Page';
export default Page;
