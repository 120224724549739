import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { DEFAULT_TEMPLATE_SECTIONS } from './defaultTemplateSections';

const salesTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.metrics,
    DEFAULT_TEMPLATE_SECTIONS.dates,
    DEFAULT_TEMPLATE_SECTIONS.next_meeting_items
    // DEFAULT_TEMPLATE_SECTIONS.decision_process,
    // DEFAULT_TEMPLATE_SECTIONS.decision_time,
    // DEFAULT_TEMPLATE_SECTIONS.decisors,
    // DEFAULT_TEMPLATE_SECTIONS.decision_criteria,
    // DEFAULT_TEMPLATE_SECTIONS.competitors,
    // DEFAULT_TEMPLATE_SECTIONS.implementation_date,
    // DEFAULT_TEMPLATE_SECTIONS.purchase_decision_metrics,
    // DEFAULT_TEMPLATE_SECTIONS.pains,
    // DEFAULT_TEMPLATE_SECTIONS.questions,
];

const teamTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.pains,
    // DEFAULT_TEMPLATE_SECTIONS.tasks,
    // DEFAULT_TEMPLATE_SECTIONS.pains,
    // DEFAULT_TEMPLATE_SECTIONS.metrics,
    DEFAULT_TEMPLATE_SECTIONS.chronological_summary
];

const clientTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.pains,
    DEFAULT_TEMPLATE_SECTIONS.dates,
    DEFAULT_TEMPLATE_SECTIONS.metrics,
    // DEFAULT_TEMPLATE_SECTIONS.questions,
    // DEFAULT_TEMPLATE_SECTIONS.next_meeting_items,
    DEFAULT_TEMPLATE_SECTIONS.chronological_summary
];

const hrScreeningInterviewTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.education,
    DEFAULT_TEMPLATE_SECTIONS.work_experience,
    DEFAULT_TEMPLATE_SECTIONS.soft_skills,
    DEFAULT_TEMPLATE_SECTIONS.job_change_reasons,
    DEFAULT_TEMPLATE_SECTIONS.salary,
    DEFAULT_TEMPLATE_SECTIONS.availability_incorporation
];

const hrClientJobRequerimentsTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.job_requeriments,
    // DEFAULT_TEMPLATE_SECTIONS.questions,
    DEFAULT_TEMPLATE_SECTIONS.chronological_summary
];

const trainingTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.dates,
    DEFAULT_TEMPLATE_SECTIONS.chronological_summary
];

const defaultTemplateSections = [
    DEFAULT_TEMPLATE_SECTIONS.summary,
    DEFAULT_TEMPLATE_SECTIONS.index,
    DEFAULT_TEMPLATE_SECTIONS.chronological_summary
];

const predefinedTemplates = [
    {
        id: 'hiring',
        name: 'Entrevista de Selección',
        description: 'Extrae información de tus entrevistas de selección',
        icon: <PeopleAltOutlinedIcon />,
        sections: hrScreeningInterviewTemplateSections
    },
    {
        id: 'hiring-clients',
        name: 'Toma de Requerimientos del Candidato',
        description:
            'Captura todos los detalles que tu cliente te ha proporcionado sobre el perfil de candidato que busca para la posición vacante',
        icon: <PeopleAltOutlinedIcon />,
        sections: hrClientJobRequerimentsTemplateSections
    },
    {
        id: 'sales',
        name: 'Reunión de Ventas',
        description: 'Extrae información de tus potenciales clientes',
        icon: <WorkOutlineOutlinedIcon />,
        sections: salesTemplateSections
    },
    {
        id: 'team',
        name: 'Reunión de Equipo',
        description: 'Revive las reuniones internas de tu empresa',
        icon: <WorkspacesOutlinedIcon />,
        sections: teamTemplateSections
    },
    {
        id: 'clients',
        name: 'Seguimiento de Cliente',
        description: 'Haz un seguimiento de tus actuales clientes',
        icon: <GroupsOutlinedIcon />,
        sections: clientTemplateSections
    },
    {
        id: 'training',
        name: 'Formación',
        description: 'Recuerda el contenido de tu formación rápidamente',
        icon: <SchoolOutlinedIcon />,
        sections: trainingTemplateSections
    },
    {
        id: 'other',
        name: 'Otro',
        description: 'Para todo tipo de reuniones',
        icon: <NotListedLocationOutlinedIcon />,
        sections: defaultTemplateSections
    }
];

export default predefinedTemplates;
