import React, { useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import DialogButton from '@common/components/dialogs/DialogButton';
import Paragraph from '@common/components/Paragraph';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { deleteOneFromArrayState, introduceArrayIntoArrayState } from '@common/utils/useState';
import {
    createMicroSummaryKeyWordsById,
    deleteMicroSummaryKeyWordById
} from '@setup/api/microSummaries/microSummaries';
import EditionFloatingMenu from '../EditionFloatingMenu';

export default function MicroSummary({
    microSummary,
    isShared,
    handleUpdateMicroSummary,
    handleDeleteMicroSummary,
    meetingId
}) {
    const notification = useNotification();

    const [showActions, setShowActions] = useState(false);
    const [onInterventionEdit, setOnInterventionEdit] = useState(false);
    const [titleValue, setTitleValue] = useState(microSummary.title);
    const [contentValue, setContentValue] = useState(microSummary.content);
    const [KeyWords, setKeyWords] = useState(microSummary.keyWords);
    const [newKeyWordsValue, setNewKeyWordsValue] = useState(microSummary.keyWords);
    const [alertKeyWords, setAlertKeyWords] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const microSummaryValue = microSummary.title + microSummary.content;

    const handleChangeTitle = (event) => {
        setTitleValue(event.target.value);
    };
    newKeyWordsValue;

    const handleChangeContent = (event) => {
        setContentValue(event.target.value);
    };
    const handleCloseInterventionEdition = () => {
        setTitleValue(microSummary.title);
        setContentValue(microSummary.content);
        setShowActions(false);
        setOnInterventionEdit(false);
    };

    const onClickSave = async () => {
        await handleUpdateMicroSummary(microSummary.id, titleValue, contentValue);
        setShowActions(false);
        setOnInterventionEdit(false);
    };

    const handleChangeNewKeyWords = (event) => {
        setNewKeyWordsValue(event.target.value);
    };

    const handleUpdateKeyWords = async () => {
        try {
            const newKeyWords = newKeyWordsValue.split(',');
            introduceArrayIntoArrayState({
                setState: setKeyWords,
                currentState: KeyWords,
                newArray: newKeyWords
            });
            await createMicroSummaryKeyWordsById({
                id: microSummary.id,
                keyWords: newKeyWords,
                meetingId
            });
            return true;
        } catch (error) {
            setAlertKeyWords(true);
            setAlertMessage('Error creando palabras clave, vuelve a intentarlo.');
        }
    };

    const handleDeleteKeyWord = async (keyWord) => {
        try {
            deleteOneFromArrayState({
                setState: setKeyWords,
                currentState: KeyWords,
                elementToBeDeleted: keyWord
            });
            await deleteMicroSummaryKeyWordById({ id: microSummary.id, meetingId, keyWord });
        } catch (error) {
            notification('delete-keyword-error');
        }
    };

    const handleCloseDialog = () => {
        setAlertKeyWords(false);
        setAlertMessage('');
    };

    return (
        <Grid
            container
            sx={{ cursor: isShared ? 'default' : 'pointer' }}
            onMouseLeave={() => setShowActions(false)}
            onClick={isShared ? null : () => setShowActions(true)}
        >
            {showActions ? (
                <EditionFloatingMenu
                    handleDelete={handleDeleteMicroSummary}
                    handleEditContent={() => setOnInterventionEdit(true)}
                    contentValue={microSummaryValue}
                />
            ) : null}
            {onInterventionEdit ? (
                <>
                    <Grid item xs={12}>
                        <TextField
                            id='title-input'
                            multiline
                            rows={3}
                            fullWidth
                            variant='standard'
                            type={'text'}
                            value={titleValue}
                            onChange={handleChangeTitle}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                        <Grid container spacing={1} sx={{ direction: 'row', alignItems: 'left' }}>
                            {KeyWords.map((keyWord, index) => (
                                <Grid item key={index}>
                                    <Chip
                                        key={keyWord}
                                        size='small'
                                        label={keyWord}
                                        onDelete={() => handleDeleteKeyWord(keyWord)}
                                        sx={{
                                            color: palette.primary.main,
                                            backgroundColor: palette.primary[50]
                                        }}
                                    />
                                </Grid>
                            ))}
                            <Grid item key={'button'} sx={{ mt: -0.5 }}>
                                <DialogButton
                                    withButton={true}
                                    iconButton={true}
                                    buttonIcon={<AddOutlinedIcon fontSize='small' />}
                                    successButtonText={'Crear'}
                                    dialogTitle={'Palabras clave'}
                                    dialogText={
                                        'Introduce nuevas palabras clave para el fragmento seleccionado.'
                                    }
                                    onSuccess={handleUpdateKeyWords}
                                    onClose={handleCloseDialog}
                                >
                                    <TextField
                                        id='keyWords-input'
                                        error={alertKeyWords}
                                        helperText={alertKeyWords ? alertMessage : null}
                                        fullWidth
                                        variant='standard'
                                        type={'text'}
                                        label={'Separa por comas para introducir varias'}
                                        onChange={handleChangeNewKeyWords}
                                    />
                                </DialogButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id='content-input'
                            multiline
                            fullWidth
                            rows={6}
                            sx={{ height: 'auto' }}
                            variant='standard'
                            type={'text'}
                            value={contentValue}
                            onChange={handleChangeContent}
                        />
                    </Grid>
                    <Grid item sx={{ mt: 3, mb: 3 }}>
                        <Button
                            variant='contained'
                            color='primary'
                            disableElevation={true}
                            onClick={onClickSave}
                        >
                            Guardar
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            color='primary'
                            disableElevation={true}
                            onClick={handleCloseInterventionEdition}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Grid
                            item
                            sx={{
                                display: 'inline-flex',
                                maxHeight: 'auto'
                            }}
                            xs={12}
                        >
                            <Typography component='span' variant='h6' color={palette.primary.main}>
                                {titleValue}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paragraph text={contentValue} color={palette.primary.main} />
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Grid container spacing={1} sx={{ direction: 'row', alignItems: 'left' }}>
                            {KeyWords.map((keyWord, index) => (
                                <Grid item key={index}>
                                    <Chip
                                        key={keyWord}
                                        size='small'
                                        label={keyWord}
                                        sx={{
                                            color: palette.primary.main,
                                            backgroundColor: palette.primary[50]
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

MicroSummary.propTypes = {
    microSummary: PropTypes.object,
    isShared: PropTypes.bool,
    handleUpdateMicroSummary: PropTypes.func,
    handleDeleteMicroSummary: PropTypes.func,
    meetingId: PropTypes.number
};
