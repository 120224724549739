import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActive: false,
    type: null,
    message: null
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.isActive = action.payload;
        },
        setNotificationType: (state, action) => {
            state.isActive = true;
            state.type = action.payload;
        },
        setNotificationMessage: (state, action) => {
            state.isActive = true;
            state.message = action.payload;
        },
        removeNotification: (state) => {
            state.isActive = false;
            state.type = null;
            state.message = null;
        }
    }
});

export const { setNotification, setNotificationType, setNotificationMessage, removeNotification } =
    notificationSlice.actions;
export default notificationSlice.reducer;
