import { authApiCall, endpoints } from '..';

export async function getSharedMeetingById({ meetingId, authToken }) {
    const res = await authApiCall({
        method: 'GET',
        endpoint: endpoints.sharedMeetings,
        path: `/${meetingId}`,
        authToken
    });

    return {
        meeting: res.data
    };
}
