import { useEffect, useRef, useState } from 'react';
import { ArrowBackOutlined, ArrowForwardOutlined } from '@mui/icons-material';
import {
    Box,
    ClickAwayListener,
    Container,
    Fab,
    Grid,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import TabHeader from '@common/components/tabs/TabHeader';
import TabPanel from '@common/components/tabs/TabPanel';
import useWidth from '@common/hooks/useWidth';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/palette/shadows';
import Breadcrumb from '../BreadCrumb';

HideableLayout.propTypes = {
    breadcrumbProps: PropTypes.object,
    content: PropTypes.node,
    hideableContent: PropTypes.node,
    sticky: PropTypes.bool,
    hideableContentTabs: PropTypes.arrayOf(PropTypes.object),
    setHideMenu: PropTypes.func,
    handleChangeTitle: PropTypes.func,
    actions: PropTypes.node,
    fabActionIcon: PropTypes.node,
    fabActionFunction: PropTypes.func,
    fabActionTooltip: PropTypes.string
};

export default function HideableLayout({
    breadcrumbProps,
    content,
    hideableContent,
    sticky,
    hideableContentTabs,
    setHideMenu,
    handleChangeTitle,
    actions,
    fabActionIcon,
    fabActionFunction,
    fabActionTooltip
}) {
    const width = useWidth();
    const [topOffset, setTopOffset] = useState(0);
    const menuRef = useRef(null);
    const [hidden, setHidden] = useState(width === 'xs' || width === 'sm' ? true : false);
    const [tabValue, setTabValue] = useState(0);
    const [titleClick, setTitleClick] = useState(false);
    const [editableTitleContent, setEditableTitleContent] = useState(
        breadcrumbProps.currentPageTitle
    );
    const [titleChanged, setTitleChanged] = useState(false);
    const [currentPageTitle, setCurrentPageTitle] = useState(breadcrumbProps.currentPageTitle);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSaveNewTitle = async () => {
        await handleChangeTitle(editableTitleContent);
        setCurrentPageTitle(editableTitleContent);
        setTitleClick(false);
        setTitleChanged(false);
    };

    const handleSaveTitleWithEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSaveNewTitle();
            event.target.blur();
        }
    };

    const handleNameClickAway = () => {
        if (titleClick && titleChanged) {
            handleSaveNewTitle();
        }
    };

    const handleTitleChange = (e) => {
        if (!titleChanged) {
            setTitleChanged(true);
        }
        setEditableTitleContent(e.target.innerText);
    };

    useEffect(() => {
        const handleScroll = () => {
            const menuElement = menuRef.current;

            if (menuElement) {
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const newTopOffset = Math.max(0, scrollTop - 0);
                setTopOffset(newTopOffset);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (setHideMenu) {
            setHideMenu(hidden);
        }
    }, [hidden]);

    useEffect(() => {
        setCurrentPageTitle(breadcrumbProps.currentPageTitle);
    }, [breadcrumbProps]);

    return (
        <Container
            maxWidth={hidden ? 'md' : 'lg'}
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
            <Grid spacing={2} container style={{ height: '100%' }}>
                {/* COLUMN PAGE CONTENT */}
                <Grid
                    md={hidden ? 11 : 8}
                    sx={{
                        display: {
                            xs: hidden
                                ? 'block'
                                : width === 'xs' || width === 'sm'
                                ? 'none'
                                : 'block'
                        }
                    }}
                    xs={11}
                    item
                >
                    {breadcrumbProps.disabled ? null : (
                        <Grid container direction='column' spacing={3}>
                            <Grid item xs={12} sx={{ mt: 1, mr: 2 }}>
                                <Breadcrumb
                                    previousPage={breadcrumbProps.previousPage}
                                    currentPageTitle={currentPageTitle}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {handleChangeTitle ? (
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            <Stack spacing={2}>
                                <ClickAwayListener onClickAway={handleNameClickAway}>
                                    <Tooltip title={'Doble clic para editar'} followCursor={true}>
                                        <Typography
                                            component='span'
                                            variant='h4'
                                            contentEditable={titleClick}
                                            onClick={() => setTitleClick(true)}
                                            onInput={handleTitleChange}
                                            onKeyDown={handleSaveTitleWithEnter}
                                            sx={{
                                                ':hover': {
                                                    cursor: 'pointer',
                                                    color: palette.primary[500]
                                                },
                                                ':focus': {
                                                    outline: 'none',
                                                    color: palette.primary[500],
                                                    pl: 1
                                                }
                                            }}
                                        >
                                            {currentPageTitle}
                                        </Typography>
                                    </Tooltip>
                                </ClickAwayListener>
                            </Stack>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            <Stack spacing={2}>
                                <Typography component='span' variant='h4'>
                                    {currentPageTitle}
                                </Typography>
                            </Stack>
                        </Grid>
                    )}

                    <Box mt={3} sx={{ mr: 3 }}>
                        {content}
                    </Box>
                </Grid>

                {/* COLUMN HIDEABLE CONTENT */}
                <Grid
                    ref={menuRef}
                    sx={{
                        borderLeft: 1,
                        borderColor: palette.primary[50],
                        display: { xs: 'block', md: 'block' },
                        ml: (width === 'xs' || width === 'sm') && !hidden ? 5 : 0
                    }}
                    xs={hidden ? 1 : width === 'xs' || width === 'sm' ? 11 : 4}
                    md={hidden ? 1 : 4}
                    item
                >
                    <Grid
                        ref={menuRef}
                        container
                        direction='column'
                        alignItems='left'
                        spacing={0}
                        display='flex'
                        sx={{
                            ml: 0,
                            mt: 0,
                            position: sticky ? 'sticky' : 'none', // We use sticky to adjust the top value dinamically
                            top: `${topOffset}px`,
                            transition: 'top 0.2s ease-in-out' // We add a soft transition to change the top value
                        }}
                    >
                        {hideableContentTabs ? (
                            <>
                                {hidden ? null : (
                                    <Grid item xs={12} sx={{ ml: -2 }}>
                                        <TabHeader
                                            tabs={hideableContentTabs.map((t) => {
                                                return t.name;
                                            })}
                                            currentTabValue={tabValue}
                                            handleChangeTab={handleTabChange}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Tooltip title={hidden ? 'Mostrar' : 'Ocultar'}>
                                        <Fab
                                            onClick={() => setHidden(!hidden)}
                                            aria-label='show-more'
                                            color='floating_button'
                                            size='small'
                                            sx={{
                                                boxShadow: shadows.elevated,
                                                mt: hidden ? 6 : 1,
                                                mb: 1,
                                                ml: -4.5
                                            }}
                                        >
                                            {hidden ? (
                                                <ArrowBackOutlined />
                                            ) : (
                                                <ArrowForwardOutlined />
                                            )}
                                        </Fab>
                                    </Tooltip>
                                </Grid>
                                {hidden ? null : (
                                    <Grid item xs={12} sx={{ pt: 1, pl: 2 }}>
                                        {hideableContentTabs.map((tab, index) => {
                                            return (
                                                <TabPanel
                                                    key={tab.id}
                                                    value={tabValue}
                                                    index={index}
                                                >
                                                    {tab.content}
                                                </TabPanel>
                                            );
                                        })}
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <>
                                <Grid item>
                                    <Tooltip title={hidden ? 'Mostrar' : 'Ocultar'}>
                                        <Fab
                                            onClick={() => setHidden(!hidden)}
                                            aria-label='show-more'
                                            color='floating_button'
                                            size='small'
                                            sx={{
                                                boxShadow: shadows.elevated,
                                                mt: 1,
                                                mb: 1,
                                                ml: -4.5,
                                                mr: 1
                                            }}
                                        >
                                            {hidden ? (
                                                <ArrowBackOutlined />
                                            ) : (
                                                <ArrowForwardOutlined />
                                            )}
                                        </Fab>
                                    </Tooltip>
                                    {actions}
                                </Grid>
                                {fabActionIcon && fabActionFunction && (
                                    <Tooltip title={fabActionTooltip}>
                                        <Fab
                                            onClick={fabActionFunction}
                                            aria-label='fabAction'
                                            color='floating_button'
                                            size='small'
                                            sx={{
                                                boxShadow: shadows.elevated,
                                                mt: 1,
                                                mb: -5.5,
                                                ml: -4.5,
                                                mr: 1
                                            }}
                                        >
                                            {fabActionIcon}
                                        </Fab>
                                    </Tooltip>
                                )}
                                {hidden ? null : <Grid item>{hideableContent}</Grid>}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
