import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import usePrevious from '@common/components/editableComponents/hooks/usePrevious';
import { StrictModeDroppable } from '@common/components/editableComponents/StrictModeDroppable';
import ReportBlock from './ReportBlock';
import { moveCursorToLastBlock, moveCursorToNextBlock } from './utils';

export default function ReportView({
    blocks,
    addBlockHandler,
    deleteBlockHandler,
    updateBlockHandler,
    onDragEndHandler,
    meetingId,
    getReportBlocksHandler,
    currentBlockId,
    isShared,
    intoduceBlockIntoAnother,
    undoBlockDeletion
}) {
    const prevBlocks = usePrevious(blocks);

    // Handling the cursor and focus on adding and deleting blocks
    useEffect(() => {
        // If a new block was added, move the caret to it
        if (prevBlocks && prevBlocks.length + 1 === blocks.length) {
            moveCursorToNextBlock(blocks, currentBlockId);
            getReportBlocksHandler(meetingId);
        } else if (prevBlocks && prevBlocks.length - 1 === blocks.length) {
            // If a block was deleted, move the caret to the end of the last block
            moveCursorToLastBlock(prevBlocks, currentBlockId);
            getReportBlocksHandler(meetingId);
        }
    }, [blocks, prevBlocks, currentBlockId]);

    return (
        <>
            <DragDropContext onDragEnd={onDragEndHandler}>
                <StrictModeDroppable droppableId={meetingId.toString()}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {blocks.map((block) => {
                                const position = blocks.map((b) => b.id).indexOf(block.id) + 1;
                                return (
                                    <ReportBlock
                                        key={block.id}
                                        position={position}
                                        id={block.id}
                                        htmlTag={block.htmlTag}
                                        htmlContent={block.htmlContent}
                                        addBlock={addBlockHandler}
                                        deleteBlock={deleteBlockHandler}
                                        updateBlock={updateBlockHandler}
                                        blocks={blocks}
                                        currentBlockId={block.id}
                                        isShared={isShared}
                                        meetingId={meetingId}
                                        intoduceBlockIntoAnother={intoduceBlockIntoAnother}
                                        undoBlockDeletion={undoBlockDeletion}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </>
    );
}

ReportView.propTypes = {
    meetingId: PropTypes.number,
    currentBlockId: PropTypes.number,
    blocks: PropTypes.arrayOf(PropTypes.object),
    addBlockHandler: PropTypes.func,
    deleteBlockHandler: PropTypes.func,
    updateBlockHandler: PropTypes.func,
    onDragEndHandler: PropTypes.func,
    getReportBlocksHandler: PropTypes.func,
    isShared: PropTypes.bool,
    intoduceBlockIntoAnother: PropTypes.func,
    undoBlockDeletion: PropTypes.func
};
