import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';

export default function DateCell({ date, errorState, handleOpenMeeting, meeting }) {
    return (
        <TableCell
            sx={{
                borderColor: palette.primary[50],
                cursor: errorState ? null : 'pointer'
            }}
            key={date}
            style={{ width: 240 }}
            align='left'
            onClick={errorState ? null : (event) => handleOpenMeeting(meeting, event)}
            onMouseDown={(event) => {
                if (event.button === 1) handleOpenMeeting(meeting, event);
            }}
        >
            <Typography variant='body2' color={palette.primary[500]}>
                {date}
            </Typography>
        </TableCell>
    );
}

DateCell.propTypes = {
    date: PropTypes.string,
    errorState: PropTypes.bool,
    handleOpenMeeting: PropTypes.func,
    meeting: PropTypes.object
};
