import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

TemplateSkeleton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    borderType: 'dashed' | 'solid',
    borderColor: PropTypes.number,
    borderWidth: PropTypes.number,
    shadow: PropTypes.bool
};

export default function TemplateSkeleton({
    children,
    borderType,
    borderWidth,
    shadow,
    borderColor
}) {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            boxShadow={shadow}
            sx={{
                border: `${borderWidth}px ${borderType} ${borderColor}`,
                cursor: 'pointer',
                borderRadius: '10px',
                position: 'relative',
                ':hover': {
                    borderColor: palette.primary.main
                },
                pb: `${borderWidth}px`
            }}
            height='170px'
            width='350px'
        >
            {children}
        </Box>
    );
}
