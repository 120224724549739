import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import palette from '@common/theme/palette/palette';

export const getIconFromFormatType = (format) => {
    if (format === 'phrase') {
        return <ShortTextIcon htmlColor={palette.primary[50]} />;
    } else if (format === 'paragraph') {
        return <NotesIcon htmlColor={palette.primary[50]} />;
    } else if (format === 'multi_paragraph') {
        return <SubjectOutlinedIcon htmlColor={palette.primary[50]} />;
    } else if (format === 'list') {
        return <FormatListBulletedOutlinedIcon htmlColor={palette.primary[50]} />;
    } else if (format === 'number') {
        return <TagOutlinedIcon htmlColor={palette.primary[50]} />;
    }
};
