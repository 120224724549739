import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    confirmButtonText: PropTypes.string,
    closeButtonText: PropTypes.string
};

export default function ConfirmationDialog({
    open,
    title,
    message,
    onConfirm,
    onClose,
    confirmButtonText,
    closeButtonText
}) {
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' disableElevation={true} onClick={onClose}>
                        {confirmButtonText ? confirmButtonText : 'Cancelar'}
                    </Button>
                    <Button
                        variant='contained'
                        onClick={onConfirm}
                        disableElevation={true}
                        autoFocus
                    >
                        {closeButtonText ? closeButtonText : 'Aceptar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
