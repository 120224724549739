import { combineReducers } from 'redux';
import authReducer from './authSlice';
import notificationReducer from './notificationSlice';
import stastsReducer from './statsSlice';
import userReducer from './userSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    stats: stastsReducer,
    notification: notificationReducer
});

export default rootReducer;
