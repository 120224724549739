import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import useNotification from '@common/hooks/useNotification';
import { compareWithoutAccentsAndCase } from '@common/utils/strings';
import { createCustomTag } from '@setup/api/userConfig';
import TagCreationButtonView from './TagCreationButtonView';

export default function TagCreationButton({ setTagsList, tagsList, setCurrentTag, IsIconButton }) {
    const notification = useNotification();

    const [alertName, setAlertName] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [name, setName] = useState('');
    const [color, setColor] = useState('chip_grey');
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenTagCreationMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseTagCreationMenu = () => {
        setAnchorElUser(null);
        setName('');
        setAlertMessage('');
        setColor('chip_grey');
        setAlertName(false);
    };

    const handleOnTitleChange = (e) => {
        setName(e.target.value);
    };

    const handleOnColorChange = (e) => {
        setColor(e.target.id);
    };

    const handleCreateCustomTag = async () => {
        if (!name) {
            setAlertMessage('Te has olvidado de poner un nombre a la etiqueta.');
            setAlertName(true);
            return;
        } else if (tagsList.find((tag) => compareWithoutAccentsAndCase(tag.name, name))) {
            setAlertMessage('Ops, parece que ya existe una etiqueta con este nombre.');
            setAlertName(true);
            return;
        }
        try {
            const { customTag } = await createCustomTag({ name, color });
            setTagsList([customTag, ...(tagsList || [])]);
            setCurrentTag({ name, color });
            handleCloseTagCreationMenu();
        } catch (error) {
            notification('create-tag-error');
        }
    };

    return (
        <TagCreationButtonView
            IsIconButton={IsIconButton}
            name={name}
            color={color}
            anchorElUser={anchorElUser}
            handleOpenTagCreationMenu={handleOpenTagCreationMenu}
            handleCloseTagCreationMenu={handleCloseTagCreationMenu}
            handleOnTitleChange={handleOnTitleChange}
            handleOnColorChange={handleOnColorChange}
            alertName={alertName}
            alertMessage={alertMessage}
            handleCreateCustomTag={handleCreateCustomTag}
        />
    );
}

TagCreationButton.propTypes = {
    setTagsList: PropTypes.func,
    tagsList: PropTypes.arrayOf(PropTypes.object),
    setCurrentTag: PropTypes.func,
    IsIconButton: PropTypes.bool
};
