import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useNotification from '@common/hooks/useNotification';
import { getWriteSignedUrl, uploadToGCS } from '@setup/api/gcs';
import { updateAvatarUrl } from '@setup/api/user';
import { setUserAvatarUrl } from '@setup/redux/reducers/userSlice';
import UploadPhotoView from './UploadPhotoView';
import { dataUrlToFileUsingFetch } from './utils';

export default function UploadPhoto({ setUrlAvatarImage }) {
    const dispatch = useDispatch();
    const notification = useNotification();

    const currentAvatarUrl = useSelector((state) => state.user.avatarUrl);

    const [filename, setFilename] = useState('');
    const [file, setFile] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOnFileSelection = (file) => {
        if (['image/jpeg', 'image/png'].indexOf(file.type) < 0) {
            setAlert(true);
            setAlertMessage('Los formatos de imagen válidos son .jpeg o .png');
            return;
        } else if (file.size > 2000000) {
            setAlert(true);
            setAlertMessage('Tu imagen pesa demasiado, comprímela o sube otra');
            return;
        }
        try {
            setFile(file);
            setFilename(file.name);
            setAlert(false);
        } catch (error) {
            setFilename('');
            console.error(error);
        }
    };

    const handleCloseDialog = () => {
        setFilename('');
        setFile('');
        setAlertMessage('');
        setAlert(false);
        setLoading(false);
    };

    const hanldeUploadImage = async (urlCroppedImage) => {
        const filetype = file.type || 'unknown';
        setLoading(true);
        const filename =
            currentAvatarUrl && currentAvatarUrl !== null
                ? currentAvatarUrl.split('/')[5].split('?')[0]
                : uuidv4();

        try {
            const newImageFile = await dataUrlToFileUsingFetch(urlCroppedImage, filename, filetype);

            const signedUrl = await getWriteSignedUrl({
                filename: `avatars/${filename}`,
                filetype
            });
            await uploadToGCS({ signedUrl, file: newImageFile });
            const { avatarUrl } = await updateAvatarUrl({ filename });

            setLoading(false);
            handleCloseDialog();
            dispatch(setUserAvatarUrl(avatarUrl)); // Makes no effect because the url is the same always
            notification('image-uploaded');
        } catch (error) {
            console.error(error.message);
            setUrlAvatarImage(currentAvatarUrl);
            handleCloseDialog();
            notification('image-upload-failure');
        }
    };

    return (
        <UploadPhotoView
            handleOnFileSelection={handleOnFileSelection}
            filename={filename}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            alert={alert}
            setAlert={setAlert}
            handleCloseDialog={handleCloseDialog}
            file={file}
            setUrlAvatarImage={setUrlAvatarImage}
            hanldeUploadImage={hanldeUploadImage}
            loading={loading}
        />
    );
}

UploadPhoto.propTypes = {
    setUrlAvatarImage: PropTypes.func
};
