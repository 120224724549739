import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InformationAlert from '@common/components/alerts/InformationAlert';
import { navigation } from '@common/helpers/navigation';
import { getAllReportTemplates } from '@setup/api/reportTemplates/reportTemplates';

TemplatesNonUsedAlert.propTypes = {
    centered: PropTypes.bool
};

export default function TemplatesNonUsedAlert() {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const userCreatedAt = useSelector((state) => state.user.userCreatedAt);

    useEffect(() => {
        (async () => {
            const { data } = await getAllReportTemplates();
            if (data.length <= 1) {
                const millisecondsDifference = Math.abs(
                    new Date(data[0].updatedAt) - new Date(userCreatedAt)
                );
                if (millisecondsDifference < 60 * 1000) {
                    // difference between dates are not bigger than 1 minute
                    setShow(true);
                }
            }
        })();
    }, []);

    if (show) {
        return (
            <InformationAlert
                message={
                    '🚀 ¡Personaliza la información que obtienes de tu reunión con las plantillas!'
                }
                onClick={() => navigate(navigation.app.routes.templates)}
                buttonText='Personalizar plantilla'
            />
        );
    }
}
