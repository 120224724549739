import axios from 'axios';
import { config } from '../config';
import { authApiCall, endpoints } from '.';

export async function signIn({ data }) {
    const { data: resData } = await axios.post(
        `${config.gatewayUrl}/${endpoints.auth}/signin`,
        data,
        {
            withCredentials: false
        }
    );

    return resData;
}

export async function sendChangePasswordEmail({ email }) {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.auth,
        path: '/forgotten-password',
        data: { email }
    });

    return { sendChangePasswordEmailStatus: res.status };
}

export async function resetPassword({ token, password }) {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.auth,
        path: '/forgotten-password',
        data: { token, password }
    });

    return { resetPasswordStatus: res.status };
}

export async function signUp(newUserData) {
    const { data } = await axios.post(
        `${config.gatewayUrl}/${endpoints.auth}/signup`,
        newUserData,
        {
            withCredentials: false
        }
    );

    return data;
}

export async function grecaptchaVerify(grecaptchaToken) {
    const res = await axios.post(
        `${config.gatewayUrl}/${endpoints.auth}/grecaptcha/verify`,
        { token: grecaptchaToken },
        {
            withCredentials: false
        }
    );

    return res.data;
}
