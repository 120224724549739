import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { PropTypes } from 'prop-types';
import AudioPlayer from '@common/components/audioPlayer/AudioPlayer';
import { meetingStatus } from '@common/helpers/meetingStatus';
import useUser from '@common/hooks/useUser';
import { downloadAudio } from '@common/utils/audio';
import { getReadSignedURL } from '@setup/api/gcs';
import UsageWarning from '../createMeetingDialog/UsageWarning';
import Content from './Content';
import DialogLoadingState from './DialogLoadingState';
import EditableTitle from './EditableTitle';
import Info from './info/Info';
import TopActions from './TopActions';

const useStyles = makeStyles(() => ({
    dialog: {
        height: '75vh'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function MeetingDialogView(props) {
    const {
        open,
        handleClose,
        handleOpenMeetingPage,
        handleUpdateTitle,
        currentMeeting,
        handleOpenMeeting,
        summaryTopics,
        findAndUpdateMeeting
    } = props;
    const classes = useStyles();
    const audioUrl = useRef('');
    const { userIsRunOutOfMinutes } = useUser();

    const [onTitleClick, setOnTitleClick] = useState(false);
    const isProcessing =
        currentMeeting.transcriptionStatus === meetingStatus.AUDIO_STARTED ||
        currentMeeting.transcriptionStatus === meetingStatus.AUDIO_IN_PROGESS ||
        currentMeeting.transcriptionStatus === meetingStatus.TRANSCRIPTION_IN_PROGRESS ||
        currentMeeting.transcriptionStatus === meetingStatus.TRANSCRIPTION_STARTED ||
        currentMeeting.transcriptionStatus === meetingStatus.TRANSCRIPTION_SUCCEED ||
        currentMeeting.transcriptionStatus === meetingStatus.SUMMARY_STARTED ||
        currentMeeting.transcriptionStatus === meetingStatus.SUMMARY_IN_PROGRESS
            ? true
            : false;
    const isStandBy = currentMeeting.transcriptionStatus === meetingStatus.STAND_BY;

    useEffect(() => {
        (async () => {
            if (currentMeeting) {
                audioUrl.current = await getReadSignedURL({
                    filename: currentMeeting.filename,
                    removeType: isStandBy
                });
            }
        })();
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description'
            sx={{ height: '100vh' }}
            classes={{ paper: classes.dialog }}
        >
            <TopActions
                handleClose={handleClose}
                setOnTitleClick={setOnTitleClick}
                handleOpenMeetingPage={isProcessing || isStandBy ? null : handleOpenMeetingPage}
                platform={currentMeeting.platform}
                currentMeeting={currentMeeting}
            />
            <EditableTitle
                handleUpdateTitle={handleUpdateTitle}
                currentMeeting={currentMeeting}
                onTitleClick={onTitleClick}
                setOnTitleClick={setOnTitleClick}
            />
            <DialogContent>
                <Info currentMeeting={currentMeeting} findAndUpdateMeeting={findAndUpdateMeeting} />
                {isProcessing || isStandBy ? (
                    <>
                        <AudioPlayer
                            sx={{ mt: 2, mb: 3 }}
                            filename={currentMeeting.title}
                            subtitle={
                                currentMeeting.platform
                                    ? currentMeeting.platform === 'directUpload'
                                        ? 'Subida directa'
                                        : currentMeeting.platform
                                    : null
                            }
                            audioUrl={audioUrl.current}
                            audioDuration={currentMeeting.duration}
                            handleDownload={
                                isStandBy
                                    ? null
                                    : async () =>
                                          await downloadAudio({
                                              meetingTitle: currentMeeting.title,
                                              audioFilename: currentMeeting.filename
                                          })
                            }
                            enablePlaybackRate={true}
                        />
                        {isProcessing && (
                            <DialogLoadingState
                                currentMeeting={currentMeeting}
                                handleOpenMeetingDialog={handleOpenMeeting}
                            />
                        )}
                        {userIsRunOutOfMinutes && <UsageWarning />}
                    </>
                ) : (
                    <Content summaryTopics={summaryTopics} summary={currentMeeting.summary} />
                )}
            </DialogContent>
        </Dialog>
    );
}

MeetingDialogView.propTypes = {
    open: PropTypes.bool,
    loadingDialog: PropTypes.bool,
    currentMeeting: PropTypes.object,
    handleClose: PropTypes.func,
    handleOpenMeetingPage: PropTypes.func,
    handleDeleteSummary: PropTypes.func,
    handleUpdateTitle: PropTypes.func,
    handleCreateSummary: PropTypes.func,
    summaryCreationLoader: PropTypes.bool,
    handleOpenMeeting: PropTypes.func.isRequired,
    summaryTopics: PropTypes.arrayOf(PropTypes.string),
    findAndUpdateMeeting: PropTypes.func
};
