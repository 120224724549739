import React, { useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import { PropTypes } from 'prop-types';
import { meetingStatus } from '@common/helpers/meetingStatus';

export default function TagMeetingProcessingStatus({ status }) {
    const [label, setLabel] = useState('Creando');

    useEffect(() => {
        switch (status) {
            case meetingStatus.AUDIO_IN_PROGESS:
                setLabel('Procesando');
                break;
            case meetingStatus.TRANSCRIPTION_IN_PROGRESS:
                setLabel('Transcribiendo');
                break;
            case meetingStatus.SUMMARY_IN_PROGRESS:
                setLabel('Resumiendo');
                break;
        }
    }, [status]);

    return (
        <Tooltip title='Puedes cerrar Voicit, recibirás un correo.'>
            <Chip
                key={label}
                label={label}
                size='small'
                icon={<CircularProgress size={12} sx={{ mr: 1 }} />}
            />
        </Tooltip>
    );
}

TagMeetingProcessingStatus.propTypes = {
    status: PropTypes.number.isRequired
};
