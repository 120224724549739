import { authApiCall, endpoints } from '.';

export const createWorkspace = async (newWorkspaceName) => {
    // We have to update authToken in redux store with this new one for admin permissions
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.workspace,
        path: '/create',
        data: {
            workspaceName: newWorkspaceName
        }
    });

    return data.token;
};

export const getWorkspace = async () => {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.workspace,
        path: '/'
    });

    return data;
};

export const getWorkspaceMembers = async () => {
    const { data } = await authApiCall({
        method: 'GET',
        endpoint: endpoints.workspace,
        path: '/members'
    });

    return data;
};

// Below function are just for workspace admins

export const addWorkspaceMember = async (userEmail, setNewMemberRoleId, cb) => {
    const res = await authApiCall({
        method: 'POST',
        endpoint: endpoints.workspaceAdmin,
        path: '/members/add',
        data: {
            userEmail,
            role: setNewMemberRoleId
        }
    });
    cb();

    return res;
};

export const removeWorkspaceMember = async (userId, cb) => {
    const res = await authApiCall({
        method: 'DELETE',
        endpoint: endpoints.workspaceAdmin,
        path: '/members/delete',
        data: {
            userId
        }
    });
    cb();

    return res;
};

export const updateWorkspaceName = async (workspaceName, cb) => {
    const res = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.workspaceAdmin,
        path: '/update/name',
        data: {
            workspaceName
        }
    });
    cb();

    return res;
};
