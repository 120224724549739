import React, { useState } from 'react';

import useNotification from '@common/hooks/useNotification';
import { sendChangePasswordEmail } from '@setup/api/auth';

import FormEmailView from './FormEmailView';

export default function FormEmail() {
    const notification = useNotification();

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const resetState = () => {
        setAlert(false);
        setAlertMessage('');
        setLoading(false);
    };

    const handleSendEmail = async (data) => {
        setLoading(true);
        try {
            await sendChangePasswordEmail({
                email: data.email
            });
            notification('email-sent');
            resetState();
        } catch (error) {
            setAlert(true);
            setAlertMessage(error.response.data.message);
            setLoading(false);
        }
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append('federated', false);
        handleSendEmail(Object.fromEntries(data.entries()));
    };

    return (
        <FormEmailView
            handleSubmit={handleSubmitForm}
            alert={alert}
            alertMessage={alertMessage}
            loading={loading}
        />
    );
}
