import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';

TooltipIcon.propTypes = {
    icon: PropTypes.node,
    tooltipText: PropTypes.string,
    sx: PropTypes.object
};

export default function TooltipIcon(props) {
    return (
        <Tooltip sx={props.sx} title={props.tooltipText}>
            {props.icon}
        </Tooltip>
    );
}
