import React, { useState } from 'react';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import TemplatesNonUsedAlert from '@common/components/alerts/TemplatesNonUsedAlert';
import HideableLayout from '@common/components/layout/HideableLayout';
import LoaderSummary from '@common/components/loaders/LoaderSummary';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import useNotification from '@common/hooks/useNotification';
import { updateMeetingTitle } from '@setup/api/meeting/meeting';
import Chat from '../components/Chat/Chat';
import MeetingNotes from '../components/MeetingNotes';
import MeetingSideMenu from '../components/meetingSideMenu/MeetingSideMenu';
import Report from '../components/Report/Report';
import Summary from '../components/Summary/Summary';
import OldTranscriptions from '../components/Transcription/OldTranscriptions';
import Transcription from '../components/Transcription/Transcription';
import { meetingTabs } from '../utils';

MeetingPageView.propTypes = {
    meeting: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    firstSpeaker: PropTypes.string,
    isShared: PropTypes.bool.isRequired,
    authToken: PropTypes.string,
    reportBlocks: PropTypes.arrayOf(PropTypes.object),
    getReportBlocksHandler: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isFromPremiumUser: PropTypes.bool.isRequired,
    setBlocks: PropTypes.func.isRequired,
    handleUpdateBlocksWords: PropTypes.func.isRequired,
    meetingNotes: PropTypes.arrayOf(PropTypes.any)
};

export default function MeetingPageView({
    meeting,
    date,
    time,
    firstSpeaker,
    isShared,
    authToken,
    reportBlocks,
    getReportBlocksHandler,
    loading,
    isFromPremiumUser,
    setBlocks,
    handleUpdateBlocksWords,
    meetingNotes
}) {
    const navigate = useNavigate();
    const notification = useNotification();

    const [speakerTurn, setSpeakerTurn] = useState(true);
    const [currentTab, setCurrentTab] = useState(meetingTabs.summary);
    const [currentPage, setCurrentPage] = useState(meeting.title);
    const [currentAudioTime, setCurrentAudioTime] = useState(0);
    const [slideChange, setSlideChange] = useState(0);
    const [messages, setMessages] = useState([]);

    const handleChangeTab = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    const handleSpeakerTurnChange = () => {
        setSpeakerTurn(!speakerTurn);
    };

    const handleUpdateTitle = async (title) => {
        try {
            await updateMeetingTitle({ meetingId: meeting.id, title });
            setCurrentPage(title);
            return true;
        } catch (error) {
            notification('title-error');
        }
    };

    const handleScrollToElement = (targetId) => {
        // TODO: we should show the notes button in all tabs but if we are not in the summary to redirect to the summary
        if (targetId) {
            const element = document.getElementById(targetId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <Page title={currentPage}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    paddingTop: 8,
                    minHeight: '100vh'
                }}
            >
                <Box
                    component='main'
                    sx={{
                        backgroundColor: 'white',
                        flexGrow: 1,
                        overflow: 'auto',
                        height: 'auto'
                    }}
                >
                    <HideableLayout
                        sticky={true}
                        breadcrumbProps={{
                            previousPage: null,
                            currentPageTitle: currentPage || 'Tu reunión',
                            disabled: isShared
                        }}
                        handleChangeTitle={isShared ? null : handleUpdateTitle}
                        content={
                            <>
                                <Grid container spacing={2} direction='column'>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction='column'
                                            alignItems='left'
                                            spacing={2}
                                            display='flex'
                                        >
                                            {!isShared && (
                                                <Grid item xs={12} sm={8} md={6}>
                                                    <TemplatesNonUsedAlert />
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={8} md={6} sx={{ mr: 2, mb: 10 }}>
                                                {currentTab === meetingTabs.transcription ? (
                                                    <>
                                                        {meeting.filename === null ? (
                                                            <OldTranscriptions meeting={meeting} />
                                                        ) : (
                                                            <>
                                                                <Transcription
                                                                    meeting={meeting}
                                                                    showInterDetails={true}
                                                                    isShared={isShared}
                                                                    authToken={authToken}
                                                                    platform={meeting.platform}
                                                                    speakerTurn={speakerTurn}
                                                                    currentAudioTime={
                                                                        currentAudioTime
                                                                    }
                                                                    setCurrentAudioTime={
                                                                        setCurrentAudioTime
                                                                    }
                                                                    enableAutomaticAudioSync={true}
                                                                    slideChange={slideChange}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                ) : currentTab === meetingTabs.summary ? (
                                                    <>
                                                        {loading ? (
                                                            <LoaderSummary />
                                                        ) : (
                                                            <>
                                                                {reportBlocks !== null ? (
                                                                    <>
                                                                        <Report
                                                                            meetingId={meeting.id}
                                                                            isShared={isShared}
                                                                            blocks={reportBlocks}
                                                                            getReportBlocksHandler={
                                                                                getReportBlocksHandler
                                                                            }
                                                                            setBlocks={setBlocks}
                                                                            setCurrentAudioTime={
                                                                                setCurrentAudioTime
                                                                            }
                                                                        />
                                                                        {!isShared &&
                                                                            meetingNotes?.length >
                                                                                0 && (
                                                                                <MeetingNotes
                                                                                    meetingNotes={
                                                                                        meetingNotes
                                                                                    }
                                                                                    setCurrentAudioTime={
                                                                                        setCurrentAudioTime
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </>
                                                                ) : (
                                                                    <Summary
                                                                        content={meeting.summary}
                                                                        transcriptionStatus={
                                                                            meeting.transcriptionStatus
                                                                        }
                                                                        meetingId={meeting.id}
                                                                        isShared={isShared}
                                                                        authToken={authToken}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Chat
                                                        meetingId={meeting.id}
                                                        meetingDuration={meeting.duration}
                                                        meetingTitle={meeting.title}
                                                        messages={messages}
                                                        setMessages={setMessages}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isShared && !isFromPremiumUser ? (
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        TransitionComponent={Slide}
                                        open={true}
                                        message='Crea tus propios resúmenes automáticos con
                                                                Voicit -&gt;'
                                        action={
                                            <Button
                                                onClick={() =>
                                                    navigate(navigation.app.routes.signup)
                                                }
                                                variant='contained'
                                                color='secondary'
                                                disableElevation={true}
                                            >
                                                Usar Voicit Gratis
                                            </Button>
                                        }
                                        key={Slide}
                                    />
                                ) : null}
                            </>
                        }
                        hideableContent={
                            <MeetingSideMenu
                                id={meeting.id}
                                speakers={meeting.speakers}
                                tag={meeting.tag}
                                title={meeting.title}
                                platform={meeting.platform}
                                date={date}
                                time={time}
                                speakersInterventionPercentages={
                                    meeting.speakersInterventionPercentages
                                }
                                filename={meeting.filename}
                                duration={meeting.duration}
                                firstSpeaker={firstSpeaker}
                                isShared={isShared}
                                meetingId={meeting.id}
                                handleSpeakerTurnChange={handleSpeakerTurnChange}
                                authToken={authToken}
                                handleChangeTab={handleChangeTab}
                                currentTab={currentTab}
                                handleUpdateBlocksWords={handleUpdateBlocksWords}
                                setCurrentAudioTime={setCurrentAudioTime}
                                currentAudioTime={currentAudioTime}
                                setSlideChange={setSlideChange}
                            />
                        }
                        fabActionIcon={<BookmarksOutlinedIcon fontSize='small' />}
                        fabActionFunction={
                            isShared || meetingNotes?.length < 1 || currentTab !== 'summary'
                                ? null
                                : () => handleScrollToElement('notes')
                        }
                        fabActionTooltip='Ver notas'
                    />
                </Box>
            </Box>
        </Page>
    );
}
