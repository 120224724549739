export const interventionNotification = [
    {
        type: 'intervention-deletion-failure',
        message: 'Ha ocurrido un error borrando la intervención, intentalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    },
    {
        type: 'intervention-update-failure',
        message: 'Ha ocurrido un error actualizando la intervención, intentalo de nuevo.',
        actionTitle: null,
        actionFunc: null
    }
];
