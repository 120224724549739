import { authNotification } from './authNotification';
import { blockNotification } from './blockNotification';
import { commonNotification } from './commonNotification';
import { interventionNotification } from './interventionNotification';
import { meetingNotification } from './meetingNotification';
import { profileNotification } from './profileNotification';

export const notifications = [
    ...blockNotification,
    ...meetingNotification,
    ...commonNotification,
    ...interventionNotification,
    ...profileNotification,
    ...authNotification
];
