import React, { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    ThemeProvider as MUIThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import palette from './palette/palette';
import typography from './typography';
// import componentsOverride from './overrides';

ThemeProvider.propTypes = {
    children: PropTypes.node
};

export default function ThemeProvider({ children }) {
    const themeOptions = useMemo(
        () => ({
            palette,
            shape: { borderRadius: 4 },
            typography
        }),
        []
    );

    const theme = createTheme(themeOptions);
    // theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
