import React, { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Avatar from '@common/components/avatars/Avatar';
import DialogButton from '@common/components/dialogs/DialogButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import SubscriptionPlanTag from '@common/components/SubscriptionPlanTag';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { updateUserName } from '@setup/api/user';
import ChangePasswordButton from './ChangePasswordButton';
import DeleteAccountButton from './DeleteAccountButton';
import UploadPhoto from './UploadPhoto/UploadPhoto';

AccountTab.propTypes = {
    button: PropTypes.bool,
    handleUpdateName: PropTypes.func.isRequired
};

export default function AccountTab({ value }) {
    const givenName = useSelector((state) => state.user.userGivenName);
    const email = useSelector((state) => state.user.userEmail);
    const avatarUrl = useSelector((state) => state.user.userAvatarUrl);
    const { handleUpdateUserData, getPlan } = useUser();

    const [openDialog, setOpenEditNameDialog] = useState(false);
    const [newName, setNewName] = useState(givenName);
    const [urlAvatarImage, setUrlAvatarImage] = useState(avatarUrl);
    const [alertName, setAlertName] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [hover, setHover] = useState(false);

    const handleUpdateName = async () => {
        try {
            const { updateUserNameStatus } = await updateUserName({ givenName: newName });
            updateUserNameStatus;
            await handleUpdateUserData();
            return true;
        } catch (error) {
            setAlertName(true);
            setAlertMessage('Error modificando el nombre, vuelve a intentarlo');
        }
    };

    const handleOnSuccessOnClose = () => {
        setOpenEditNameDialog(false);
    };

    const handleOnClose = () => {
        setNewName(givenName);
        setOpenEditNameDialog(false);
    };

    const onNameInput = async (value) => {
        setNewName(value);
    };

    if (value == 0) {
        // if account tab is not selected we don't render it
        return (
            <>
                {/* Personal Info */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'left',
                        mb: 5
                    }}
                >
                    <Grid item>
                        <Typography component={'span'} variant='subtitle1'>
                            Datos de tu cuenta
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 2 }}>
                        <Typography component={'span'} variant='body2' color={palette.primary[300]}>
                            A continuación se muestra la configuración de tu cuenta
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'left'
                            }}
                            spacing={3}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                        mt: 0
                                    }}
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Avatar
                                            size='xxl'
                                            avatarName={givenName}
                                            avatarImage={urlAvatarImage}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UploadPhoto setUrlAvatarImage={setUrlAvatarImage} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'left',
                                        mt: 0,
                                        mb: 2
                                    }}
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Grid
                                            container
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                            sx={{
                                                borderRadius: '4px',
                                                padding: '8px',
                                                display: 'inline-flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                backgroundColor: hover
                                                    ? palette.primary[50]
                                                    : 'transparent',
                                                justifyContent: 'left',
                                                width: 'auto'
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'left',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Typography
                                                    component='span'
                                                    variant='subtitle2'
                                                    color={palette.primary[300]}
                                                >
                                                    Nombre completo
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    variant='subtitle1'
                                                    color='primary'
                                                >
                                                    {givenName}
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{ ml: 2 }}>
                                                <DialogButton
                                                    onSuccessOnClose={handleOnSuccessOnClose}
                                                    id='clickEditName'
                                                    iconButton={true}
                                                    iconButtonTooltip={'Editar nombre'}
                                                    buttonIcon={
                                                        <EditOutlinedIcon
                                                            style={{ opacity: hover ? 1 : 0 }}
                                                            fontSize='small'
                                                        />
                                                    }
                                                    dialogTitle={'Editar nombre'}
                                                    dialogText={
                                                        'Introduce tu nombre y apellidos para actualizar la información.'
                                                    }
                                                    withButton={true}
                                                    buttonVariant={'text'}
                                                    buttonColor={'chip_grey'[800]}
                                                    buttonSize={'medium'}
                                                    successButtonText='Guardar'
                                                    onSuccess={() => handleUpdateName(newName)}
                                                    fullWidthButton={true}
                                                    openDialog={openDialog}
                                                    onClose={handleOnClose}
                                                >
                                                    <TextField
                                                        onInput={(e) => {
                                                            onNameInput(e.target.value);
                                                        }}
                                                        helperText={alertName ? alertMessage : null}
                                                        label='Nombre completo'
                                                        value={newName}
                                                        style={{ marginTop: '15px' }}
                                                        variant='standard'
                                                        fullWidth
                                                        error={alertMessage ? true : false}
                                                    />
                                                </DialogButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'left',
                                                borderRadius: '8px',
                                                flexGrow: 0,
                                                padding: '8px'
                                            }}
                                        >
                                            <Grid
                                                item
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'left',
                                                    mr: 10
                                                }}
                                            >
                                                <Typography
                                                    component='span'
                                                    variant='subtitle2'
                                                    color={palette.primary[300]}
                                                >
                                                    Correo de contacto
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    variant='subtitle1'
                                                    color='primary'
                                                >
                                                    {email}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Billing */}
                {getPlan().name !== subscriptionPlans.free.name && (
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            justifyContent: 'left',
                            mb: 5
                        }}
                    >
                        <Grid item>
                            <Typography
                                component={'span'}
                                variant='subtitle1'
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                Facturación
                                <SubscriptionPlanTag sx={{ ml: 1 }} />
                            </Typography>
                        </Grid>
                        <Grid item sx={{ mb: 2 }}>
                            <Typography
                                component={'span'}
                                variant='body2'
                                color={palette.primary[300]}
                            >
                                Gestiona tu cuenta de facturación, revisa y descarga tus facturas o
                                cambia tu forma de pago.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    disableElevation={true}
                                    onClick={() => window.open(urls.payments.billing)}
                                >
                                    Acceso a facturación
                                </Button>
                                {getPlan().name === subscriptionPlans.premium.name && (
                                    <PricingDialog
                                        button={true}
                                        title='Actualiza al plan Unlimited'
                                        defaultPlanId={1}
                                    />
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                )}

                {/* Password */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'left',
                        mb: 5
                    }}
                >
                    <Grid item>
                        <Typography component={'span'} variant='subtitle1'>
                            Contraseña
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 2 }}>
                        <Typography component={'span'} variant='body2' color={palette.primary[300]}>
                            Utiliza una combinación de carácteres, números y símbolos para aumentar
                            la seguridad de tu contraseña.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ChangePasswordButton />
                    </Grid>
                </Grid>

                {/* Account Deletion */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'left',
                        mt: 0
                    }}
                >
                    <Grid item>
                        <Typography component={'span'} variant='subtitle1'>
                            Zona de peligro
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 2 }}>
                        <Typography component={'span'} variant='body2' color={palette.primary[300]}>
                            Elimina tu perfil y todos tus datos asociados a él.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <DeleteAccountButton />
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        justifyContent: 'left',
                        mt: 4
                    }}
                >
                    <Grid item>
                        <Typography component={'span'} variant='body2' color={palette.primary[300]}>
                            Contacta con soporte@voicit.com para resolver dudas o problemas.
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    }
}

AccountTab.propTypes = {
    value: PropTypes.number.isRequired
};
