import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'primary'
    }
}));

export default function ProgressLoader({ currentMeeting, handleOpenMeetingDialog }) {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    const [progress, setProgress] = useState(0);

    const handleProgress = () => {
        // ! The percentage is just a time estimation, not real status
        // next have to be in the useEffect
        let difference = [];
        const transcriptionDuration = currentMeeting.duration * 0.75; // transcription can last up to 75% of the audio duration
        const today = new Date();
        // destructuring dates
        const destructuredTime = [today.getHours(), today.getMinutes(), today.getSeconds()];
        const creationTime = currentMeeting.createdAt.split('T')[1].split('.')[0];
        const destructuredCreationTime = creationTime.split(':').map((element) => {
            return Number(element);
        });
        // substracting dates
        destructuredCreationTime.map((element, index) => {
            difference[index] = destructuredTime[index] - element;
        });
        difference.map((element, index) => {
            if (element < 0) {
                --difference[index - 1];
                difference[index] = 60 + difference[index];
            }
        });
        // obtaining total progress
        const transcurredSeconds = difference[1] * 60 + difference[2]; // Difference to seconds supossing any meetings will last more than 45 minutes transcribing
        const totalProgress = (transcurredSeconds * 100) / transcriptionDuration;
        setProgress(totalProgress > 100 ? 100 : totalProgress);
    };

    useEffect(() => {
        if (progress >= 100) {
            handleOpenMeetingDialog(currentMeeting);
            return;
        } else {
            const timer = setInterval(() => {
                handleProgress();
            }, 500);
            return () => {
                clearInterval(timer);
            };
        }
    }, [progress]);

    return (
        <Grid
            container
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 5 }}
        >
            <Grid item sx={{ minWidth: 35, mb: 1 }}>
                <Typography component='span' variant='subtitle1'>
                    Estamos generando la transcripción de tu reunión
                </Typography>
            </Grid>
            {progress == 100 ? (
                <Grid item sx={{ minWidth: 30, mb: 1, justifyContent: 'center' }}>
                    <Typography
                        component='span'
                        variant='caption'
                        color={palette.secondary.light}
                        align='center'
                    >
                        Es posible que tu reunión esté tardando más de lo estimado. Recibirás un
                        email cuando esté lista.
                    </Typography>
                </Grid>
            ) : (
                <Grid
                    item
                    sx={{ minWidth: 30, mb: 1, justifyContent: 'center', textAlign: 'center' }}
                >
                    <Typography component='span' variant='caption' color={palette.secondary.light}>
                        Enviaremos un email a tu correo cuando el proceso termine.
                    </Typography>
                </Grid>
            )}
            <Grid item sx={{ minWidth: 35, mb: 4 }}>
                <Typography component='span' variant='body2' color='text.secondary'>
                    {`${Math.round(progress)}%`}
                </Typography>
            </Grid>
            <Grid item sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant='determinate' value={progress} />
            </Grid>
        </Grid>
    );
}

ProgressLoader.propTypes = {
    currentMeeting: PropTypes.object.isRequired,
    handleOpenMeetingDialog: PropTypes.func.isRequired,
    minHeight: PropTypes.string
};
