import { useEffect, useState } from 'react';
import { getAllCustomTags } from '@setup/api/userConfig';

export function useGetTagData(meetingTagName) {
    const [currentTagData, setCurrentTagData] = useState({});

    const handleGetAllTags = async () => {
        const { customTags } = await getAllCustomTags();
        setCurrentTagData(customTags.find((tag) => tag.name === meetingTagName));
    };

    useEffect(() => {
        (async () => {
            await handleGetAllTags();
        })();
    }, [meetingTagName]);

    return { currentTagData };
}
