import React, { useState } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { urls } from '@common/helpers/urls';
import { utcToLocaleDate } from '@common/utils/date';
import { getNumberOfParticipants } from '@pages/meetingsRepository/utils';
import DateCell from './tableRow/tableCells/DateCell';
import InfoCell from './tableRow/tableCells/InfoCell';
import TitleCell from './tableRow/tableCells/TitleCell';

TableRowEmptyState.propTypes = {
    type: PropTypes.string
};

export default function TableRowEmptyState({ type }) {
    const userCreatedAt = useSelector((state) => state.user.userCreatedAt);
    const emptyStateMeeting = {
        createdAt: userCreatedAt,
        title: 'Crea tu primer resumen con Voicit 👋',
        tag: { name: 'Onboarding', color: 'chip_laurel' },
        speakers: ['Rafa Torres'],
        duration: 100
    };

    const [hover, setHover] = useState(false);

    const date = utcToLocaleDate({ utcDate: emptyStateMeeting.createdAt, timeZone: 'es-ES' });
    const numberOfParticipants = getNumberOfParticipants(emptyStateMeeting.speakers);

    const handleOpenMeeting = () => {
        window.open(urls.helpCenter.tutorials.onboarding);
    };
    if (type === 'no-meetings') {
        return (
            <Box sx={{ width: '100%' }}>
                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table aria-label='custom pagination table'>
                        {/* <TableHead /> */}
                        <TableBody>
                            <TableRow
                                key={0}
                                hover={true}
                                onMouseLeave={() => setHover(false)}
                                onMouseEnter={() => setHover(true)}
                            >
                                <TitleCell
                                    handleOpenMeeting={handleOpenMeeting}
                                    hover={hover}
                                    meeting={emptyStateMeeting}
                                    errorState={false}
                                />
                                <InfoCell
                                    handleOpenMeeting={handleOpenMeeting}
                                    currentTagData={emptyStateMeeting.tag}
                                    numberOfParticipants={numberOfParticipants}
                                    meeting={emptyStateMeeting}
                                    errorState={false}
                                />
                                <DateCell
                                    handleOpenMeeting={handleOpenMeeting}
                                    date={date}
                                    errorState={false}
                                    meeting={emptyStateMeeting}
                                />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    } else if (type === 'not-found') {
        return (
            <TableRow key='empty row'>
                <TableCell colSpan={6} component='th' scope='row'>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 8
                        }}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Typography variant='overline' sx={{ mb: 1 }}>
                            Oops, parece que no se ha encontrado ninguna reunión ...
                        </Typography>
                        <Typography variant='body2' sx={{ mb: 3 }}>
                            Prueba a introducir otros parámetros.
                        </Typography>
                    </Box>
                </TableCell>
            </TableRow>
        );
    }
}
