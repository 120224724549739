import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    deleteMicroSummary,
    getMicroSummaries,
    updateMicroSummaryContentById,
    updateMicroSummaryTitleById
} from '@setup/api/microSummaries/microSummaries';
import { getSharedMicroSummaries } from '@setup/api/microSummaries/sharedMicroSummaries';
import SummaryView from './SummaryView';

export default function Summary({ content, transcriptionStatus, meetingId, isShared, authToken }) {
    const [currentMicroSummaries, setCurrentMicroSummaries] = useState(null);

    const handleGetMicroSummaries = async (meetingId) => {
        const { microSummaries, getMicroSummariesStatus } = isShared
            ? await getSharedMicroSummaries({ meetingId, authToken })
            : await getMicroSummaries({ meetingId });
        if (getMicroSummariesStatus === 200) {
            setCurrentMicroSummaries(microSummaries[0] ? microSummaries : null);
        } else {
            console.log('Error getting microsummaries');
        }
    };

    const handleUpdateMicroSummary = async (id, title, content) => {
        try {
            await updateMicroSummaryTitleById({ id, meetingId, newTitle: title });
            await updateMicroSummaryContentById({ id, meetingId, newContent: content });
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteMicroSummary = async (id) => {
        try {
            await deleteMicroSummary({ meetingId, id });
            setCurrentMicroSummaries(currentMicroSummaries.filter((ms) => ms.id !== id));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        handleGetMicroSummaries(meetingId);
    }, []);

    return (
        <SummaryView
            content={content}
            transcriptionStatus={transcriptionStatus}
            microSummaries={currentMicroSummaries}
            isShared={isShared}
            handleUpdateMicroSummary={handleUpdateMicroSummary}
            handleDeleteMicroSummary={handleDeleteMicroSummary}
            meetingId={meetingId}
        />
    );
}

Summary.propTypes = {
    content: PropTypes.string,
    transcriptionStatus: PropTypes.string.isRequired,
    meetingId: PropTypes.number.isRequired,
    isShared: PropTypes.bool.isRequired,
    authToken: PropTypes.string
};
