import React, { useEffect, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import { deleteCustomTagById, getAllCustomTags } from '@setup/api/userConfig';
import IconButtonWithTooltip from '../buttons/IconButtonWithTooltip';
import Tag from './Tag';
import TagCreationButton from './tagCreationButtonDialog/TagCreationButton';

export default function TagsButtonSelector({
    iconButton,
    onTagSelection,
    tooltipLabel,
    createTag,
    deleteTag,
    toolBar
}) {
    const [anchorElChip, setAnchorElChip] = useState(null);
    const [tagsList, setTagsList] = useState([]);

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const handleGetAllTags = async () => {
        const { customTags } = await getAllCustomTags();
        setTagsList(customTags);
    };

    const filterMeetingList = (currentTagId) => {
        setTagsList(tagsList.filter((tagData) => tagData.id !== currentTagId));
    };

    const handleDeleteTag = async (tag) => {
        await deleteCustomTagById({ tagId: tag.id });
        filterMeetingList(tag.id);
    };

    const handleTagSelection = (chip) => {
        onTagSelection(chip);
        handleCloseList();
    };

    useEffect(() => {
        (async () => {
            await handleGetAllTags();
        })();
    }, []);

    return (
        <>
            {tagsList.length > 0 ? (
                <>
                    <IconButtonWithTooltip
                        icon={iconButton}
                        tooltipText={tooltipLabel}
                        onClick={handleOpenList}
                    />
                    <Popover
                        sx={{ mt: '45px', maxHeight: 450, display: 'flex' }}
                        id='menu-filters'
                        anchorEl={anchorElChip}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        open={Boolean(anchorElChip)}
                        onClose={handleCloseList}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', m: 0 }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    spacing={0}
                                    direction={'column'}
                                    sx={{ alignItems: 'left' }}
                                >
                                    {tagsList.map((tag) => (
                                        <Grid item key={tag.id} sx={{ mt: 1, ml: 1, mr: 1 }}>
                                            <Stack
                                                direction='row'
                                                spacing={1}
                                                alignItems='center'
                                                display='flex'
                                            >
                                                <Tag
                                                    onTagSelection={handleTagSelection}
                                                    tag={tag}
                                                    filterMeetingList={filterMeetingList}
                                                />
                                                {deleteTag ? (
                                                    <IconButtonWithTooltip
                                                        icon={
                                                            <DeleteOutlineOutlinedIcon fontSize='small' />
                                                        }
                                                        tooltipText={'Eliminar etiqueta'}
                                                        onClick={() => handleDeleteTag(tag)}
                                                        size={'small'}
                                                    />
                                                ) : null}
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item sx={{ m: 1 }}>
                                {createTag ? (
                                    <Box display='flex' alignItems={'center'}>
                                        <TagCreationButton
                                            setTagsList={setTagsList}
                                            setCurrentTag={handleTagSelection}
                                            tagsList={tagsList}
                                        />
                                    </Box>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Popover>
                </>
            ) : (
                <>
                    {toolBar ? null : (
                        <Grid
                            container
                            spacing={0}
                            direction={'column'}
                            sx={{ alignItems: 'left', m: 0 }}
                        >
                            <Grid item sx={{ m: 1 }}>
                                {createTag ? (
                                    <Box display='flex' alignItems={'center'}>
                                        <TagCreationButton
                                            IsIconButton={true}
                                            setTagsList={setTagsList}
                                            setCurrentTag={onTagSelection}
                                            tagsList={tagsList}
                                        />
                                    </Box>
                                ) : null}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
}

TagsButtonSelector.propTypes = {
    iconButton: PropTypes.element,
    onTagSelection: PropTypes.func,
    itemsList: PropTypes.arrayOf(PropTypes.object),
    tooltipLabel: PropTypes.string,
    createTag: PropTypes.bool,
    deleteTag: PropTypes.bool,
    toolBar: PropTypes.bool
};
