import React from 'react';
import TextField from '@mui/material/TextField';
import { PropTypes } from 'prop-types';

NameField.propTypes = {
    alertMessage: PropTypes.string,
    name: PropTypes.string,
    setName: PropTypes.func,
    autoFocus: PropTypes.bool,
    variant: PropTypes.string
};

export default function NameField({ alertMessage, name, setName, autoFocus, variant }) {
    const handleChange = (event) => {
        setName(event.target.value);
    };

    return (
        <TextField
            error={alertMessage ? true : false}
            helperText={alertMessage}
            margin='normal'
            size='small'
            required
            fullWidth
            id='givenName'
            label='Nombre'
            name='givenName'
            autoComplete='name'
            autoFocus={autoFocus}
            onChange={handleChange}
            value={name}
            variant={variant ? variant : 'standard'}
        />
    );
}
