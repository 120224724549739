import React, { useEffect, useState } from 'react';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
// import CopyIconButton from '@common/components/buttons/CopyIconButton';
// import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import VoicitAvatar from '@common/components/avatars/VoicitAvatar';
import Paragraph from '@common/components/Paragraph';
import palette from '@common/theme/palette/palette';
// import { getColorFromLetter } from '@common/utils/colors';
import './styles.css';

function TypewriterEffect({ text, speed = 50 }) {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + text.charAt(index));
                setIndex(index + 1);
            }, speed);

            return () => clearTimeout(timeout);
        }
    }, [index, text, speed]);

    return <Paragraph text={displayedText} color={palette.primary[900]} />;
}

export default function ChatMessage({ role, content, position, isLoading, messagesCount }) {
    console.log(position, messagesCount);
    return (
        <Grid
            id={position}
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                mt: 1
            }}
            wrap='nowrap'
            spacing={0}
        >
            <Grid item>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'left',
                        mb: 1
                    }}
                    spacing={2}
                >
                    {role === 'assistant' && (
                        <Grid item>
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'left'
                                }}
                            >
                                <Grid item>
                                    <VoicitAvatar
                                        size={28}
                                        sx={{
                                            animationName: isLoading ? 'circle-pulse' : 'none',
                                            animationDuration: '1s',
                                            animationIterationCount: 'infinite',
                                            backgroundColor: palette.secondary[400],
                                            border: isLoading
                                                ? 'none'
                                                : `1px solid ${palette.secondary[600]}`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    ml: 0,
                    mr: 0,
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Grid
                    item
                    justifyContent={'left'}
                    xs={12}
                    zeroMinWidth
                    sx={{
                        backgroundColor:
                            role === 'user' ? palette.primary[50] : palette.common.white,
                        padding: role === 'user' ? 2 : 0,
                        borderRadius: 2,
                        mb: role === 'user' ? 2 : 0,
                        pb: 0,
                        ml: role === 'user' ? 5 : 0
                    }}
                >
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}
                    >
                        <Grid item xs={12}>
                            {role === 'assistant' && content && messagesCount - 1 === position ? (
                                <TypewriterEffect text={content} speed={10} />
                            ) : (
                                <Paragraph
                                    text={isLoading ? '' : content}
                                    color={palette.primary[900]}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* Add to the upper sx grid element mb: showActions ? -1 : 3.5
                        {showActions ? (
                            <Grid container flexDirection='row'>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<VolumeUpOutlinedIcon fontSize='small' />}
                                        tooltipText={'Escuchar'}
                                        onClick={() =>
                                            setCurrentAudioTime(interventionStartTimeInSeconds)
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<EditOutlinedIcon fontSize='small' />}
                                        tooltipText={'Editar'}
                                        onClick={() => setOnInterventionEdit(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <CopyIconButton content={interventionValue} size='small' />
                                </Grid>
                                <Grid item>
                                    <IconButtonWithTooltip
                                        icon={<DeleteOutlineOutlinedIcon fontSize='small' />}
                                        tooltipText={'Eliminar'}
                                        onClick={() => handleDeleteIntervention(intervention.id)}
                                    />
                                </Grid>
                            </Grid>
                        ) : null} */}
            </Grid>
        </Grid>
    );
}

ChatMessage.propTypes = {
    role: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    messagesCount: PropTypes.number.isRequired
};

TypewriterEffect.propTypes = {
    text: PropTypes.string.isRequired,
    speed: PropTypes.number
};
