import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/palette/shadows';

ItemsSelector.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    selectedItemsIds: PropTypes.arrayOf(PropTypes.string),
    setSelectedItemsIds: PropTypes.func,
    multipleSelection: PropTypes.bool,
    setCurrentItem: PropTypes.func,
    spacing: PropTypes.number,
    direction: PropTypes.string
};

export default function ItemsSelector({
    items,
    selectedItemsIds,
    setSelectedItemsIds,
    multipleSelection,
    setCurrentItem,
    spacing,
    direction
}) {
    const [hoveredItemId, setHoveredItemId] = useState(false);

    const handleSelection = (itemId) => {
        if (multipleSelection) {
            setSelectedItemsIds((prevSelectedItemsIds) => {
                if (prevSelectedItemsIds.includes(itemId)) {
                    return prevSelectedItemsIds.filter((id) => id !== itemId);
                } else {
                    return [...prevSelectedItemsIds, itemId];
                }
            });
        } else {
            setSelectedItemsIds([itemId]);
        }
    };

    useEffect(() => {
        if (setCurrentItem) {
            setCurrentItem(hoveredItemId);
        }
    }, [hoveredItemId]);

    return (
        <Grid
            container
            direction={direction ? direction : 'row'}
            flexWrap={true}
            spacing={spacing ? spacing : 2}
        >
            {items.map((item) => (
                <Grid item key={item.id} flexGrow={1}>
                    <Grid
                        sx={{
                            border: '1px solid',
                            borderColor: selectedItemsIds.includes(item.id)
                                ? palette.primary.main
                                : palette.primary[100],
                            borderRadius: '4px',
                            backgroundColor: selectedItemsIds.includes(item.id)
                                ? palette.primary[50]
                                : 'default',
                            boxShadow: selectedItemsIds.includes(item.id)
                                ? shadows.focused
                                : shadows.none,
                            mb: 2,
                            ':hover': {
                                borderColor: palette.primary.main,
                                cursor: 'pointer'
                            }
                        }}
                        container
                        direction={'row'}
                        alignItems='center'
                        p={1}
                        onClick={() => handleSelection(item.id)}
                        onMouseLeave={() =>
                            setHoveredItemId(selectedItemsIds[selectedItemsIds.length - 1])
                        }
                        onMouseEnter={() => setHoveredItemId(item.id)}
                    >
                        <Grid
                            item
                            sx={{
                                display: { xs: 'flex' },
                                justifyContent: { xs: 'center', md: 'start' }
                            }}
                        >
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                width={'30px'}
                                height={'30px'}
                                borderRadius={1}
                                sx={{ backgroundColor: palette.primary[50] }}
                            >
                                {item.icon}
                            </Box>
                        </Grid>
                        <Grid item justifyContent='start' sx={{ flexGrow: 0, ml: 1 }}>
                            <Typography variant='body2'>{item.name}</Typography>
                        </Grid>
                        {multipleSelection && (
                            <Grid
                                item
                                sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
                            >
                                <Checkbox
                                    size='small'
                                    checked={selectedItemsIds.includes(item.id)}
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent parent click event
                                        handleSelection(item.id);
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}
