import React from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

export default function TagsRow({ tags, handleDelete }) {
    return (
        <Grid container direction='row' spacing={1}>
            {tags?.map((tag, index) => (
                <Grid item key={tag}>
                    <Chip
                        key={tag}
                        label={tag}
                        size='small'
                        onDelete={
                            handleDelete
                                ? () => {
                                      handleDelete(tag, index);
                                  }
                                : null
                        }
                    />
                </Grid>
            ))}
        </Grid>
    );
}

TagsRow.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleDelete: PropTypes.func
};
