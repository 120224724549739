import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AvatarWithInfo from '@common/components/avatars/AvatarWithInfo';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import DialogButton from '@common/components/dialogs/DialogButton';
import MenuButton from '@common/components/MenuButton';
import { userWorkspaceRole } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import palette from '@common/theme/palette/palette';
import { sendUserInvitationToVoicit } from '@common/utils/user';
import {
    addWorkspaceMember,
    removeWorkspaceMember,
    updateWorkspaceName
} from '@setup/api/workspace';

WorkspaceMemberList.propTypes = {
    workspaceName: PropTypes.string.isRequired,
    isWorkspaceAdmin: PropTypes.bool.isRequired,
    members: PropTypes.arrayOf(PropTypes.object),
    onMemberChange: PropTypes.func.isRequired,
    onWorkspaceNameChange: PropTypes.func.isRequired
};

export default function WorkspaceMemberList({
    workspaceName,
    members,
    onMemberChange,
    onWorkspaceNameChange,
    isWorkspaceAdmin
}) {
    const userEmail = useSelector((state) => state.user.userEmail);
    const notification = useNotification();

    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [newMemberRoleId, setNewMemberRoleId] = useState(2);
    const [newWorkspaceName, setNewWorkspaceName] = useState(workspaceName);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [removalTargetUser, setRemovalTargetUser] = useState({});
    const [isWorkspaceRenameDialogOpen, setIsWorkspaceRenameDialogOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [checked, setChecked] = useState(false);

    const handleAddColleague = async () => {
        try {
            await addWorkspaceMember(newMemberEmail, newMemberRoleId, onMemberChange);
            return true;
        } catch (error) {
            setAlertMessage(error.response.data.message);
        }
    };

    const handleEmailChange = () => {
        if (alertMessage) {
            setAlertMessage('');
        }
    };

    const handleInvitation = async () => {
        await sendUserInvitationToVoicit([newMemberEmail], userEmail, 'Workspace');
        notification('user-invitation-sent');
        return true;
    };

    return (
        <Stack mt={2} spacing={2}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography component='span' variant='subtitle1' sx={{ mb: 1 }}>
                        {workspaceName}
                    </Typography>
                </Grid>
                <Grid display='flex' justifyContent='flex-end' item xs={6}>
                    {isWorkspaceAdmin && (
                        <MenuButton
                            iconButton={<MoreVertIcon fontSize='small' />}
                            menuList={[
                                {
                                    itemFunction: () => setIsWorkspaceRenameDialogOpen(true),
                                    itemText: 'Renombrar',
                                    itemIcon: <EditIcon fontSize='small' />
                                }
                            ]}
                        />
                    )}
                </Grid>
            </Grid>
            <List>
                {members
                    .sort((a, b) => a.workspaceRole.localeCompare(b.workspaceRole))
                    .map((m, i) => {
                        return (
                            <ListItem key={i} sx={{ pb: 2 }}>
                                <AvatarWithInfo
                                    avatarName={m.givenName}
                                    subtitle={m.email}
                                    avatarImage={m.avatarUrl}
                                />
                                {m.workspaceRole === 'ADMIN' && (
                                    <Chip
                                        variant='filled'
                                        color={palette.secondary.light}
                                        label={'Admin'}
                                        size='small'
                                    />
                                )}
                                {isWorkspaceAdmin && m.workspaceRole !== 'ADMIN' && (
                                    <IconButton
                                        onClick={() => {
                                            setRemovalTargetUser(m);
                                            setIsConfirmationDialogOpen(true);
                                        }}
                                        disableRipple={true}
                                        size='small'
                                    >
                                        <CloseIcon
                                            color='primary'
                                            style={{ fontSize: '15px', fontWeight: 'bold' }}
                                        />
                                    </IconButton>
                                )}
                            </ListItem>
                        );
                    })}
            </List>
            {/* CONFIRMATION DIALOG */}
            <ConfirmationDialog
                title={`¿Eliminar a ${removalTargetUser.givenName} de este workspace?`}
                message='Esta acción es irreversible, deberás añadir al usuario de nuevo.'
                onClose={() => setIsConfirmationDialogOpen(false)}
                open={isConfirmationDialogOpen}
                onConfirm={async () => {
                    await removeWorkspaceMember(removalTargetUser.id, onMemberChange);
                    setIsConfirmationDialogOpen(false);
                }}
            />
            {/* ADD MEMBER DIALOG */}
            {isWorkspaceAdmin && (
                <DialogButton
                    dialogTitle={'Añadir a un nuevo compañero'}
                    dialogText={
                        'Introduce el email que tu compañero utiliza en Voicit para poder invitarlo.'
                    }
                    withButton={true}
                    buttonText={'Añadir compañero'}
                    successButtonLoadingText={alertMessage ? 'Enviando invitación' : 'Añadiendo'}
                    successButtonText={
                        alertMessage ? 'Enviar correo de invitación a Voicit' : 'Añadir compañero'
                    }
                    onSuccess={alertMessage ? handleInvitation : handleAddColleague}
                    buttonIcon={<PersonAddAltOutlinedIcon />}
                    buttonVariant={'text'}
                    onClose={() => setAlertMessage('')}
                >
                    <TextField
                        style={{ marginTop: '15px' }}
                        onInput={(e) => setNewMemberEmail(e.target.value)}
                        onChange={handleEmailChange}
                        fullWidth
                        placeholder={'Email'}
                        variant='standard'
                        error={alertMessage ? true : false}
                        helperText={alertMessage}
                    />
                    <FormControlLabel
                        required
                        sx={{ mt: 1 }}
                        control={<Checkbox checked={checked} />}
                        onChange={() => {
                            setNewMemberRoleId(
                                checked ? userWorkspaceRole.MEMBER : userWorkspaceRole.ADMIN
                            );
                            setChecked(!checked);
                        }}
                        label='Hacer administrador'
                    />
                </DialogButton>
            )}
            {/* RENAME WORKSPACE DIALOG */}
            <DialogButton
                openDialog={isWorkspaceRenameDialogOpen}
                dialogTitle={'Nombre de tu workspace'}
                dialogText={'Este nombre lo verán el resto de compañeros de tu workspace'}
                successButtonLoadingText={'Guardando'}
                successButtonText={'Guardar'}
                onClose={() => {
                    setIsWorkspaceRenameDialogOpen(false);
                    setNewWorkspaceName(workspaceName);
                }}
                onSuccess={async () =>
                    await updateWorkspaceName(newWorkspaceName, () => {
                        setIsWorkspaceRenameDialogOpen(false);
                        onWorkspaceNameChange(newWorkspaceName);
                    })
                }
            >
                <TextField
                    style={{ marginTop: '15px' }}
                    value={newWorkspaceName}
                    onInput={(e) => setNewWorkspaceName(e.target.value)}
                    fullWidth
                    placeholder={'Nombre del workspace'}
                    variant='standard'
                />
            </DialogButton>
        </Stack>
    );
}
