import {
    BLUE,
    EMERALD,
    ERROR,
    GREEN,
    GREY,
    INFO,
    LAUREL,
    OCEAN,
    OLIVE,
    ORGANGE,
    PEACH,
    PINK,
    PRIMARY,
    PURPLE,
    RED,
    SECONDARY,
    SUCCESS,
    WARNING,
    WOOD,
    YELLOW
} from './fullPalette';

// COMMON COLORS
export const CHIP_PRIMARY_SOFT = {
    main: PRIMARY[50],
    contrastText: PRIMARY.main
};

export const CHIP_PRIMARY_DARK = {
    main: PRIMARY[700],
    contrastText: PRIMARY[50]
};

export const CHIP_PRIMARY = {
    main: PRIMARY.main,
    contrastText: PRIMARY[50]
};

export const CHIP_SECONDARY_SOFT = {
    main: SECONDARY[200],
    contrastText: PRIMARY[600]
};

export const CHIP_SECONDARY = {
    main: SECONDARY.main,
    contrastText: SECONDARY.contrastText
};

export const CHIP_SECONDARY_DARK = {
    main: SECONDARY[900],
    contrastText: SECONDARY.main
};

export const CHIP_SECONDARY_PRIMARY = {
    main: PRIMARY.main,
    contrastText: SECONDARY.main
};

export const CHIP_GREY = {
    main: GREY[200],
    contrastText: GREY[900]
};

export const CHIP_PURPLE = {
    main: PURPLE[100],
    contrastText: PURPLE[900]
};

export const CHIP_PINK = {
    main: PINK[100],
    contrastText: PINK[900]
};

export const CHIP_ORGANGE = {
    main: ORGANGE[100],
    contrastText: ORGANGE[900]
};

export const CHIP_RED = {
    main: RED[100],
    contrastText: RED[900]
};

export const CHIP_GREEN = {
    main: GREEN[100],
    contrastText: GREEN[900]
};

export const CHIP_BLUE = {
    main: BLUE[100],
    contrastText: BLUE[900]
};

export const CHIP_YELLOW = {
    main: YELLOW[100],
    contrastText: YELLOW[900]
};

export const CHIP_OLIVE = {
    main: OLIVE[100],
    contrastText: OLIVE[900]
};

export const CHIP_OCEAN = {
    main: OCEAN[100],
    contrastText: OCEAN[900]
};

export const CHIP_EMERALD = {
    main: EMERALD[100],
    contrastText: EMERALD[800]
};

export const CHIP_LAUREL = {
    main: LAUREL[200],
    contrastText: LAUREL[800]
};

export const CHIP_PEACH = {
    main: PEACH[100],
    contrastText: PEACH[800]
};

export const CHIP_WOOD = {
    main: WOOD[200],
    contrastText: WOOD[800]
};

export const CHIP_INFO = {
    main: INFO.lighter,
    contrastText: INFO.darker
};

export const CHIP_ERROR = {
    main: ERROR.lighter,
    contrastText: ERROR.darker
};

export const CHIP_DISABLED = {
    main: GREY[100],
    contrastText: GREY[400]
};

// ESPECIAL COLORS
export const CHIP_SUCCESS = {
    main: SUCCESS.lighter,
    contrastText: SUCCESS.darker
};

export const CHIP_WARNING = {
    main: WARNING.lighter,
    contrastText: WARNING.darker
};

export const FLOATING_BUTTON = {
    main: '#FFFFFF',
    contrastText: PRIMARY[900]
};
