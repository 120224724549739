import { downloadFileFromBlob } from '@common/utils/downloadFileFromBlob';
import { getAudioBlob } from '@setup/api/gcs';

export const getAudioDuration = async (audioFile) => {
    const url = URL.createObjectURL(audioFile);
    return new Promise((resolve, reject) => {
        const player = new Audio(url);
        player.addEventListener(
            'durationchange',
            function () {
                if (this.duration !== Infinity) {
                    player.remove();
                    resolve(this.duration);
                }
            },
            false
        );
        player.load();
        player.currentTime = 24 * 60 * 60;
        player.volume = 0;
        setTimeout(
            () =>
                reject(
                    'Error with audio: Unable to get audio duration. Format may be invalid or audio could be corrupted.'
                ),
            3000
        );
    });
};

export const downloadAudio = async ({ audioFilename, meetingTitle, allowExportInformation }) => {
    const { audioBlob } = await getAudioBlob({ filename: audioFilename, allowExportInformation });
    downloadFileFromBlob({ blob: audioBlob, filename: meetingTitle, extension: 'mp3' });
};

/**
 * Converts a base64-encoded string to an MP3 audio Blob.
 * NOTE: This function is currently not being used.
 *
 * @param {string} base64 - The base64-encoded string representing the audio data.
 * @param {function} [setCurrentProgress] - Optional callback function to update the decoding progress.
 * @param {function} [setEnd] - Optional callback function to set the end of the decoding process.
 * @returns {Promise<Blob>} A Promise that resolves to an MP3 audio Blob.
 * @throws Will throw an error if the decoding process fails.
 *
 * @example
 * const base64Data = "..." // your base64-encoded audio data
 * base64ToMP3(base64Data, setCurrentProgressCallback, setEndCallback)
 *   .then((audioBlob) => {
 *      // Handle the MP3 audio Blob
 *   })
 *   .catch((error) => {
 *      // Handle the error
 *   });
 */
export const base64ToMP3 = (base64, setCurrentProgress, setEnd) => {
    return new Promise((resolve, reject) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        if (!binaryString || !len || !bytes) reject();
        if (setEnd) setEnd(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
            if (setCurrentProgress) {
                setCurrentProgress(i);
            }
        }

        const mp3Blob = new Blob([bytes], { type: 'audio/mp3' });
        if (!mp3Blob) reject();
        resolve(mp3Blob);
    });
};
